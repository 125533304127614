<script lang="ts">
    import EventBus from '../eventBus';
    import RightsHelper from '../../../../helpers/rights-helper';
    import self from '../../index';
    import { Compact } from 'vue-color';

    const {
        app: {
            modules: { history },
        },
    } = self;

    const defaultProps = {
        hex: '#A5A5A5',
    };

    export default {
        components: {
            'compact-picker': Compact,
        },
        props: {
            palette: {
                type: Array,
                default: () => [],
            },
            colorableEntities: {
                type: Array,
                default: () => [],
            },
            config: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                colors: defaultProps,
                isExpanded: false,
                isPublic: RightsHelper.isModePublic(),
                isRalMode: this.colorableEntities.every((colorableEntity) => colorableEntity.color),
                ralOnly: this.colorableEntities.some((colorableEntity) => colorableEntity.ralOnly),
            };
        },
        mounted() {
            EventBus.$on('toggle-groups', (open) => {
                this.isExpanded = !open;
            });
        },
        methods: {
            updateColor(color) {
                this.colorableEntities.forEach((colorableEntity) => colorableEntity.changeColor(color.hex));
                this.isRalMode = true;
                history.snapshot(); // Update history at the end (for global undo)
            },
            setEcoMaterial() {
                this.colorableEntities.forEach((colorableEntity) => colorableEntity.setEcoMaterial());
                this.isRalMode = false;
                history.snapshot();
            },
        },
    };
</script>

<template>
    <b-navbar
        class="color-picker-navbar"
        :class="{
            noRightMenu: isExpanded,
            noLeftMenu: isPublic,
        }"
    >
        <b-navbar-nav>
            <template v-if="ralOnly">
                <compact-picker
                    :value="colorableEntities[0].color"
                    :palette="palette"
                    @input="updateColor"
                />
            </template>
            <template v-else>
                <b-button
                    class="option-type-button color-mode-buttons"
                    @click="setEcoMaterial"
                >
                    ECO
                </b-button>
                <b-button
                    v-if="!isRalMode"
                    class="option-type-button color-mode-buttons"
                    @click="updateColor(colors)"
                >
                    RAL
                </b-button>
                <compact-picker
                    v-else
                    :value="colorableEntities[0].color"
                    :palette="palette"
                    @input="updateColor"
                />
            </template>
        </b-navbar-nav>
    </b-navbar>
</template>
