import self from '../..';

const EntityStructure = require('./entity-structure').default;
const Structure = require('@obsidianjs/data-store/src/entity');

const DoorStructure = EntityStructure.$extend({
    __name__: 'door',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
        this.initOptions();
        this.initMeasurement();
    },

    __include__: [
        self.app.modules.snapping.SnappableMixin.prototype,
        self.app.modules.optionManager.ColorableMixin.prototype,
        self.app.modules.optionManager.OptionableMixin.prototype,
        self.app.modules.measurementManager.MeasurableMixin.prototype,
    ],
});
Structure.$register(DoorStructure);
export default EntityStructure;
