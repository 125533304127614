<script lang="ts">
    import TextBox from './text-box.vue';
    import self from '../../../index';

    const {
        app: {
            modules: {
                buildingPlanManager: { buildingPlanController: bpController },
            },
        },
    } = self;

    export default {
        components: {
            TextBox,
        },
        computed: {
            getPageTexts() {
                const page = bpController.getPage();
                return page.texts;
            },
        },
    };
</script>
<template>
    <div class="building-plans-text-boxes-container">
        <text-box
            v-for="(textObject, uuid) in getPageTexts"
            :key="uuid"
            :text-object="textObject"
        />
    </div>
</template>
<style lang="scss">
    .building-plans-text-boxes-container {
        position: absolute;
        height: 100%;
        width: 100%;
    }
</style>
