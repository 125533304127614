/* eslint-disable import/no-import-module-exports */
import SnapRectangle from './snappy-rectangle';
import { SnapConfig } from '../controller/snap-controller';
import self from '../../index';
import { Vector3, Quaternion } from '@babylonjs/core';

import { v4 as uuid } from 'uuid';
import Class from 'abitbol';

import config from 'defaultConfig'; // eslint-disable-line

const SnappableMixin = Class.$extend({
    initSnapping() {
        /**
         * @type {SnapRectangle[]} */
        this.snappyRects = [];

        this.ghost = {
            mesh: null, // mesh showed to see the potential snap transform
            snapped: null, // Reference to the other snap structure it is "ghost snapped" with
        };

        this.isSnappable = true;
    },

    onDragStart() {
        self.app.events.emit('drag-start', this);
    },

    onDrag() {
        self.app.events.emit('drag', this);
    },

    onDragEnd() {
        if (this.ghost.snapped) {
            this.snap();
        }
    },

    /**
     * Check if one of  this structure's snappy rect is close enough to snap to
     * an other structure snappy rect
     * If it's close enough => create a ghost mesh and do more computation
     *  to see if the snap is actually doable (see function ghostSnap)
     * if it can't : delete the ghost mesh if there is one
     * Returns true if it was possible to correctly snap the ghost mesh
     * @param {bematrix.snappableStructure} snappable
     */
    updateSnap(snappable) {
        let canSnap = false;
        this.mesh.computeWorldMatrix(true);

        const maxSqrDistToSnap = SnapConfig.maxDistToSnap ** 2;

        canSnap = this.snappyRects.some((rect1) => {
            rect1.computeWorldMatrix();
            return snappable.snappyRects.some((rect2) => {
                if (!rect2.snapped || rect2.snapped === rect1) {
                    /** @type {Vector3} */
                    const deltaVect = rect1.absolutePosition.subtract(rect2.absolutePosition);
                    rect2.removeWidthComponent(deltaVect, (rect2.width + rect1.width) / 2);
                    const sqrDist = deltaVect.lengthSquared();
                    if (sqrDist < maxSqrDistToSnap) {
                        return this.ghostSnap(rect1, rect2);
                    }
                }
                return false;
            });
        });

        if (!canSnap) {
            this.unghost();
        }
        return canSnap;
    },

    /**
     * Rotates the ghost mesh so its snapRect is aligned with snapRect2
     * @param  {[type]}  snapRect1 snapRect of the original mesh
     * @param  {[type]}  snapRect2  snapRect of the target mesh
     * @param  {Boolean} [invert=false] do we consider
     * the target snapRect as rotated of PI on its forward axis
     * @return {number}  angle between the final quaternion and the initial quaternion
     */
    rotateGhostToSnap(snapRect1, snapRect2, invert = false) {
        // We update the ghost mesh rotation to be in the same space as the 2nd mesh
        this.ghost.mesh.rotationQuaternion = snapRect2.parentEntity.rotationQuaternion.clone();
        this.ghost.mesh.computeWorldMatrix();
        // If the meshes ups are facing different directions,
        // put the quaternion1 upside down to avoid too big rotations
        const quat1 = snapRect1.rotationQuaternion.clone();
        if (invert) {
            quat1.multiplyInPlace(Quaternion.RotationAxis(Vector3.Forward(), Math.PI));
            quat1.normalize();
        }
        // compute the delta quaternion to apply to the ghostMesh so
        // it's snapRect is aligned with snapRect2 and apply it
        const delta = snapRect2.oppositeTransform.rotationQuaternion.multiply(Quaternion.Inverse(quat1));
        delta.normalize();

        this.ghost.mesh.rotationQuaternion.multiplyInPlace(delta);

        this.ghost.mesh.computeWorldMatrix();
        // compute the angle between the final quaternion and the initial quaternion
        const totalQuat = this.ghost.mesh.rotationQuaternion.multiply(Quaternion.Inverse(this.mesh.rotationQuaternion));
        if (Math.abs(totalQuat.w) > 1) {
            totalQuat.w = 1 * Math.sign(totalQuat.w);
        }
        let angle = Math.abs(2 * Math.acos(totalQuat.w));
        if (angle > Math.PI) {
            angle = 2 * Math.PI - angle;
        }
        return angle;
    },

    /**
     * shows a ghost mesh with the position where this structure would eventually snap
     * @param {bematrix.SnapRectangle} snapRect1 the snapping rect of this structure
     * @param {bematrix.SnapRectangle} snapRect2 the snapping rect of the other structure
     * @return {boolean} true if can snap and the ghost is created
     */
    ghostSnap(snapRect1, snapRect2) {
        // Creates a ghost mesh with the same position and rotation as the mesh to snap
        if (!this.ghost.mesh) {
            this.ghost.mesh = this.mesh.sourceMesh.clone(uuid());
            this.ghost.mesh.setEnabled(true);
        }
        this.ghost.mesh.position = this.mesh.position.clone();
        this.ghost.mesh.rotationQuaternion = this.mesh.rotationQuaternion.clone();
        snapRect2.parentEntity.mesh.computeWorldMatrix();

        // the snapRect of the ghost corresponding to snapRect1
        const snapRectGhost = snapRect1.clone(uuid());
        snapRectGhost.width = snapRect1.width;
        snapRectGhost.parent = this.ghost.mesh;

        // Pick the best lower angle to rotate the ghost mesh
        const finalAngle1 = this.rotateGhostToSnap(snapRect1, snapRect2);
        const quat1 = this.ghost.mesh.rotationQuaternion.clone();

        const finalAngle2 = this.rotateGhostToSnap(snapRect1, snapRect2, true);
        let finalAngle = finalAngle2;

        if (finalAngle2 > finalAngle1) {
            this.ghost.mesh.rotationQuaternion = quat1;
            this.ghost.mesh.computeWorldMatrix();
            finalAngle = finalAngle1;
        }

        // If the angle is superior to the maximum allowed => no snap
        if (finalAngle > SnapConfig.maxAngleToSnap) {
            return false;
        }

        snapRectGhost.computeWorldMatrix(true);
        // Otherwise Update position of the mesh1 so it goes snapping the mesh2
        const deltaPos = snapRect2.absolutePosition.subtract(snapRectGhost.absolutePosition);
        snapRect2.removeWidthComponent(deltaPos, (snapRect1.width + snapRect2.width) / 2);
        this.ghost.mesh.position.addInPlace(deltaPos);

        // Check if it goes through the ground
        this.ghost.mesh.computeWorldMatrix();
        const { boundingBox } = this.ghost.mesh.getBoundingInfo();
        if (boundingBox.minimumWorld.y >= -0.02) {
            this.ghost.snapped = snapRect2.parentEntity;
            self.app.events.emit('ghost');
            return true;
        }
        return false;
    },

    /**
     * Set the mesh position that is snapping to the ghost mesh position
     */
    snap() {
        this.mesh.position = this.ghost.mesh.position.clone();
        this.mesh.rotationQuaternion = this.ghost.mesh.rotationQuaternion.clone();
        this.updatePosition();
        this.updateRotation();
        self.app.events.emit('snap', {
            snappedEntity: this.ghost.snapped,
            snappingEntity: this,
        });
        this.updateRectsMatrixes();
        this.unghost();
    },

    /**
     * Hide the ghost mesh when the snapping rules are not respected anymore
     */
    unghost() {
        if (this.ghost.mesh) {
            this.ghost.mesh.dispose();
            this.ghost.mesh = null;
        }
        if (this.ghost.snapped) {
            this.ghost.snapped = null;
        }
        self.app.events.emit('unghost');
    },

    /**
     * Generate snap faces from given infos
     * @param {Object} snapInfos
     */
    generateSnappyRectsFromSnapInfos(snapInfos) {
        snapInfos.forEach((sI) => {
            const fixedQuat = sI.quaternion.multiply(Quaternion.RotationAxis(Vector3.Forward(), Math.PI / 2)).normalize();
            let { width } = sI;
            let { height } = sI;
            if (height > width) {
                height = sI.width;
                width = sI.height;
            }
            this.snappyRects.push(new SnapRectangle(this, this.snappyRects.length, sI.position, fixedQuat, width, height));
        });
    },

    /**
     * Generate snap faces from the attached mesh bounding
     * @param {Boolean} topBot (Optional)
     * @param {Boolean} leftRight (Optional)
     */
    generateSnappyRectsFromBoundingBox(topBot = true, leftRight = true) {
        /**
         * @type BoundingBox
         */
        const bb = this.getBoundingBox();

        // snappy rect for covers
        if (bb.extendSize.y < 0.01) {
            this.generateSnapRectsForCover(bb);
            return;
        }

        // snappy rects for classic frames
        if (topBot) {
            const xPos = bb.center.x;
            const zPos = bb.center.z;
            const topPosition = new Vector3(xPos, bb.maximum.y, zPos);
            let width = bb.maximum.x - bb.minimum.x;
            let height = bb.maximum.z - bb.minimum.z;
            let quat1;
            let quat2;
            // width is always the bigger side
            if (height > width) {
                const temp = width;
                width = height;
                height = temp;
                quat1 = Quaternion.RotationYawPitchRoll(Math.PI / 2, -Math.PI / 2, 0);
                quat2 = Quaternion.RotationYawPitchRoll(Math.PI / 2, Math.PI / 2, 0);
            } else {
                quat1 = Quaternion.RotationYawPitchRoll(0, -Math.PI / 2, 0);
                quat2 = Quaternion.RotationYawPitchRoll(0, Math.PI / 2, 0);
            }
            const topRect = new SnapRectangle(this, this.snappyRects.length, topPosition, quat1, width, height);
            this.snappyRects.push(topRect);
            const botPosition = new Vector3(xPos, bb.minimum.y, zPos);
            const botRect = new SnapRectangle(this, this.snappyRects.length, botPosition, quat2, width, height);
            this.snappyRects.push(botRect);
        }
        if (leftRight) {
            const yPos = bb.center.y;
            const zPos = bb.center.z;
            let width = bb.maximum.y - bb.minimum.y;
            let height = bb.maximum.z - bb.minimum.z;
            let quat1;
            let quat2;
            // width is always the bigger side
            if (height > width) {
                const temp = width;
                width = height;
                height = temp;
                quat1 = Quaternion.RotationYawPitchRoll(Math.PI / 2, 0, 0);
                quat2 = Quaternion.RotationYawPitchRoll(-Math.PI / 2, 0, 0);
            } else {
                quat1 = Quaternion.RotationYawPitchRoll(Math.PI / 2, 0, Math.PI / 2);
                quat2 = Quaternion.RotationYawPitchRoll(-Math.PI / 2, 0, Math.PI / 2);
            }
            const leftPosition = new Vector3(bb.maximum.x, yPos, zPos);
            const topRect = new SnapRectangle(this, this.snappyRects.length, leftPosition, quat1, width, height);
            this.snappyRects.push(topRect);
            const botPosition = new Vector3(bb.minimum.x, yPos, zPos);
            const botRect = new SnapRectangle(this, this.snappyRects.length, botPosition, quat2, width, height);
            this.snappyRects.push(botRect);
        }
    },

    /**
     * Generate the snap faces for a cover object
     * @param {BoundingBox} boundingBox
     */
    generateSnapRectsForCover(boundingBox) {
        // This align generated faces with blender generated ones
        const offset = 0.0004;
        const rect1 = new SnapRectangle(
            this,
            this.snappyRects.length,
            Vector3.Up().scale(this.coverThickness - offset),
            Quaternion.RotationYawPitchRoll(Math.PI / 2, -Math.PI / 2, 0),
            boundingBox.maximum.z - boundingBox.minimum.z,
            boundingBox.maximum.x - boundingBox.minimum.x,
        );
        const rect2 = new SnapRectangle(
            this,
            this.snappyRects.length,
            Vector3.Up().scale(offset),
            Quaternion.RotationYawPitchRoll(Math.PI / 2, Math.PI / 2, 0),
            boundingBox.maximum.z - boundingBox.minimum.z,
            boundingBox.maximum.x - boundingBox.minimum.x,
        );
        this.snappyRects.push(rect1);
        this.snappyRects.push(rect2);
    },

    updateRectsMatrixes() {
        this.snappyRects.forEach((r) => {
            r.computeWorldMatrix();
        });
    },
});
module.exports = SnappableMixin;
