import self from '../..';
import RightsHelper from 'helpers/rights-helper';
import KeyboardJs from 'keyboardjs';

const { events } = self.app;

const PERMISSIONS = {
    OTHER: 0,
    PUBLIC: 1,
};
/**
 * Used for binding functions or events to keyboard events
 */
export default class KeyboardEventHandler {
    constructor() {
        // For each combination of keys used in the app we set a callback when the combination is
        // pressed and a callback whent the combiantion is released

        this.lockKeyboardEvents = false;

        this.KeyboardEventsMap = [
            {
                keys: 'modifier',
                callbackPressed: KeyboardEventHandler.onModifierPressed,
                callbackReleased: KeyboardEventHandler.onModifierReleased,
            },
            {
                keys: 'delete',
                callbackPressed: KeyboardEventHandler.onDeletePressed,
                buildingPlanCallbackPressed: KeyboardEventHandler.onBuildingPlanDeletePressed,
            },
            {
                keys: 'esc',
                callbackPressed: KeyboardEventHandler.onEscPressed,
                buildingPlan: true,
                permission: PERMISSIONS.PUBLIC,
            },
            {
                keys: 'modifier + z',
                callbackPressed: KeyboardEventHandler.onUndoPressed,
            },
            {
                keys: 'modifier + y',
                callbackPressed: KeyboardEventHandler.onRedoPressed,
            },
            {
                keys: process.env.NODE_ENV === 'development' ? 'modifier + d' : 'modifier + c',
                callbackPressed: KeyboardEventHandler.onCopyPressed,
            },
            {
                keys: 'modifier + m',
                callbackPressed: KeyboardEventHandler.onCopyAndMovePressed,
            },
            {
                keys: 'modifier + v',
                callbackPressed: KeyboardEventHandler.onPastePressed,
            },
            {
                keys: 'modifier + r',
                callbackPressed: KeyboardEventHandler.onCopyAndRotatePressed,
            },
            {
                keys: 'modifier + s',
                callbackPressed: KeyboardEventHandler.onCopyAndScalePressed,
            },
            {
                keys: 'm',
                callbackPressed: KeyboardEventHandler.onMovePressed,
            },
            {
                keys: 'n',
                callbackPressed: KeyboardEventHandler.onSnappingModePressed,
            },
            {
                keys: 'r',
                callbackPressed: KeyboardEventHandler.onRotatePressed,
            },
            {
                keys: 'q',
                callbackPressed: () => KeyboardEventHandler.onRotate90Pressed(0),
            },
            {
                keys: 'modifier + q',
                callbackPressed: () => KeyboardEventHandler.onRotate45Pressed(0),
            },
            {
                keys: 'shift + q',
                callbackPressed: () => KeyboardEventHandler.onRotate90Pressed(1),
            },
            {
                keys: 'shift + modifier + q',
                callbackPressed: () => KeyboardEventHandler.onRotate45Pressed(1),
            },
            {
                keys: 'alt + q',
                callbackPressed: () => KeyboardEventHandler.onRotate90Pressed(2),
            },
            {
                keys: 'alt + modifier + q',
                callbackPressed: () => KeyboardEventHandler.onRotate45Pressed(2),
            },
            {
                keys: 's',
                callbackPressed: KeyboardEventHandler.onScalePressed,
            },
            {
                keys: 'd',
                callbackPressed: KeyboardEventHandler.onDuplicatePressed,
            },
        ];

        events.on('@bematrix-ui.popin-opened', () => {
            this.lockKeyboardEvents = true;
        });

        events.on('@bematrix-ui.popin-closed', () => {
            this.lockKeyboardEvents = false;
        });

        this.initKeyboardEvent();
    }

    // Bind the keyboard map defined in the constructor with KeyboardJS
    initKeyboardEvent() {
        const conditionFun = (kbEvent) =>
            (kbEvent.permission === PERMISSIONS.PUBLIC || !RightsHelper.isModePublic()) &&
            (kbEvent.buildingPlan || !RightsHelper.isModeBuildingPlan());

        this.KeyboardEventsMap.forEach((keyboardEvent) => {
            KeyboardJs.bind(
                keyboardEvent.keys,
                // pressed function and conditions
                (e) => {
                    if (this.lockKeyboardEvents) {
                        // Useful when we don't want shortcuts usable
                        return;
                    }

                    // Avoid to lose the default functioning of single keys
                    if (keyboardEvent.keys.includes('modifier +')) {
                        e.preventDefault();
                    }
                    if (conditionFun(keyboardEvent)) {
                        keyboardEvent.callbackPressed();
                    } else if (RightsHelper.isModeBuildingPlan() && typeof keyboardEvent.buildingPlanCallbackPressed === 'function') {
                        keyboardEvent.buildingPlanCallbackPressed();
                    }
                },
                // release function and conditions
                (e) => {
                    if (this.lockKeyboardEvents) {
                        // Useful when we don't want shortcuts usable
                        return;
                    }

                    // Avoid to lose the default functioning of single keys
                    if (keyboardEvent.keys.includes('modifier +')) {
                        e.preventDefault();
                    }
                    if (conditionFun(keyboardEvent) && keyboardEvent.callbackReleased) {
                        keyboardEvent.callbackReleased();
                    }
                },
            );
        });
    }

    static onModifierPressed() {
        events.emit('modifierPressed');
    }

    static onModifierReleased() {
        events.emit('modifierReleased');
    }

    static onDeletePressed() {
        events.emit('delete');
    }

    static onBuildingPlanDeletePressed() {
        events.emit('building-plan-delete');
    }

    static onEscPressed() {
        events.emit('cancel');
    }

    static onSnappingModePressed() {
        events.emit('snapping-mode');
    }

    static onUndoPressed() {
        events.emit('undo');
    }

    static onRedoPressed() {
        events.emit('redo');
    }

    static onCopyPressed() {
        events.emit('copy');
    }

    static onCopyAndMovePressed() {
        events.emit('copyAndMove');
    }

    static onCopyAndRotatePressed() {
        events.emit('copyAndRotate');
    }

    static onCopyAndScalePressed() {
        events.emit('copyAndReplace');
    }

    static onPastePressed() {
        events.emit('paste');
    }

    static onMovePressed() {
        events.emit('move');
    }

    static onRotatePressed() {
        events.emit('rotate');
    }

    static onRotate90Pressed(axis) {
        events.emit('rotate90', axis);
    }

    static onRotate45Pressed(axis) {
        events.emit('rotate45', axis);
    }

    static onScalePressed() {
        events.emit('scale');
    }

    static onDuplicatePressed() {
        events.emit('duplicate');
    }
}
