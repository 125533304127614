import AbstractOptionController from './abstract-option-controller';
// eslint-disable-next-line import/default
import OptionableMixin from '../model/optionable-mixin';
import OptionUtility from '../utility/option-utility';
import self from '../index';
import { Quaternion, Vector3 } from '@babylonjs/core';

const {
    modules: { catalogManager },
} = self.app;

export default class BaseplateOptionController extends AbstractOptionController {
    constructor(optionController) {
        super(optionController);

        this.products = catalogManager.products;

        this.oldBaseplatesReferences = {};
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.SQUARED}`] = '901 10 07';
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.BOTH_SIDE}`] = '901 10 08';
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.DOUBLE}`] = '901 10 09';
        this.oldBaseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.ONE_SIDE}`] = '901 10 15';

        this.baseplatesReferences = {};
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.SQUARED}`] = '901 11 07';
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.BOTH_SIDE}`] = '901 11 08';
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.DOUBLE}`] = '901 11 09';
        this.baseplatesReferences[`${OptionableMixin.BASEPLATE_OPTION.ONE_SIDE}`] = '901 11 15';

        this.initializeEvents(this.baseplatesReferences);
    }

    /**
     * Add a baseplate mesh to the passed entity
     * @param {*} baseplateType
     * @param {*} entity if null take the current entity
     */
    addBaseplate(baseplateType, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return Promise.resolve(null);
        }

        return this.updateBaseplateMeshes(realEntity, baseplateType);
    }

    removeBaseplateMeshes(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);
        if (entityToRemoveFrom.optionsMeshes && entityToRemoveFrom.optionsMeshes.baseplates.length) {
            entityToRemoveFrom.optionsMeshes.baseplates.forEach((baseplate) => {
                baseplate.dispose();
            });
            entityToRemoveFrom.optionsMeshes.baseplates.splice(0);
        }
    }

    /**
     * Remove the baseplate
     * @param {*} entity if null take the current entity
     */
    removeBaseplate(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);

        if (entityToRemoveFrom.optionsMeshes && entityToRemoveFrom.getBaseplateOption() > OptionableMixin.BASEPLATE_OPTION.NONE) {
            entityToRemoveFrom.setBaseplateOption(OptionableMixin.BASEPLATE_OPTION.NONE);
            entityToRemoveFrom.swappedOptions.BASEPLATE = false;
            this.removeBaseplateMeshes(entity);
        }
    }

    updateBaseplateMeshes(frame, type) {
        this.removeBaseplateMeshes(frame);
        frame.baseplateOption = type;

        if (frame.baseplateOption) {
            const baseplateRef = this.getBaseplateRef(frame);
            const baseplates = [];
            baseplates.push(this.loadBaseplateFromRef(baseplateRef));

            // Add a second baseplate
            if (type === OptionableMixin.BASEPLATE_OPTION.DOUBLE) {
                baseplates.push(this.loadBaseplateFromRef(baseplateRef));
            }
            frame.optionsMeshes.baseplates = baseplates;
            frame.computeOptionsParameters();

            // Modify the real mesh
            frame.optionsMeshes.baseplates.forEach((baseplate) => {
                baseplate.name = 'baseplate';
                baseplate.parent = frame.mesh;
                baseplate.ref = baseplateRef;
            });

            const baseplate = frame.optionsMeshes.baseplates[0];
            const baseplate2 = frame.optionsMeshes.baseplates[1];

            if (frame.angleToUp === 0) {
                baseplate.translate(Vector3.Up(), -frame.orientedHeight / 2);
                baseplate.rotate(Vector3.Right(), Math.PI / 2);
                if (baseplate2) {
                    baseplate2.translate(Vector3.Up(), -frame.orientedHeight / 2);
                    baseplate2.rotate(Vector3.Right(), Math.PI / 2);
                    baseplate2.translate(Vector3.Right(), frame.orientedWidth / 2);
                    baseplate.translate(Vector3.Left(), frame.orientedWidth / 2);
                }
            } else if (frame.angleToUp === Math.PI) {
                baseplate.translate(Vector3.Up(), frame.orientedHeight / 2);
                baseplate.rotate(Vector3.Right(), -Math.PI / 2);
                if (baseplate2) {
                    baseplate2.translate(Vector3.Up(), frame.orientedHeight / 2);
                    baseplate2.translate(Vector3.Right(), frame.orientedWidth / 2);
                    baseplate2.rotate(Vector3.Right(), -Math.PI / 2);
                    baseplate.translate(Vector3.Left(), frame.orientedWidth / 2);
                }
            } else if (frame.angleToUp === -Math.PI / 2) {
                baseplate.translate(Vector3.Left(), -frame.orientedHeight / 2);
                baseplate.rotate(Vector3.Forward(), -Math.PI / 2);
                baseplate.rotate(Vector3.Right(), -Math.PI / 2);
                if (baseplate2) {
                    baseplate2.translate(Vector3.Left(), -frame.orientedHeight / 2);
                    baseplate2.translate(Vector3.Up(), frame.orientedWidth / 2);
                    baseplate2.rotate(Vector3.Forward(), -Math.PI / 2);
                    baseplate2.rotate(Vector3.Right(), -Math.PI / 2);
                    baseplate.translate(Vector3.Left(), -frame.orientedWidth / 2);
                }
            } else if (frame.angleToUp === Math.PI / 2) {
                baseplate.translate(Vector3.Right(), -frame.orientedHeight / 2);
                baseplate.rotate(Vector3.Forward(), Math.PI / 2);
                baseplate.rotate(Vector3.Right(), -Math.PI / 2);
                if (baseplate2) {
                    baseplate2.translate(Vector3.Right(), -frame.orientedHeight / 2);
                    baseplate2.translate(Vector3.Up(), frame.orientedWidth / 2);
                    baseplate2.rotate(Vector3.Forward(), Math.PI / 2);
                    baseplate2.rotate(Vector3.Right(), -Math.PI / 2);
                    baseplate.translate(Vector3.Left(), frame.orientedWidth / 2);
                }
            }

            // Swap side if needed
            if (frame.swappedOptions.BASEPLATE) {
                this.optionController.swapOptionsMeshesSide(this.optionController.optionsFamilies.BASEPLATE, frame);
            }
            this.toggleBaseplatesVisibility(frame.visible, frame);
        }
        return Promise.resolve(null);
    }

    /**
     * Return an array with the baseplates reference available for the entity
     * @param {*} entity
     */
    availableBaseplates(entity = null) {
        const finalEntity = this.optionController.returnRealEntity(entity);
        if (!finalEntity) {
            return null;
        }
        if (!this.canHaveBaseplate(finalEntity)) {
            finalEntity.canHaveBaseplatesOption = false;
            return [];
        }
        const availableReferences = [];
        finalEntity.computeOptionsParameters();

        if (finalEntity.orientedWidth >= 0.992) {
            Object.keys(this.baseplatesReferences).forEach((baseplateType) => {
                const ref = this.baseplatesReferences[baseplateType];
                availableReferences.push({
                    ref,
                    type: parseInt(baseplateType, 10),
                    thumbnail: this.products[ref].thumbnailUrl,
                });
            });
        } else if (finalEntity.orientedWidth >= 0.496) {
            const squaredBaseplate = OptionableMixin.BASEPLATE_OPTION.SQUARED;
            const doubleBaseplate = OptionableMixin.BASEPLATE_OPTION.DOUBLE;
            const squaredBPRef = this.baseplatesReferences[squaredBaseplate];
            const doubleBPRef = this.baseplatesReferences[doubleBaseplate];

            availableReferences.push({
                ref: squaredBPRef,
                type: parseInt(squaredBaseplate, 10),
                thumbnail: this.products[squaredBPRef].thumbnailUrl,
            });
            availableReferences.push({
                ref: doubleBPRef,
                type: parseInt(doubleBaseplate, 10),
                thumbnail: this.products[doubleBPRef].thumbnailUrl,
            });
        }

        finalEntity.canHaveBaseplatesOption = availableReferences.length > 0;

        return availableReferences;
    }

    /**
     * Rotate the baseplate around the up axis of the world, of 180 degrees
     * @param {*} entity
     */
    swapBaseplateSide(entity = null) {
        const quaternion = Quaternion.RotationAxis(Vector3.Up(), Math.PI);
        const entityToRotate = this.optionController.returnRealEntity(entity);
        entityToRotate.optionsMeshes.baseplates[0].rotationQuaternion.multiplyInPlace(quaternion);
        entityToRotate.optionsMeshes.baseplates[0].computeWorldMatrix(true);
    }

    /**
     * Does the current entity already have a baseplate or not
     * @param {*} entity if null take the current entity
     */
    haveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }

        if (realEntity.optionsMeshes && realEntity.optionsMeshes.baseplates[0]) {
            return true;
        }
        return false;
    }

    /**
     * Check if the given entity can have a baseplate or not
     * @param {*} entity if null take the current entity
     */
    canHaveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return false;
        }

        const isCorrectlyOriented = OptionUtility.isAlignedWithGround(realEntity.mesh);
        if (!isCorrectlyOriented) {
            return false;
        }

        let isColliding = false;
        realEntity.mesh.computeWorldMatrix();
        realEntity.mesh.refreshBoundingInfo();
        if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < -0.001) {
            isColliding = false;
        } else if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < 0.01) {
            isColliding = true;
        }

        const result = isColliding && isCorrectlyOriented;

        return result;
    }

    /**
     * Callback function used in different events in case we want
     * to update the baseplate information of a frame
     * @param {*} entity if null take the current entity
     */
    updateCanHaveBaseplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            if (realEntity.forceBaseplate) {
                realEntity.canHaveBaseplatesOption = true;
            } else {
                this.availableBaseplates(realEntity);
            }
            if (!realEntity.canHaveBaseplatesOption) {
                this.removeBaseplate(realEntity);
            }
        }
    }

    /**
     * Whats the current entity baseplate type
     * @param {*} entity if null take the current entity
     */
    baseplateType(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }
        return realEntity.getBaseplateOption();
    }

    /**
     * Returns the baseplate corresponding to the ref arguments
     * @param {*} ref
     */
    loadBaseplateFromRef(ref) {
        const baseplate = this.meshManager.getMeshFromLoadedGeometry(ref, 'eco', null, {
            category: 'PARTS',
            subCategory: 'BASE PLATES',
        });

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(baseplate, {
            isOption: true,
        });

        return baseplate;
    }

    /**
     * Toggle the visibilty of the baseplates if there are any
     * @param {*} isVisible
     * @param {*} entity
     */
    toggleBaseplatesVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (realEntity && this.haveBaseplate(realEntity)) {
            realEntity.optionsVisibility.baseplates = isVisible;
            self.app.modules.geometryUtility.toggleMeshesVisibility(realEntity.optionsMeshes.baseplates, isVisible && realEntity.visible);
        }
    }

    /**
     * Returns the baseplate reference from the entity
     * @param {Object} entity
     */
    getBaseplateRef(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);

        if (!realEntity) {
            return null;
        }

        return this.baseplatesReferences[entity.baseplateOption];
    }

    /**
     * Returns the baseplate type from the reference
     * @param {string} ref
     */
    getBaseplateTypeFromRef(ref) {
        let referenceBaseplateType = OptionableMixin.BASEPLATE_OPTION.NONE;

        // Loop through the references array
        Object.keys(this.baseplatesReferences).forEach((baseplateRefAlias) => {
            if (
                this.baseplatesReferences[baseplateRefAlias].includes(ref) ||
                this.oldBaseplatesReferences[baseplateRefAlias].includes(ref)
            ) {
                referenceBaseplateType = parseInt(baseplateRefAlias, 10);
            }
        });

        return referenceBaseplateType;
    }

    /**
     * Returns true if the reference is a baseplate reference
     * @param {string} ref
     */
    isBaseplateRef(ref) {
        return Boolean(this.getBaseplateTypeFromRef(ref));
    }
}
