import self from '../../index';
import { v4 as uuid } from 'uuid';

const Structure = require('@obsidianjs/data-store/src/entity');

const GroupStructure = Structure.$extend({
    __name__: 'group',

    __init__(params) {
        this.dataStore = self.app.modules.dataStore;
        this.nameLocked = false;
        this.$data.default = false;
        this.$data.productIDs = [];
        this.productList = [];
        this.$data.id = uuid();
        this.$data.name = 'Default name';
        this.$super(params);
    },

    /**
     * Add a product in its productList and
     * add a productID in its array of entitiesID
     * @param {String} productID
     */
    addProduct(productID) {
        const indexId = this.$data.productIDs.indexOf(productID);
        const isInList = this.productList.some((product) => product.id === productID);
        const entity = this.dataStore.getEntity(productID);
        if (indexId < 0) {
            this.$data.productIDs.push(productID);
        }
        if (!isInList) {
            entity.group = this;
            entity.groupId = this.id;
            this.productList.push(entity);
        }
        self.app.events.emit('product-added-group', entity);
    },

    /**
     * Add a product in its productList and
     * add a productID in its array of entitiesID
     * @param {Entity} product
     */
    addProductFromEntity(entity) {
        const productID = entity.id;
        const indexId = this.$data.productIDs.indexOf(productID);
        const isInList = this.productList.some((product) => product.id === productID);
        if (indexId < 0) {
            this.$data.productIDs.push(productID);
        }
        if (!isInList) {
            entity.group = this;
            entity.groupId = this.id;
            this.productList.push(entity);
        }
        self.app.events.emit('product-added-group', entity);
    },

    /**
     * Called when the group is created
     * Add all the products in productIDs in the productList
     */
    loadProducts() {
        this.nameLocked = true;
        this.$data.productIDs.forEach((productID) => {
            this.productList.push(this.store.getEntity(productID));
        });
    },

    /**
     * Remove the entityID in its array of entitiesID
     * @param {String || Object} product
     * @param {Boolean} noDefault False by default.
     * True if we don't set the new entity's group to default
     */
    removeProduct(product, noDefault = false) {
        let entity;
        let entityId;

        // Phase 1 - Get the right entity and the right entityId
        if (typeof product === 'string') {
            entity = this.dataStore.getEntity(product);
            entityId = product;
        } else {
            entity = product;
            entityId = entity.id;
        }

        // Phase 2 - Remove the product in productList
        const indexToRemove = this.productList.indexOf(entity);
        if (indexToRemove !== -1) {
            this.productList.splice(indexToRemove, 1);
        }

        // Phase 3 - Remove the productID in productIDs
        const indexIdToRemove = this.productIDs.indexOf(entityId);
        if (indexIdToRemove === -1) {
            return;
        }
        this.productIDs.splice(indexIdToRemove, 1);

        // Phase 4 - If noDefault is false we add the product in the default group
        const defaultGroup = this.dataStore.listEntities('/groups/default')[0];
        if (entity) {
            if (noDefault) {
                // Set group infos of the entity to null
                entity.group = null;
                entity.groupId = null;
            } else {
                defaultGroup.addProduct(entityId);
            }
        }

        // Phase 5 - Send the event
        self.app.events.emit('product-removed-group', entity);

        // Phase 6 (Optional) - If group is not default and empty we remove it and send an event
        if (!this.default && !this.productList.length && !this.productIDs.length) {
            // Check if the entity is not already deleted from dataStore
            if (this.dataStore.getEntity(this.id)) {
                this.dataStore.removeEntity(this.id);
            }
            self.app.events.emit('cleaned-group', this.id);
        }
    },

    /**
     * Add all the entities of the group to an empty parent mesh
     */
    groupProducts() {
        this.$data.productIDs.forEach((id) => {
            const entityStructure = this.store.getEntity(id);
            entityStructure.mesh.parent = this.mesh;
        });
    },

    /**
     *
     * Removes all the entity from the group
     * @param {Boolean} destroy False by default.
     * True if we want all entities to have no groups
     */
    clearProducts(destroy = false) {
        while (this.productList.length > 0) {
            this.removeProduct(this.productList[0].id, destroy);
        }
    },

    updateDynamicParameters() {
        this.productList.splice(0, this.productList.length);
        this.productIDs.forEach((productId) => {
            const product = this.dataStore.getEntity(productId);
            this.productList.push(product);
            product.group = this;
        });
    },

    getId() {
        return this.$data.id;
    },

    isDefault() {
        return this.$data.default;
    },

    setDefault(bool) {
        this.$data.default = bool;
    },

    getName() {
        return this.$data.name;
    },

    setName(name) {
        this.$data.name = name;
    },

    getProductIDs() {
        return this.$data.productIDs;
    },

    setProductIDs(IDs) {
        this.$data.productIDs = IDs;
    },

    /**
     * This empty the group and destroy it in the
     * clearProducts function
     * In the case of the default group, clearProducts
     * function just empty the group
     * So we destroy it manually after the call
     */
    destroy() {
        this.clearProducts(true);
        this.destroyed = true;

        if (this.default && !this.productList.length) {
            this.store.removeEntity(this.id);
        }
    },
});

Structure.$register(GroupStructure);
export default GroupStructure;
