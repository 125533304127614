import AbstractOptionController from './abstract-option-controller';
import self from '../index';
// eslint-disable-next-line import/default
import OptionableMixin from '../model/optionable-mixin';
import config from 'defaultConfig';
import { Vector3 } from '@babylonjs/core';

export default class ScreenBracketOptionController extends AbstractOptionController {
    constructor(optionController) {
        super(optionController);

        this.screenReferences = {};
        this.screenReferences[`${OptionableMixin.SCREEN_OPTION.UNIVERSAL}`] = '901 3703 B62 GALVA - ';
        this.screenReferences[`${OptionableMixin.SCREEN_OPTION.FLATSCREEN}`] = '901 3706 0860 GALVA';

        this.initializeEvents();
    }

    initializeEvents() {
        super.initializeEvents([]);
        self.app.events.on('update-screen-number', (entity) => {
            this.updateScreensMeshes(entity);
        });
        self.app.events.on('@project-manager.init-frame', (entity) => {
            this.updateMaxScreenNumber(entity);
        });
    }

    addScreen(type, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.computeOptionsParameters();
            realEntity.screenOption = type;
            if (realEntity.screenNumber === 0 || realEntity.screenOption === OptionableMixin.SCREEN_OPTION.FLATSCREEN) {
                realEntity.screenNumber = 1;
            }
            if (realEntity.screenOption !== OptionableMixin.SCREEN_OPTION.FLATSCREEN) {
                this.updateMaxScreenNumber(realEntity);
            }
            return this.updateScreensMeshes(realEntity, type);
        }
        return Promise.resolve(null);
    }

    removeScreens(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            realEntity.screenOption = OptionableMixin.SCREEN_OPTION.NONE;
            realEntity.swappedOptions.SCREEN = false;
            ScreenBracketOptionController.removeScreensMeshes(realEntity);
        }
    }

    updateScreensMeshes(entity) {
        ScreenBracketOptionController.removeScreensMeshes(entity);

        if (entity.screenNumber === 0) {
            return Promise.resolve(null);
        }

        const modelRef = this.getScreenRef(entity);
        if (!modelRef) {
            return Promise.resolve(null);
        }
        self.app.events.emit('loading-screen-bracket');
        return this.catalogManager.tryRegisterMeshGeometry(modelRef).then(() => {
            self.app.events.emit('screen-bracket-loaded');
            if (entity.destroyed) {
                return;
            }
            if (entity.optionsMeshes.screens.length >= entity.screenNumber) {
                return;
            }

            ScreenBracketOptionController.computeMaxScreenNumber(entity);
            const nbHoles = entity.orientedHeight / (config.step * 2);
            const interval = Math.round(nbHoles / (entity.screenNumber + 1));

            for (let i = 1; i <= entity.screenNumber; i += 1) {
                // We load the model
                const mesh = this.loadScreenFromRef(modelRef);
                mesh.name = 'screen';
                // We assign the bracket the frame as a parent
                mesh.parent = entity.mesh;

                // Need to compute y
                if (entity.screenOption === OptionableMixin.SCREEN_OPTION.UNIVERSAL) {
                    mesh.position.y = mesh.position.y - entity.orientedHeight / 2 + i * interval * config.step * 2;
                } else {
                    mesh.position.y = mesh.position.y - entity.orientedHeight / 2 + i * interval * config.step * 2 - config.step;
                }
                mesh.computeWorldMatrix(true);

                // If the screen is an universal bracket we need to rotate them
                // (Yes ... the 3d models are not rotated correctly ...)

                if (entity.screenOption === OptionableMixin.SCREEN_OPTION.UNIVERSAL) {
                    mesh.rotate(Vector3.Forward(), Math.PI / 2);
                    mesh.rotate(Vector3.Up(), Math.PI / 2);
                    mesh.rotate(Vector3.Left(), entity.angleToUp);
                } else {
                    mesh.rotate(Vector3.Forward(), entity.angleToUp);
                    mesh.rotate(Vector3.Left(), Math.PI / 2);
                }
                mesh.computeWorldMatrix(true);

                entity.optionsMeshes.screens.push(mesh);
            }

            // Swap side if needed
            if (entity.swappedOptions.SCREEN) {
                this.optionController.swapOptionsMeshesSide(this.optionController.optionsFamilies.SCREEN, entity);
            }
            this.toggleScreensVisibility(entity.visible, entity);
        });
    }

    /**
     * Returns the baseplate corresponding to the ref arguments
     * @param {*} ref
     * @returns {Mesh}
     */
    loadScreenFromRef(ref) {
        const screen = this.meshManager.getMeshFromLoadedGeometry(ref, 'eco', null, {
            category: 'PARTS',
            subCategory: 'FLATSCREEN  PANELHO',
        });

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(screen, {
            isOption: true,
        });

        return screen;
    }

    updateMaxScreenNumber(entity = null, computeOptionParams = true) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            ScreenBracketOptionController.computeMaxScreenNumber(realEntity, computeOptionParams);
        }
    }

    getScreenRef(entity) {
        if (!entity.orientedWidth) {
            return null;
        }
        if (entity.screenOption && entity.screenOption !== OptionableMixin.SCREEN_OPTION.NONE) {
            if (entity.screenOption === OptionableMixin.SCREEN_OPTION.FLATSCREEN) {
                return this.screenReferences[OptionableMixin.SCREEN_OPTION.FLATSCREEN];
            }
            return `${this.screenReferences[OptionableMixin.SCREEN_OPTION.UNIVERSAL]}${Math.ceil(entity.orientedWidth * 1000)}mm`;
        }
        return null;
    }

    toggleScreensVisibility(isVisible, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity && realEntity.optionsMeshes.screens.length > 0) {
            realEntity.optionsVisibility.screens = isVisible;
            self.app.modules.geometryUtility.toggleMeshesVisibility(realEntity.optionsMeshes.screens, isVisible && realEntity.visible);
        }
    }

    static computeMaxScreenNumber(entity, computeOptionParams = true) {
        if (computeOptionParams) {
            entity.computeOptionsParameters();
        }
        entity.canHaveScreenOption = true;
        if (entity.orientedHeight <= 0.62 || entity.orientedWidth > 1.488 || entity.orientedWidth <= 0.248) {
            entity.canHaveScreenOption = false;
            entity.maxScreenNumber = 0;
        } else if (entity.orientedHeight <= 0.93) {
            entity.maxScreenNumber = 1;
        } else if (entity.orientedHeight <= 1.24) {
            entity.maxScreenNumber = 2;
        } else {
            entity.maxScreenNumber = 3;
        }
        if (entity.screenNumber > entity.maxScreenNumber) {
            entity.screenNumber = entity.maxScreenNumber;
        }
    }

    static removeScreensMeshes(entity) {
        entity.optionsMeshes.screens.forEach((screenMesh) => {
            screenMesh.dispose();
        });
        entity.optionsMeshes.screens = [];
    }

    /**
     * Returns the screen bracket type from the reference
     * @param {string} ref
     */
    getScreenTypeFromRef(ref) {
        let referenceScreenType = OptionableMixin.SCREEN_OPTION.NONE;

        // Loop through the references array
        Object.keys(this.screenReferences).forEach((screenRefAlias) => {
            if (ref.includes(this.screenReferences[screenRefAlias])) {
                referenceScreenType = parseInt(screenRefAlias, 10);
            }
        });

        return referenceScreenType;
    }

    /**
     * Returns true if the reference is a screen bracket reference
     * @param {string} ref
     */
    isScreenRef(ref) {
        return Boolean(this.getScreenTypeFromRef(ref));
    }
}
