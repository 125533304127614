/**
 * SpatialGrid is a data structure that allows for fast collision detection by dividing the world into a grid of cells.
 * Each cell contains a list of entities that are currently inside that cell.
 * This allows for fast lookup of nearby entities for a given entity.
 * @class
 * @param {number} cellSize - The size of each cell in the grid
 */
class SpatialGrid {
    constructor(cellSize) {
        this.cellSize = cellSize;
        this.cells = new Map();
    }

    _hash(x, y, z) {
        return `${Math.floor(x / this.cellSize)},${Math.floor(y / this.cellSize)},${Math.floor(z / this.cellSize)}`;
    }

    _getCellsForEntity(position, radius) {
        const maxX = Math.floor((position.x + radius) / this.cellSize);
        const minX = Math.floor((position.x - radius) / this.cellSize);
        const minY = Math.floor((position.y - radius) / this.cellSize);
        const maxY = Math.floor((position.y + radius) / this.cellSize);
        const minZ = Math.floor((position.z - radius) / this.cellSize);
        const maxZ = Math.floor((position.z + radius) / this.cellSize);

        const cells = [];
        for (let x = minX; x <= maxX; x++) {
            for (let y = minY; y <= maxY; y++) {
                for (let z = minZ; z <= maxZ; z++) {
                    const cell = this._hash(x, y, z);
                    if (!cells.includes(cell)) {
                        cells.push(this._hash(x, y, z));
                    }
                }
            }
        }
        return cells;
    }

    addEntity(entity) {
        const cells = this._getCellsForEntity(entity.mesh.getAbsolutePivotPoint(), entity.mesh.boundingSphere.radiusWorld);
        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            if (!this.cells.has(cell)) {
                this.cells.set(cell, []);
            }
            if (!this.cells.get(cell).includes(entity)) {
                this.cells.get(cell).push(entity);
            }
        }
    }

    removeEntity(entity, position, radius) {
        const cells = this._getCellsForEntity(
            position || entity.mesh.getAbsolutePivotPoint(),
            radius || entity.mesh.boundingSphere.radiusWorld,
        );
        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            if (this.cells.has(cell)) {
                const index = this.cells.get(cell).indexOf(entity);
                if (index !== -1) {
                    this.cells.get(cell).splice(index, 1);
                    if (this.cells.get(cell).length === 0) {
                        this.cells.delete(cell);
                    }
                }
            }
        }
    }

    updateEntity(entity) {
        this.removeEntity(entity, entity.mesh.oldPosition);
        this.addEntity(entity);
    }

    getNearbyEntities(entity) {
        const cells = this._getCellsForEntity(entity.mesh.getAbsolutePivotPoint(), entity.mesh.boundingSphere.radiusWorld);
        const nearbyEntities = new Set();

        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            if (this.cells.has(cell)) {
                for (let j = 0; j < this.cells.get(cell).length; j++) {
                    if (this.cells.get(cell)[j] !== entity) {
                        nearbyEntities.add(this.cells.get(cell)[j]);
                    }
                }
            }
        }
        return Array.from(nearbyEntities);
    }
}

export default SpatialGrid;
