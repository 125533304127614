const RalColors = {
    Colors: [
        {
            code: '1018 TEC',
            description: 'RAL1018 - Zink Yellow TEC',
            hex: '#F8F32B',
        },
        {
            code: '2009',
            description: 'RAL2009 - Traffic Orange',
            hex: '#F54021',
        },
        {
            code: '3020 TEC',
            description: 'RAL3020 - Traffic Red TEC',
            hex: '#CC0605',
        },
        {
            code: '4010',
            description: 'RAL4010 - Telemagenta',
            hex: '#CF3476',
        },
        {
            code: '5002 TEC',
            description: 'RAL5002 - Ultramarine Blue TEC',
            hex: '#20214F',
        },
        {
            code: '6029 TEC',
            description: 'RAL6029 - Mint Green TEC',
            hex: '#20603D',
        },
        {
            code: '8017 TEC',
            description: 'RAL8017 - Chocolate Brown TEC',
            hex: '#45322E',
        },
        {
            code: '9005 TEC',
            description: 'RAL9005 - Jet Black TEC',
            hex: '#0A0A0A',
        },
        {
            code: '9006 TEC',
            description: 'RAL9006 - White Aluminium (Metalic Color) TEC',
            hex: '#A5A5A5',
        },
        {
            code: '9016 TEC',
            description: 'RAL9016 - Traffic White TEC',
            hex: '#F6F6F9',
        },
    ],

    getColorCodes() {
        const codes = RalColors.Colors.map((colorInfo) => colorInfo.hex);
        return codes;
    },

    getDefaultColor() {
        return RalColors.Colors[RalColors.Colors.length - 2].hex;
    },

    getRalFromHex(hex) {
        const colInfos = RalColors.Colors.find((c) => c.hex === hex);
        if (colInfos) {
            return colInfos.code;
        }
        return undefined;
    },
};
module.exports = RalColors;
