import { BoxArrow, AXIS, NORMALS, COLORS, addHoverMaterial } from '../helpers/gizmo-meshes-helper';
import { UtilityLayerRenderer, Observable, Gizmo, Color3, Vector3, AxisScaleGizmo, Tools } from '@babylonjs/core';

export default class ScaleGizmo extends Gizmo {
    /**
     * This is a modified ScaleGizmo wich is taken from ScaleGizmo,
     * we don't extend it because there are some properties of the class we
     * don't want to extend
     *
     * Creates a ScaleGizmo
     * @param gizmoLayer The utility layer the gizmo will be added to
     */
    constructor(gizmoLayer = UtilityLayerRenderer.DefaultUtilityLayer) {
        super(gizmoLayer);
        this.onDragStartObservable = new Observable();
        this.onDragEndObservable = new Observable();
        this.initGizmos(gizmoLayer);
        this.attachedMesh = null;
    }

    initGizmos(gizmoLayer) {
        // X axis
        const xCustomMesh = new BoxArrow(NORMALS[AXIS.X], COLORS[AXIS.X]);
        const xMinusCustomMesh = new BoxArrow(NORMALS[AXIS.X], COLORS[AXIS.X]);
        xCustomMesh.mesh.rotate(Vector3.Forward(), -Math.PI / 2);
        xCustomMesh.mesh.translate(Vector3.Up(), 0.025);
        xMinusCustomMesh.mesh.rotate(Vector3.Forward(), Math.PI / 2);
        xMinusCustomMesh.mesh.translate(Vector3.Up(), 0.025);

        this.xGizmo = new AxisScaleGizmo(Vector3.Right(), Color3.Red().scale(0.5), gizmoLayer);
        this.xGizmo.dragAxis = Vector3.Right();
        this.xGizmo.setCustomMesh(xCustomMesh.mesh);
        addHoverMaterial(this.xGizmo, COLORS[AXIS.X], xCustomMesh.material);
        this.xMinusGizmo = new AxisScaleGizmo(Vector3.Left(), Color3.Red().scale(0.5), gizmoLayer);
        this.xMinusGizmo.dragAxis = Vector3.Left();
        this.xMinusGizmo.setCustomMesh(xMinusCustomMesh.mesh);
        addHoverMaterial(this.xMinusGizmo, COLORS[AXIS.X], xMinusCustomMesh.material);

        // Y axis
        const yCustomMesh = new BoxArrow(NORMALS[AXIS.Y], COLORS[AXIS.Y]);
        const yMinusCustomMesh = new BoxArrow(NORMALS[AXIS.Y], COLORS[AXIS.Y]);
        yCustomMesh.mesh.translate(Vector3.Up(), 0.025);
        yMinusCustomMesh.mesh.rotate(Vector3.Forward(), Math.PI);
        yMinusCustomMesh.mesh.translate(Vector3.Up(), 0.025);

        this.yGizmo = new AxisScaleGizmo(Vector3.Up(), Color3.Green().scale(0.5), gizmoLayer);
        this.yGizmo.dragAxis = Vector3.Up();
        this.yGizmo.setCustomMesh(yCustomMesh.mesh);
        addHoverMaterial(this.yGizmo, COLORS[AXIS.Y], yCustomMesh.material);
        this.yMinusGizmo = new AxisScaleGizmo(Vector3.Down(), Color3.Green().scale(0.5), gizmoLayer);
        this.yMinusGizmo.dragAxis = Vector3.Down();
        this.yMinusGizmo.setCustomMesh(yMinusCustomMesh.mesh);
        addHoverMaterial(this.yMinusGizmo, COLORS[AXIS.Y], yMinusCustomMesh.material);

        // Z axis
        const zCustomMesh = new BoxArrow(NORMALS[AXIS.Z], COLORS[AXIS.Z]);
        const zMinusCustomMesh = new BoxArrow(NORMALS[AXIS.Z], COLORS[AXIS.Z]);
        zCustomMesh.mesh.rotate(Vector3.Right(), Math.PI / 2);
        zCustomMesh.mesh.translate(Vector3.Up(), 0.025);
        zMinusCustomMesh.mesh.rotate(Vector3.Right(), -Math.PI / 2);
        zMinusCustomMesh.mesh.translate(Vector3.Up(), 0.025);

        this.zGizmo = new AxisScaleGizmo(Vector3.Forward(), Color3.Blue().scale(0.5), gizmoLayer);
        this.zGizmo.dragAxis = Vector3.Forward();
        this.zGizmo.setCustomMesh(zCustomMesh.mesh);
        addHoverMaterial(this.zGizmo, COLORS[AXIS.Z], zCustomMesh.material);
        this.zMinusGizmo = new AxisScaleGizmo(Vector3.Backward(), Color3.Blue().scale(0.5), gizmoLayer);
        this.zMinusGizmo.dragAxis = Vector3.Backward();
        this.zMinusGizmo.setCustomMesh(zMinusCustomMesh.mesh);
        addHoverMaterial(this.zMinusGizmo, COLORS[AXIS.Z], zMinusCustomMesh.material);

        this.gizmos = [this.xGizmo, this.xMinusGizmo, this.yGizmo, this.yMinusGizmo, this.zGizmo, this.zMinusGizmo];
    }

    /** Default BABYLON Function */
    set attachedMesh(mesh) {
        if (this.xGizmo) {
            this.gizmos.forEach((gizmo) => {
                gizmo.attachedMesh = mesh;
            });
        }
    }

    get attachedMesh() {
        return this.gizmos[0].attachedMesh;
    }

    /** Default BABYLON Function */
    set attachedEntity(entity) {
        this.gizmos.forEach((gizmo) => {
            gizmo.attachedEntity = entity;
        });
    }

    get attachedEntity() {
        return this.gizmos[0].attachedEntity;
    }

    /** Default BABYLON Function */
    set updateGizmoRotationToMatchAttachedMesh(value) {
        if (!value) {
            Tools.Warn('Setting updateGizmoRotationToMatchAttachedMesh = false on scaling gizmo is not supported.');
        }
        if (this.xGizmo) {
            this.gizmos.forEach((gizmo) => {
                gizmo.updateGizmoRotationToMatchAttachedMesh = value;
            });
        }
    }

    /** Default BABYLON Function */
    get updateGizmoRotationToMatchAttachedMesh() {
        return this.xGizmo.updateGizmoRotationToMatchAttachedMesh;
    }

    /**
     * Default BABYLON Function
     * Drag distance in babylon units that the gizmo will snap to when dragged (Default: 0)
     */
    set snapDistance(value) {
        if (this.xGizmo) {
            this.gizmos.forEach((gizmo) => {
                gizmo.snapDistance = value;
            });
        }
    }

    /** Default BABYLON Function */
    get snapDistance() {
        return this.xGizmo.snapDistance;
    }

    /**
     * Default BABYLON Function
     * Ratio for the scale of the gizmo (Default: 1)
     */
    set scaleRatio(value) {
        if (this.xGizmo) {
            this.gizmos.forEach((gizmo) => {
                gizmo.scaleRatio = value;
            });
        }
    }

    /** Default BABYLON Function */
    get scaleRatio() {
        return this.xGizmo.scaleRatio;
    }

    /**
     * Disposes of the gizmo
     * Default BABYLON Function
     */
    dispose() {
        this.gizmos.forEach((gizmo) => {
            gizmo.dispose();
        });
        this.onDragStartObservable.clear();
        this.onDragEndObservable.clear();
    }
}
