/* eslint max-classes-per-file: 0 */
import { StandardMaterial, Mesh, Vector3, MeshBuilder, UtilityLayerRenderer, Color3, Color4 } from '@babylonjs/core';

export const PLANES = {
    XY: 0,
    YZ: 1,
    XZ: 2,
};

export const AXIS = {
    X: 0,
    Y: 1,
    Z: 2,
};

export const NORMALS = [Vector3.Forward(), Vector3.Right(), Vector3.Up()];

export const COLORS = [Color3.Red(), Color3.Green(), Color3.Blue()];

export const GizmoMaterialAlpha = 0.75;

class AbstractGizmoMesh {
    constructor(normal, color) {
        this.normal = normal;

        this.color = color;

        this.material = new StandardMaterial('');
        this.material.disableLighting = true;
        this.material.emissiveColor = color;
        this.material.alpha = GizmoMaterialAlpha;
    }
}

export class Plane extends AbstractGizmoMesh {
    constructor(
        normal,
        color,
        planeOptions = {
            size: 0.05,
            sideOrientation: Mesh.DOUBLESIDE,
        },
    ) {
        super(normal, color);

        this.mesh = MeshBuilder.CreatePlane('planeMesh', planeOptions, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        this.mesh.material = this.material;
    }
}

export class Torus extends AbstractGizmoMesh {
    constructor(
        normal,
        color,
        torusOptions = {
            diameter: 0.15,
            thickness: 0.007,
            tessellation: 32,
        },
    ) {
        super(normal, color);

        this.mesh = MeshBuilder.CreateTorus('torus-gizmo', torusOptions, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        this.mesh.material = this.material;
    }
}

export class Arrow extends AbstractGizmoMesh {
    constructor(
        normal,
        color,
        cylinderOptions = {
            height: 0.1,
            diameter: 0.005,
        },
        arrowOptions = {
            height: 0.025,
            diameterTop: 0,
            diameterBottom: 0.01,
        },
    ) {
        super(normal, color);

        // Cylinder
        this.mesh = MeshBuilder.CreateCylinder(
            'cylinder-gizmo',
            cylinderOptions,
            UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene,
        );
        this.mesh.material = this.material;

        // Arrow
        const arrow = MeshBuilder.CreateCylinder(
            'arrow-tip-gizmo',
            arrowOptions,
            UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene,
        );
        arrow.parent = this.mesh;
        arrow.material = this.material;
        arrow.translate(Vector3.Up(), 0.05);
    }
}

export class BoxArrow extends AbstractGizmoMesh {
    constructor(
        normal,
        color,
        cylinderOptions = {
            height: 0.05,
            diameter: 0.005,
        },
        boxOptions = {
            size: 0.0125,
        },
    ) {
        super(normal, color);

        // Cylinder
        this.mesh = MeshBuilder.CreateCylinder(
            'cylinder-gizmo',
            cylinderOptions,
            UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene,
        );
        this.mesh.material = this.material;

        // Box
        const box = MeshBuilder.CreateBox('box-tip-gizmo', boxOptions, UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene);
        box.parent = this.mesh;
        box.material = this.material;
        box.translate(Vector3.Up(), 0.025);
    }
}

/**
 * Add a hover material to the gizmo, on hover the material emissiveColor will be
 * color + Color4(0.4, 0.4, 0.4), after hover it will sitch back to coloredMaterial
 * @param {Gizmo} gizmo gizmo to apply the material to
 * @param {Color} color color that will be used to create on hover color
 * @param {Material} coloredMaterial material used to after hover
 */
export function addHoverMaterial(gizmo, color, coloredMaterial) {
    const hoverMaterial = new StandardMaterial('');
    hoverMaterial.disableLighting = true;
    hoverMaterial.emissiveColor = color.add(new Color4(0.4, 0.4, 0.4));
    hoverMaterial.alpha = GizmoMaterialAlpha;
    gizmo._pointerObserver = UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene.onPointerObservable.add((pointerInfo) => {
        const isHovered = pointerInfo.pickInfo && gizmo._rootMesh.getChildMeshes().indexOf(pointerInfo.pickInfo.pickedMesh) !== -1;
        const material = isHovered ? hoverMaterial : coloredMaterial;
        gizmo._rootMesh.getChildMeshes().forEach((m) => {
            m.material = material;
            if (m.color) {
                m.color = material.emissiveColor;
            }
        });
    });
}
