import EngineHelper from '../helpers/engine-helper';
import self from '../..';
import { UtilityLayerRenderer } from '@babylonjs/core';

const {
    app: { events },
} = self;

const BUTTON = {
    LEFT_CLICK: 0,
};

export const PICKING_TYPE = {
    mesh: Symbol('mesh'),
    gizmo: Symbol('gizmo'),
    sprite: Symbol('sprite'),
};

export default class MouseEventHandler {
    constructor(scene, canvas) {
        this.scene = scene;
        this.canvas = canvas;
        this.dragging = false;
        this.selectedObject = null;
        this.modifierPressed = false;
        this.selectionController = null;
        this._dragStartPosition = null;

        this.initListening();
    }

    initListening() {
        events.on('modifierPressed', () => {
            this.modifierPressed = true;
        });
        events.on('modifierReleased', () => {
            this.modifierPressed = false;
        });
        events.on('cancel', () => {
            this.selectedObject = null;
            this.dragging = false;
        });
        events.on('@obsidian-engine.undo', () => {
            this.selectedObject = null;
            this.dragging = false;
        });
        events.on('@building-plan-manager.toggle-connector-selection', (isEnabled) => {
            this.connectorSelectionOnly = isEnabled;
        });
        events.on('@selection-manager.last-unselected', () => {
            this.selectedObject = null;
        });
        events.on('@selection-manager.drag-new', () => {
            this.dragging = true;
        });
        this.onPointerMoveEventHandler = this.onPointerMove.bind(this);
        this.onPointerDownEventHandler = this.onPointerDown.bind(this);
        this.onPointerUpEventHandler = this.onPointerUp.bind(this);
        this.enableMouseEvents();
        this.canvas.addEventListener('dblclick', (eventInfo) => {
            events.emit('double-click', eventInfo);
        });
    }

    enableMouseEvents() {
        this.canvas.addEventListener('pointermove', this.onPointerMoveEventHandler);
        this.canvas.addEventListener('pointerdown', this.onPointerDownEventHandler);
        this.canvas.addEventListener('pointerup', this.onPointerUpEventHandler);
    }

    disableMouseEvents() {
        this.canvas.removeEventListener('pointermove', this.onPointerMoveEventHandler);
        this.canvas.removeEventListener('pointerdown', this.onPointerDownEventHandler);
        this.canvas.removeEventListener('pointerup', this.onPointerUpEventHandler);
    }

    onPointerMove() {
        if (!this.dragging && this.selectedObject) {
            events.emit('drag-start', this._dragStartPosition);
            this.dragging = true;
        }
        if (this.dragging) {
            events.emit('dragging');
        }
    }

    onPointerDown(event) {
        if (event.button !== BUTTON.LEFT_CLICK) {
            // We handle only left click
            return;
        }
        const pickingResult = this.checkPicking();
        if (pickingResult.type === PICKING_TYPE.mesh) {
            // We picked a mesh
            this.selectedObject = pickingResult.pickinfo.pickedMesh;

            const pickedPosition = pickingResult.pickinfo.pickedPoint;

            const isAlreadySelected = this.selectedObject.entity.isSelected;

            if (isAlreadySelected) {
                if (this.modifierPressed) {
                    events.emit('unselect-mesh', this.selectedObject);
                }
            } else {
                events.emit('select-mesh', this.selectedObject, this.modifierPressed);
            }
            this._dragStartPosition = pickedPosition;

            // (this.selectedObject.metadata && this.selectedObject.metadata.isOption)
        } else if (pickingResult.type === PICKING_TYPE.sprite) {
            // We picked a sprite
            // If sprite exists run it's click function
            const sprite = pickingResult.pickinfo.pickedSprite;
            if (typeof sprite.onSpriteClicked === 'function') {
                sprite.onSpriteClicked();
            }
        }
    }

    onPointerUp(event) {
        if (event.button !== BUTTON.LEFT_CLICK) {
            // We handle only left click
            return;
        }
        this.selectedObject = null;
        if (this.dragging) {
            this.dragging = false;
            events.emit('drag-end');
        }
    }

    /**
     * Check what object is picked in the scene
     * @return {Object} the type of the object picked (PICKING_TYPE) and the pickinfos
     */
    checkPicking() {
        const pickResult = this.sceneMousePick();
        const gizmoResult = MouseEventHandler.gizmoLayerMousePick(this.scene.pointerX, this.scene.pointerY);
        const spriteResult = this.scene.pickSprite(this.scene.pointerX, this.scene.pointerY);

        if (gizmoResult.hit) {
            return { type: PICKING_TYPE.gizmo, pickinfo: gizmoResult };
        }

        if (spriteResult.hit && pickResult.hit) {
            if (spriteResult.distance < pickResult.distance) {
                return { type: PICKING_TYPE.sprite, pickinfo: spriteResult };
            }
            return { type: PICKING_TYPE.mesh, pickinfo: pickResult };
        }

        if (spriteResult.hit) {
            return { type: PICKING_TYPE.sprite, pickinfo: spriteResult };
        }
        if (pickResult.hit) {
            return { type: PICKING_TYPE.mesh, pickinfo: pickResult };
        }

        return { type: -1, pickinfo: null };
    }

    /**
     * Scene picking selecting the parent if a child is picked or return an option if clicked on one
     */
    sceneMousePick() {
        const predicateFunction = this.connectorSelectionOnly ? MouseEventHandler.connectorsOnly : MouseEventHandler.excludeConnectors;
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY, predicateFunction);

        if (pickResult.hit && pickResult.pickedMesh) {
            if (EngineHelper.HasPickedIgnoredMesh(pickResult)) {
                return { distance: 0.0 };
            }

            while (pickResult.pickedMesh.parent) {
                pickResult.pickedMesh = pickResult.pickedMesh.parent;
            }
        }

        return pickResult;
    }

    /**
     * Returns true if the mesh in argument entity is a connector
     * @param{Mesh} mesh
     * @returns{boolean}
     */
    static connectorsOnly(mesh) {
        let rootMesh = mesh;
        while (rootMesh.parent) {
            rootMesh = rootMesh.parent;
        }

        if (rootMesh && rootMesh.entity) {
            return rootMesh.entity.isConnector && rootMesh.isVisible;
        }
        return false;
    }

    static excludeConnectors(mesh) {
        let rootMesh = mesh;
        while (rootMesh.parent) {
            rootMesh = rootMesh.parent;
        }

        if (rootMesh && rootMesh.entity) {
            return (!rootMesh.entity.isConnector || rootMesh.entity.ref === 'ErrorBlock') && rootMesh.isVisible;
        }
        return false;
    }

    static gizmoLayerMousePick(x, y) {
        return UtilityLayerRenderer.DefaultUtilityLayer.utilityLayerScene.pick(x, y);
    }
}
