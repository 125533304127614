export default {
    name: 'catalog-manager',
    requires: ['request-manager', 'mesh-manager', 'material-manager', 'iframe-api'],
    load() {
        const Catalog = require('./src/catalog-controller').default;
        const Controller = new Catalog();
        return Controller;
    },
    unload() {},
};
