// This helper files is not supposed to be exposed
// If this function as to be used outside this module
// A similar function have to be added to the option controller

import MathHelper from '../../../helpers/math-helper';

import self from '..';
import { TransformNode, Vector3, MeshBuilder, Plane, Mesh, Matrix } from '@babylonjs/core';

const { modules } = self.app;

const INFILL_CELL_MAX_SIZE = 2048;

export default class InfillHelper {
    /**
     * Return true if the passed reference in an infill one
     * @param {string} ref infill reference
     * @returns {boolean}
     */
    static isInfillRef(ref) {
        // Special case for R430
        if (ref.includes('PANEL FOR')) {
            return true;
        }
        return false;
    }

    static checkInfillBothSides(entity) {
        if (entity.subCategory === 'STORAGE DOORS') {
            entity.hasInfillsBothSide = false;
        }
    }

    static getPlaneMatrixFromEntityInfill(entity) {
        let localPlaneMatrix = null;
        let originPoint = null;
        let planeMesh = null;
        if (entity.optionsMeshes.infills.length === 1) {
            // Get infill reference
            const infillRef = entity.optionsMeshes.infills[0].metadata.ref;
            const catalogItem = modules.catalogManager.getCatalogItemFromRef(infillRef);
            const splitedPartNumber = catalogItem.partNumber.split(' ');
            const width = Number.parseInt(splitedPartNumber[1], 10) * 0.001;
            const height = Number.parseInt(splitedPartNumber[2], 10) * 0.001;

            const pointA = new TransformNode('pointA', entity.mesh.scene);
            const pointB = new TransformNode('pointA', entity.mesh.scene);
            const pointC = new TransformNode('pointA', entity.mesh.scene);

            pointA.parent = entity.optionsMeshes.infills[0];
            pointB.parent = entity.optionsMeshes.infills[0];
            pointC.parent = entity.optionsMeshes.infills[0];

            pointA.position = new Vector3(-width / 2, height / 2, 0);
            pointB.position = new Vector3(width / 2, height / 2, 0);
            pointC.position = new Vector3(width / 2, -height / 2, 0);

            const localPlane = Plane.FromPoints(pointA.getAbsolutePosition(), pointB.getAbsolutePosition(), pointC.getAbsolutePosition());
            planeMesh = MeshBuilder.CreatePlane('plane', {
                size: 50,
                sourcePlane: localPlane,
                sideOrientation: Mesh.DOUBLESIDE,
            });
            planeMesh.isVisible = false;
            planeMesh.position = pointA.getAbsolutePosition();
            originPoint = pointA.getAbsolutePosition();
            planeMesh.computeWorldMatrix(true);
            localPlaneMatrix = Matrix.Identity();
            planeMesh.getWorldMatrix().invertToRef(localPlaneMatrix);
        }
        return { localPlaneMatrix, originPoint, planeMesh };
    }

    static generateInfillListInfos(entityList) {
        const infillInfosList = [];

        const { localPlaneMatrix: planeMatrix, originPoint, planeMesh } = this.getPlaneMatrixFromEntityInfill(entityList[0]);
        entityList.forEach((entity) => {
            const entityInfillInfos = this.getInfillInfos(entity, planeMatrix, originPoint);
            infillInfosList.push(entityInfillInfos);
        });
        this.reprojectFromFurthestPoint(infillInfosList, planeMesh);
        return infillInfosList;
    }

    static getInfillInfos(entity, localPlaneMatrix) {
        if (entity.optionsMeshes.infills.length === 1) {
            // Get infill reference
            const infillInfos = this.getInfillsPointInfos(entity);
            const infillProjectedInfos = this.getInfillProjectedInfos(infillInfos, localPlaneMatrix);
            return {
                entity,
                infillInfos,
                infillProjectedInfos,
            };
        }
        return null;
    }

    static getInfillsPointInfos(entity) {
        const infillInfos = {
            originPoint: null,
            width: 0,
            height: 0,
            rotationInfo: {
                rotationType: 0, // 0 for 180, 1 for 90, 2 for -90, 3 for 0
            },
        };
        const infillRef = entity.optionsMeshes.infills[0].metadata.ref;
        const catalogItem = modules.catalogManager.getCatalogItemFromRef(infillRef);
        const splitedPartNumber = catalogItem.partNumber.split(' ');
        const rotation = entity.rotationQuaternion.toEulerAngles();

        let rotated90Negative = false;
        let rotated90Positive = false;
        let rotated0 = false;

        if (rotation.z === 0) {
            const dot = Vector3.Dot(rotation, new Vector3(0, 1, 0));
            const step = Math.floor((dot + Math.PI / 2) / (Math.PI / 2));

            if (step === 3) {
                rotated0 = true;
            } else if (step === 2) {
                rotated90Negative = true;
            } else if (step === 0) {
                rotated90Positive = true;
            }
        } else {
            rotated90Negative = rotation.z < -Math.PI / 2 + 0.001 && rotation.z > -Math.PI / 2 - 0.001;
            rotated90Positive = rotation.z < Math.PI / 2 + 0.001 && rotation.z > Math.PI / 2 - 0.001;
            rotated0 = rotation.z < 0.001 && rotation.z > -0.001;
        }

        let width;
        let height;
        const pointA = new TransformNode('pointA', entity.mesh.scene);

        pointA.parent = entity.optionsMeshes.infills[0];
        if (rotated90Negative || rotated90Positive) {
            width = Number.parseInt(splitedPartNumber[2], 10) * 0.001;
            height = Number.parseInt(splitedPartNumber[1], 10) * 0.001;
            if (rotated90Negative) {
                infillInfos.rotationInfo.rotationType = 2;
                pointA.position = new Vector3(-height / 2, width / 2, 0);
            } else {
                infillInfos.rotationInfo.rotationType = 1;
                pointA.position = new Vector3(height / 2, -width / 2, 0);
            }
        } else {
            width = Number.parseInt(splitedPartNumber[1], 10) * 0.001;
            height = Number.parseInt(splitedPartNumber[2], 10) * 0.001;
            if (rotated0) {
                infillInfos.rotationInfo.rotationType = 3;
                pointA.position = new Vector3(width / 2, height / 2, 0);
            } else {
                pointA.position = new Vector3(-width / 2, -height / 2, 0);
            }
        }

        infillInfos.originPoint = pointA.getAbsolutePosition();
        infillInfos.width = width;
        infillInfos.height = height;
        pointA.dispose();
        return infillInfos;
    }

    static getInfillProjectedInfos(infillInfos, localPlaneMatrix) {
        return Vector3.TransformCoordinates(infillInfos.originPoint, localPlaneMatrix);
    }

    static reprojectFromFurthestPoint(infillInfos, planeMesh) {
        const maxCoordinateInfillX = infillInfos.reduce((previousValue, currentInfillInfos) => {
            if (previousValue.infillProjectedInfos.x < currentInfillInfos.infillProjectedInfos.x) {
                return currentInfillInfos;
            }
            return previousValue;
        }, infillInfos[0]);

        const maxCoordinateInfillY = infillInfos.reduce((previousValue, currentInfillInfos) => {
            if (previousValue.infillProjectedInfos.y < currentInfillInfos.infillProjectedInfos.y) {
                return currentInfillInfos;
            }
            return previousValue;
        }, infillInfos[0]);

        const virtualPoint = new TransformNode('virtualPoint', planeMesh.scene);
        virtualPoint.parent = planeMesh;
        virtualPoint.position = new Vector3(maxCoordinateInfillX.infillProjectedInfos.x, maxCoordinateInfillY.infillProjectedInfos.y, 0);
        const originPoint = virtualPoint.getAbsolutePosition();
        planeMesh.position = originPoint;
        planeMesh.computeWorldMatrix(true);
        const localPlaneMatrix = Matrix.Identity();
        planeMesh.getWorldMatrix().invertToRef(localPlaneMatrix);
        planeMesh.dispose();
        infillInfos.forEach((infillInfo) => {
            infillInfo.infillProjectedInfos = this.getInfillProjectedInfos(infillInfo.infillInfos, localPlaneMatrix);
        });
    }

    static computeHeightAndWidthFromInfillInfos(infillInfos) {
        const firstLowY = infillInfos[0].infillProjectedInfos.y - infillInfos[0].infillInfos.height;

        const lowestY = infillInfos.reduce((previousValue, currentInfillInfos) => {
            const currentLowY = currentInfillInfos.infillProjectedInfos.y - currentInfillInfos.infillInfos.height;
            if (currentLowY < previousValue) {
                return currentLowY;
            }
            return previousValue;
        }, firstLowY);

        const firstLowX = infillInfos[0].infillProjectedInfos.x - infillInfos[0].infillInfos.width;

        const lowestX = infillInfos.reduce((previousValue, currentInfillInfos) => {
            const currentLowX = currentInfillInfos.infillProjectedInfos.x - currentInfillInfos.infillInfos.width;
            if (currentLowX < previousValue) {
                return currentLowX;
            }
            return previousValue;
        }, firstLowX);

        const maxCoordinateInfillX = infillInfos.reduce((previousValue, currentInfillInfos) => {
            if (previousValue.infillProjectedInfos.x < currentInfillInfos.infillProjectedInfos.x) {
                return currentInfillInfos;
            }
            return previousValue;
        }, infillInfos[0]);

        const maxCoordinateInfillY = infillInfos.reduce((previousValue, currentInfillInfos) => {
            if (previousValue.infillProjectedInfos.y < currentInfillInfos.infillProjectedInfos.y) {
                return currentInfillInfos;
            }
            return previousValue;
        }, infillInfos[0]);

        // treatCoordinates
        infillInfos.forEach((infillInfo) => {
            infillInfo.infillProjectedInfos.x *= -1;
            infillInfo.infillProjectedInfos.y *= -1;
        });
        let width = Math.abs(maxCoordinateInfillX.infillProjectedInfos.x - lowestX);

        let height = Math.abs(maxCoordinateInfillY.infillProjectedInfos.y - lowestY);

        width *= 1000;
        height *= 1000;

        return { width, height };
    }

    static createCanvasForInfill(img, infillInfo, infillSizeData) {
        const imgSizeData = {
            width: img.width,
            height: img.height,
        };

        const orientation = infillInfo.infillInfos.rotationInfo.rotationType;
        const { width, height } = infillSizeData;

        const widthRatioSrc = width / imgSizeData.width;
        const heightRatioSrc = height / imgSizeData.height;

        const widthRatioDst = imgSizeData.width / width;
        const heightRatioDst = imgSizeData.height / height;

        let infillCanvasWidth = Math.round(infillInfo.infillInfos.width * 1000 * widthRatioSrc);
        let infillCanvasHeight = Math.round(infillInfo.infillInfos.height * 1000 * heightRatioSrc);

        const infillCanvasConstrainSize = InfillHelper.getConstraintCanvasSize(infillCanvasWidth, infillCanvasHeight, imgSizeData);
        infillCanvasWidth = infillCanvasConstrainSize.width;
        infillCanvasHeight = infillCanvasConstrainSize.height;

        const posX = infillInfo.infillProjectedInfos.x * 1000 * widthRatioDst;
        const posY = infillInfo.infillProjectedInfos.y * 1000 * heightRatioDst;

        const srcWidth = infillInfo.infillInfos.width * 1000 * widthRatioDst;
        const srcHeight = infillInfo.infillInfos.height * 1000 * heightRatioDst;

        const tmpCanvas = document.createElement('canvas');
        let ctx;
        if (orientation === 3 || !orientation) {
            tmpCanvas.width = infillCanvasWidth;
            tmpCanvas.height = infillCanvasHeight;
            ctx = tmpCanvas.getContext('2d');
            if (orientation === 3) {
                ctx.translate(tmpCanvas.width, tmpCanvas.height);
                ctx.rotate(Math.PI);
            }
        } else {
            tmpCanvas.width = infillCanvasHeight;
            tmpCanvas.height = infillCanvasWidth / 2;
            ctx = tmpCanvas.getContext('2d');
            if (orientation === 1) {
                ctx.translate(tmpCanvas.width, 0);
                ctx.rotate(Math.PI / 2);
            } else {
                ctx.translate(0, tmpCanvas.height);
                ctx.rotate(-Math.PI / 2);
            }
        }
        ctx.drawImage(img, posX, posY, srcWidth, srcHeight, 0, 0, tmpCanvas.width, tmpCanvas.height);
        return tmpCanvas;
    }

    static getConstraintCanvasSize(infillCanvasWidth, infillCanvasHeight, imgSizeData) {
        let _infillCanvasWidth = infillCanvasWidth;
        let _infillCanvasHeight = infillCanvasHeight;
        if (infillCanvasHeight > infillCanvasWidth) {
            const max = Math.min(imgSizeData.height, MathHelper.clamp(infillCanvasHeight, imgSizeData.height, INFILL_CELL_MAX_SIZE));
            if (infillCanvasHeight > max) {
                const ratio = max / infillCanvasHeight;
                _infillCanvasHeight = max;
                _infillCanvasWidth = Math.round(infillCanvasWidth * ratio);
            }
        } else if (infillCanvasWidth > infillCanvasHeight) {
            const max = Math.min(imgSizeData.width, MathHelper.clamp(infillCanvasWidth, imgSizeData.width, INFILL_CELL_MAX_SIZE));
            if (infillCanvasWidth > max) {
                const ratio = max / infillCanvasWidth;
                _infillCanvasWidth = max;
                _infillCanvasHeight = Math.round(infillCanvasHeight * ratio);
            }
        } else {
            const max = Math.min(imgSizeData.width, MathHelper.clamp(infillCanvasWidth, imgSizeData.width, INFILL_CELL_MAX_SIZE));
            if (infillCanvasWidth > max) {
                _infillCanvasWidth = max;
                _infillCanvasHeight = max;
            }
        }
        return {
            width: _infillCanvasWidth,
            height: _infillCanvasHeight,
        };
    }
}
