import self from '../..';
import { filterCollectionByUniqueFirstKey } from '../../../../helpers/js-helper';
import ConsoleHelper from '../../../../helpers/console-helper';
import VueHelper from 'helpers/vue-helper';
import config from 'defaultConfig';
import { Color3, CubeTexture, HDRCubeTexture, Texture, PBRMaterial, StandardMaterial } from '@babylonjs/core';

const BABYLON = {
    PBRMaterial,
    StandardMaterial,
    Texture,
    CubeTexture,
    HDRCubeTexture,
    Color3,
};

export default class MaterialManager {
    /**
     * @class bematrix.MaterialManager
     * */
    constructor() {
        this.path = '/assets/textures/';

        this.loadedMaterials = [];
        ConsoleHelper.expose('loadedMaterials', this.loadedMaterials);

        this.loadedLocalTextures = [];
        ConsoleHelper.expose('loadedLocalTextures', this.loadedLocalTextures);

        this.loadedTextureGLB = [];
        ConsoleHelper.expose('loadedTextureGLB', this.loadedTextureGLB);

        this.defaultMaterial = 'frames-ano';

        this.materialLibraryLoaded = false;

        this.requestManager = self.app.modules.requestManager.controller;

        this.initVueData();

        this.holeLightMap = null;

        self.app.events.on('@obsidian-engine.engine-ready', (scene) => {
            /** @type Scene  */
            this.scene = scene;
            this.initTextures();
            this.initEnvironment();
        });
        self.app.events.on('@project-manager.update-config-images', () => {
            this.initTextures(true);
        });

        ConsoleHelper.expose('materialManager', this);
    }

    initVueData() {
        VueHelper.AddVueProperty(this, 'areFramesTransparent', false);
    }

    /**
     * Environment for all PBR Materials
     */
    initEnvironment() {
        this.scene.environmentTexture = this.loadTexture('envmaps/autoshop.env');
        this.scene.environmentTexture.level = 100;
    }

    /**
     * Initialize all the texture needed for the project given in the config
     */
    initTextures(reimport = false) {
        const promiseArray = [];

        let infillsImg = filterCollectionByUniqueFirstKey(JSON.parse(config.infillsImg));

        // TODO : Remove patch //
        // This is patch to fix doctrine bug at https://github.com/symfony/symfony/issues/37041
        // The problem is that empty collections become empty objects and not arrays
        if (!Array.isArray(infillsImg)) {
            infillsImg = [];
        }

        // End of patch
        infillsImg.forEach((imgObject) => {
            promiseArray.push(
                this.loadAwsTexture(imgObject)
                    .then((texture) => {
                        if (reimport) {
                            self.app.events.emit('reimport-texture', {
                                id: Object.keys(imgObject)[0],
                                texture,
                            });
                        }
                        return texture;
                    })
                    .catch((err) => {
                        self.app.log.error("Can't load texture :", err);
                        throw err;
                    }),
            );
        });
        return Promise.all(promiseArray)
            .finally(() => {
                this.imagesLoaded = true;
                // TODO : no one is listening to this event
                self.app.events.emit('images-loaded');
            })
            .catch((err) => {
                self.app.log.error("Can't initialize project textures :", err);
                throw err;
            });
    }

    /**
     * Setup the material library
     * It creates materials from the passed JSON
     * Then creates procedural materials
     * @param {Material[]} materialsParams
     */
    initializeMaterialLibrary(materialsParams) {
        // material.json materials
        Object.keys(materialsParams).forEach((name) => {
            this.loadMaterial(name, materialsParams[name]);
        });
        this.materialLibraryLoaded = true;
    }

    /**
     * Creates a material named after the name parameter
     * Parameters in the param Object will be assigned to the material :
     * - type : type of material, e.g. StandardMaterial, PBRMaterial, PBRMetallicRoughnessMaterial
     * - Primitives (string, number, boolean, object) will be applied directly
     * - Textures must be objects, with an url parameter and classic Babylon Texture parameters
     *   example : { url : "leather.jpg", uScale : 3, vSCale :3 }
     * - Colors can be an hex string (ex : "#FFFFFF")
     *   Or an rgb object, like { r:1, g:1, b:1} for white
     * /!\ If a material with the same name as already been loaded, returns it instead
     *   @param {String} name
     *   @param {Object} params
     *   @returns {StandardMaterial}
     */
    loadMaterial(name, params = {}) {
        if (!this.loadedMaterials[name]) {
            // type represent material type,
            // it can be PBRMaterial, StandardMaterial, PBRMetallicRoughnessMaterial etc.
            // by default it's StandardMaterial
            let type = 'StandardMaterial';
            if (params.type) {
                if (typeof BABYLON[params.type] === 'function') {
                    ({ type } = params); // go to hell eslint
                }
                delete params.type;
            }
            const mat = new BABYLON[type](name, this.scene);
            Object.keys(params).forEach((pK) => {
                this.handleBabylonParam(mat, pK, params[pK]);
            });
            this.loadedMaterials[name] = mat;
            mat.freeze();
            self.app.events.emit('load-material', name);
        }
        return this.loadedMaterials[name];
    }

    async handleBabylonParam(material, key, value) {
        const lowerKey = key.toLowerCase();
        if (lowerKey.includes('texture')) {
            const texParams = value;
            const texUrl = texParams.url;

            // Handle infills textures stuff
            if (texParams.remote && texParams.id) {
                const imageObject = {};
                imageObject[texParams.id] = texUrl;
                try {
                    material[key] = await this.loadAwsTexture(imageObject, true, texParams.letYReverted);
                } catch (err) {
                    self.app.log.error("Can't load remote texture :", err);
                    material[key] = new Texture('/assets/textures/errorTexture.png', this.scene);
                    throw err;
                }
            } else {
                delete texParams.url;
                material[key] = this.loadTexture(texUrl, texParams);
            }
        } else if (lowerKey.includes('color')) {
            const colorParams = value;
            let color;
            if (typeof colorParams === 'string') {
                color = Color3.FromHexString(colorParams);
            } else {
                color = new Color3(colorParams.r, colorParams.g, colorParams.b);
            }
            material[key] = color;
        } else if (typeof value === 'object' && material[key] && key !== 'metadata') {
            const obj = material[key];
            Object.keys(value).forEach((subKey) => {
                this.handleBabylonParam(obj, subKey, value[subKey]);
            });
        } else {
            material[key] = value;
        }
    }

    /**
     * Loads a texture from a file located at the url parameter
     * Assign the parameters of the param object to it
     * If the file has already been loaded, clones the texture instead of loading a new one
     * @param {String} url
     * @param {Object} params
     * */
    loadTexture(url, params = {}) {
        if (!url) {
            throw new Error(`Can't load texture with url : ${url}`);
        }
        if (!this.loadedLocalTextures[url]) {
            // dds & hdr handling for env map
            const urlSplit = url.split('.');
            const extension = urlSplit[urlSplit.length - 1].toLowerCase();
            const urlToLoad = this.path + url;
            if (extension === 'hdr') {
                this.loadedLocalTextures[url] = new HDRCubeTexture(urlToLoad, this.scene, params.size ? params.size : 256);
            } else if (extension === 'env') {
                this.loadedLocalTextures[url] = new CubeTexture(urlToLoad, this.scene);
            } else if (extension === 'dds') {
                this.loadedLocalTextures[url] = CubeTexture.CreateFromPrefilteredData(urlToLoad, this.scene);
            } else {
                // Other textures type
                this.loadedLocalTextures[url] = new Texture(urlToLoad, this.scene);
            }
            Object.keys(params).forEach((param) => {
                this.loadedLocalTextures[url][param] = params[param];
            });
            this.loadedLocalTextures[url].name = url;
        }

        /* const tex = this.loadedLocalTextures[url].clone();
        Object.keys(params).forEach(param => {
            tex[param] = params[param];
        }); */
        return this.loadedLocalTextures[url];
        // TODO: Check params clone
    }

    /**
     * Load a texture stored in AWS S3 by giving an imageObject
     * @param {Object} imageObject - the imageObject that contains the infos of the image
     * @param {String} imageObject.keys[0] - the id of the image
     * @param {String} imageObject[id] - the url of the given id
     * @param {Boolean} isBlobUrl - true if the url is for a local cached blob,
     *                              false if its a remote url
     */
    loadAwsTexture(imageObject, isBlobUrl = false) {
        const id = Object.keys(imageObject)[0];
        const url = imageObject[id];
        if (!id) {
            return Promise.reject(new Error('No id specified for the texture'));
        }
        if (!this.loadedLocalTextures[id]) {
            if (!url) {
                return Promise.reject(new Error(`Can't load texture with wrong url ${url}`));
            }
            if (isBlobUrl) {
                const texture = new Texture(url, this.scene);
                this.loadedLocalTextures[id] = texture;
                return Promise.resolve(texture);
            }

            // Means the texture must be loaded with the S3 URL
            const texture = this.requestManager
                .doRequest(url)
                .then((res) => res.blob())
                .then(URL.createObjectURL)
                .then((imageUrl) => {
                    const text = new Texture(imageUrl, this.scene);

                    this.loadedLocalTextures[id] = text;
                    return text;
                })
                .catch((error) => {
                    self.app.log.error('Error while loading aws texture ', error);
                    throw error;
                });
            return texture;
        }
        return Promise.resolve(this.loadedLocalTextures[id]);
    }

    /**
     * Create a material from a glb material or use one from the loadedMaterials
     * Use defaultMaterial
     * @param {*} originalMaterial
     * @param {*} newMaterialName
     * @returns
     */
    createBecadMaterialFromGlbMaterial(originalMaterial, newMaterialName = null) {
        let materialName = newMaterialName || originalMaterial.name;

        // Extract the name between double underscores to consolidate duplicated materials
        const regex = /__(.*)__/;
        const match = materialName.match(regex);
        if (match) {
            materialName = match[1];
        }

        materialName = `ECO-${materialName}`;

        if (!this.loadedMaterials[materialName]) {
            const newMaterial = this.loadedMaterials[this.defaultMaterial].clone();
            newMaterial.name = materialName;

            let { albedoTexture } = originalMaterial;
            if (albedoTexture) {
                if (!this.loadedTextureGLB[materialName]) {
                    albedoTexture.updateSamplingMode(Texture.LINEAR_LINEAR_MIPLINEAR);
                    this.loadedTextureGLB[materialName] = albedoTexture;
                    originalMaterial.albedoTexture = null;
                } else {
                    albedoTexture = this.loadedTextureGLB[materialName];
                    originalMaterial.albedoTexture.dispose(true, true);
                }

                const hasLightmap = materialName.toLowerCase().includes('lmtex');
                if (hasLightmap && !newMaterial.lightmapTexture) {
                    newMaterial.lightmapTexture = albedoTexture;
                    newMaterial.useLightmapAsShadowmap = true;
                } else {
                    newMaterial.albedoTexture = albedoTexture;
                }
            }

            const { bumpTexture } = originalMaterial;
            if (bumpTexture) {
                const textureName = `${materialName}_normal`;
                if (!this.loadedTextureGLB[textureName]) {
                    bumpTexture.updateSamplingMode(Texture.LINEAR_LINEAR_MIPLINEAR);
                    this.loadedTextureGLB[textureName] = bumpTexture;
                    originalMaterial.normalTexture = null;
                } else {
                    originalMaterial.normalTexture.dispose(true, true);
                }
                newMaterial.bumpTexture = this.loadedTextureGLB[textureName];
            }

            // custom material
            if (materialName.toLowerCase().includes('custom')) {
                newMaterial.albedoColor = originalMaterial.albedoColor.clone();
                newMaterial.metallic = originalMaterial.metallic;
                newMaterial.roughness = originalMaterial.roughness;
            }

            this.loadedMaterials[materialName] = newMaterial;
        }

        return this.loadedMaterials[materialName];
    }

    createRalVersionFromMaterial(ralMaterial, originalMaterial) {
        const color = ralMaterial.albedoColor.toHexString();
        const materialName = `RAL-${originalMaterial.name.replace('ECO-', '')}-${color}`;
        if (!this.loadedMaterials[materialName]) {
            const newRalMaterial = ralMaterial.clone();
            if (originalMaterial.lightmapTexture) {
                newRalMaterial.lightmapTexture = originalMaterial.lightmapTexture;
                newRalMaterial.useLightmapAsShadowmap = true;
            }
            newRalMaterial.name = materialName;
            this.loadedMaterials[materialName] = newRalMaterial;
        }

        return this.loadedMaterials[materialName];
    }

    createInfillMaterial(entity, options = { id: null, url: null, color: null }) {
        const isImage = !options.color;
        const materialName = `infill-material-${isImage ? options.id : options.color}`;
        const isR430 = entity.ref.includes('R430');
        if (isImage && !options.url && this.loadedMaterials[materialName]) {
            const reloadedUrl = this.loadedMaterials[materialName].albedoTexture.url;
            self.app.events.emit('check-reloaded-material', options.id, reloadedUrl);
        }
        try {
            const letYReverted =
                (entity.subCategory.toUpperCase() === 'STRAIGHT FRAMES' && !isR430) ||
                config.fileType !== '.glb' ||
                entity.subCategory.toUpperCase().includes('LIGHTBOX') ||
                entity.subCategory.toUpperCase().includes('MOTIONSKIN');
            if (isImage) {
                this.loadMaterial(materialName, {
                    type: 'PBRMaterial',
                    metallic: 0,
                    roughness: 0.3,
                    environmentIntensity: 0.3,
                    albedoTexture: {
                        id: options.id,
                        url: options.url,
                        remote: true,
                        letYReverted,
                    },
                });
            } else {
                this.loadMaterial(materialName, {
                    type: 'StandardMaterial',
                    metallic: 0,
                    roughness: 0.3,
                    environmentIntensity: 0.3,
                    diffuseColor: options.color,
                });
            }
        } catch (err) {
            self.app.log.error("Can't load infill material ", err);
            throw new Error(err);
        }
        return materialName;
    }

    /**
     * Register a new ral material to the library
     * Name and color are used to create a unique identifier for the material
     * @param {String} name material name prefix
     * @param {String} color hex formated color
     * @returns {String} created material identifier
     */
    createRalMaterial(name, color) {
        const materialName = `RAL-${name}-${color}`;
        this.loadMaterial(materialName, {
            type: 'PBRMaterial',
            metallic: 1,
            roughness: 0.85,
            environmentIntensity: 0.4,
            albedoColor: color,
        });
        return materialName;
    }

    setFramesTransparency(transparencyModeEnabled) {
        this.setFramesMaterialsTransparency(transparencyModeEnabled);
        this.areFramesTransparent = transparencyModeEnabled;
    }

    /**
     * Return the ledskin material (create it if not existing)
     */
    getLedskinMaterial() {
        return this.loadMaterial('ledskin-mat', {
            metallic: 0,
            roughness: 0.463,
            diffuseTexture: {
                url: 'ledDiffuse.webp',
            },
            bumpTexture: {
                url: 'ledNormal.webp',
            },
        });
    }

    /**
     * Set the frames materials transparency to 0.25
     * As of the frames are instances and / or use the same materials
     * Changing the materials here will apply the modifications on all frames
     * @param {Boolean} transparencyModeEnabled
     */
    setFramesMaterialsTransparency(transparencyModeEnabled) {
        const frameMaterials = Object.keys(this.loadedMaterials)
            .filter((name) => name.startsWith('RAL-') || name.startsWith('ECO-'))
            .map((name) => this.loadedMaterials[name]);

        frameMaterials.push(this.loadedMaterials['frames-ano']);
        if (this.loadedMaterials['ledskin-mat']) {
            frameMaterials.push(this.loadedMaterials['ledskin-mat']);
        }

        frameMaterials.forEach((material) => {
            if (transparencyModeEnabled) {
                material.unfreeze();
            }
            MaterialManager.setMaterialTransparency(material, transparencyModeEnabled);
        });
        this.scene.render();
        if (!transparencyModeEnabled) {
            frameMaterials.forEach((material) => {
                material.freeze();
            });
        }
    }

    static setMaterialTransparency(material, transparencyModeEnabled) {
        if (transparencyModeEnabled) {
            material.alpha = 0.25;

            if (material.lightmapTexture) {
                material.metadata = { ...material.metadata, lightmapTexture: material.lightmapTexture };
                material.lightmapTexture = null;
                material.useLightmapAsShadowmap = false;
            }
            if (material.albedoTexture) {
                material.metadata = { ...material.metadata, albedoTexture: material.albedoTexture };
                material.albedoTexture = null;
            }

            if (material.bumpTexture) {
                material.metadata = { ...material.metadata, bumpTexture: material.bumpTexture };
            }
        } else {
            material.alpha = 1;

            if (material.metadata?.lightmapTexture) {
                material.lightmapTexture = material.metadata.lightmapTexture;
                material.useLightmapAsShadowmap = true;
                material.metadata.lightmapTexture = null;
            }

            if (material.metadata?.albedoTexture) {
                material.albedoTexture = material.metadata.albedoTexture;
                material.metadata.albedoTexture = null;
            }

            if (material.metadata?.bumpTexture) {
                material.bumpTexture = material.metadata.bumpTexture;
                material.metadata.bumpTexture = null;
            }
        }
    }
}
