import self from '..';
import SnappingHelper from '../helpers/snapping-helper';
import eventBus from '../../../commons/EventBus';
import { Quaternion, Vector3, VertexBuffer } from '@babylonjs/core';

const {
    events,
    modules: {
        obsidianEngine: { controller: engineController },
        materialManager,
        history,
    },
} = self.app;

export default class SnappingController {
    constructor() {
        this.snappingModeActivated = false;
        this.normalsModeActivated = false;
        this.selectableGizmos = [];
        this.selectedGizmo = null;
        this.hoveredGizmo = null;
        this.hoveredEntity = null;
        this.hoveredMesh = null;

        events.on('@obsidian-engine.engine-ready', (scene) => {
            this.scene = scene;
            this.canvas = document.getElementById('main-canvas');
            this.onPointerDownEventHandler = this.onPointerDownCallback.bind(this);
            this.onPointerMoveEventHandler = this.onPointerMoveCallback.bind(this);
        });
    }

    onPointerDownCallback(event) {
        if (event.button !== 0) {
            // We handle only left click
            return;
        }
        if (this.hoveredGizmo) {
            if (this.hoveredGizmo === this.selectedGizmo) {
                if (this.normalsModeActivated) {
                    this.selectedGizmo.getChildMeshes(true)[0].material = SnappingController.snappingDotMaterial;
                    this.selectedGizmo.getChildMeshes(true)[1].material = SnappingController.snappingDotMaterial;
                } else if (!this.selectableGizmos.includes(this.selectedGizmo)) {
                    // Remove previously selected gizmo if not in selectable gizmos
                    this.selectedGizmo.dispose();
                }
                // Unselect the hovered gizmo
                this.selectedGizmo = null;
            } else if (this.selectedGizmo) {
                // If there was a selected gizmo
                if (this.normalsModeActivated) {
                    this.orientationOperation();
                } else {
                    // Execute the position operation
                    this.positionOperation();
                }
            } else {
                // Select the hovered gizmo
                this.selectedGizmo = this.hoveredGizmo;
                if (this.normalsModeActivated) {
                    this.selectedGizmo.getChildMeshes(true)[0].material = SnappingController.snappingDotSelectedMaterial;
                    this.selectedGizmo.getChildMeshes(true)[1].material = SnappingController.snappingDotSelectedMaterial;
                }
            }
        }
    }

    /**
     * Execute the orientation operation
     */
    orientationOperation() {
        let meshToMove = this.selectedGizmo.parent;
        let isSelected = false;
        if (meshToMove.entity.isSelected) {
            meshToMove = SnappingHelper.createTransformGroup(meshToMove);
            isSelected = true;
        } else {
            meshToMove.entity.unfreeze();
        }

        // Update world matrices to get accurate world positions and directions
        this.selectedGizmo.computeWorldMatrix(true);
        this.hoveredGizmo.computeWorldMatrix(true);

        // Calculate the normals in world space
        const up1 = this.selectedGizmo.getDirection(Vector3.Up());
        const up2 = this.hoveredGizmo.getDirection(Vector3.Up()).negate();

        // Calculate the rotation quaternion to align up1 with -up2
        const rotation = new Quaternion();
        Quaternion.FromUnitVectorsToRef(up1, up2, rotation);

        // If the mesh already has a rotation, we need to combine the quaternions
        if (meshToMove.rotationQuaternion) {
            meshToMove.rotationQuaternion = rotation.multiply(meshToMove.rotationQuaternion);
        } else {
            meshToMove.rotationQuaternion = rotation;
        }

        // Update the world matrix to apply the new rotation
        this.selectedGizmo.computeWorldMatrix(true);

        // Calculate the right directions in world space after the up rotation
        const right1 = this.selectedGizmo.getDirection(Vector3.Right());
        const right2 = this.hoveredGizmo.getDirection(Vector3.Right()).negate();

        // Compute the angle between the two vectors
        const epsilon = 0.0000001; // Use of epsilon to prevent precision issues
        let angle = Vector3.GetAngleBetweenVectors(right1, right2, up2);
        const added = Math.sign(angle) * epsilon;
        angle += added;
        if (Math.abs(angle) >= Math.PI) {
            angle = Math.sign(angle) * (Math.abs(angle) % Math.PI);
        }
        angle -= added;

        // Apply the angle rotation
        const angleRotation = Quaternion.RotationAxis(up2, angle);
        meshToMove.rotationQuaternion = angleRotation.multiply(meshToMove.rotationQuaternion);

        // Compute the position to align the centers
        this.selectedGizmo.computeWorldMatrix(true);
        const displacement = this.hoveredGizmo.getAbsolutePosition().subtract(this.selectedGizmo.getAbsolutePosition());
        meshToMove.position.addInPlace(displacement);

        // Update the world matrix to apply the new rotation and position
        meshToMove.computeWorldMatrix(true);

        if (isSelected) {
            SnappingHelper.checkNodeGridRepulsion(meshToMove);
            SnappingHelper.destroyTransformGroup(meshToMove);
        } else {
            SnappingHelper.checkMeshGridRepulsion(meshToMove);
            meshToMove.entity.updateDynamicParameters();
            meshToMove.entity.freeze();
        }

        this.dispose();
        history.snapshot();
    }

    /**
     * Execute the position operation
     */
    positionOperation() {
        const displacement = this.hoveredGizmo.getAbsolutePosition().subtract(this.selectedGizmo.getAbsolutePosition());
        let meshToMove = this.selectedGizmo.parent;

        let isSelected = false;
        if (meshToMove.entity.isSelected) {
            meshToMove = SnappingHelper.createTransformGroup(meshToMove);
            isSelected = true;
        } else {
            meshToMove.entity.unfreeze();
        }

        meshToMove.position.addInPlace(displacement);

        // If current mesh is selected, move all the meshes
        if (isSelected) {
            SnappingHelper.checkNodeGridRepulsion(meshToMove);
            SnappingHelper.destroyTransformGroup(meshToMove);
        } else {
            SnappingHelper.checkMeshGridRepulsion(meshToMove);
            meshToMove.entity.updatePosition();
            meshToMove.entity.freeze();
        }
        this.dispose();
        history.snapshot();
    }

    /**
     * Initialise mouse move interactions by setting rules
     */
    onPointerMoveCallback() {
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        let noneFound = true;
        if (pickResult.hit && pickResult.pickedMesh) {
            if (this.normalsModeActivated) {
                if (pickResult.pickedMesh.name?.includes('MAGNETISM') || pickResult.pickedMesh.name?.includes('MAGNETISM')) {
                    this.hoveredGizmo = this.getNormalGizmo(pickResult);
                    noneFound = false;
                }
            } else if (pickResult.pickedMesh.name === 'pointGizmo') {
                if (this.hoveredGizmo && this.hoveredGizmo !== this.selectedGizmo) {
                    this.hoveredGizmo.material = SnappingController.snappingDotMaterial;
                }
                this.hoveredGizmo = pickResult.pickedMesh;
                this.hoveredGizmo.material = materialManager.loadedMaterials['hover-green-material'];
                noneFound = false;
            } else if (this.selectedGizmo && pickResult.pickedMesh.id === 'main-ground' && this.hoveredMesh !== pickResult.pickedMesh) {
                // Show ground snap points
                this.hoveredMesh = pickResult.pickedMesh;
                this.selectableGizmos.push(...this.getSnappingGizmos(pickResult.pickedMesh));
            } else {
                const entity = pickResult.pickedMesh.entity ?? pickResult.pickedMesh.parent?.entity;

                if (entity && entity !== this.hoveredEntity) {
                    this.selectableGizmos.forEach((gizmo) => {
                        if (gizmo !== this.selectedGizmo) {
                            gizmo.dispose();
                        }
                    });
                    this.selectableGizmos = this.getSnappingGizmos(entity.mesh);
                    this.hoveredEntity = entity;
                    this.hoveredMesh = pickResult.pickedMesh;
                    noneFound = false;
                }
            }
        }
        if (noneFound) {
            if (this.hoveredGizmo && this.hoveredGizmo !== this.selectedGizmo) {
                if (this.normalsModeActivated) {
                    this.hoveredGizmo.dispose();
                } else {
                    this.hoveredGizmo.material = SnappingController.snappingDotMaterial;
                }
            }
            this.hoveredGizmo = null;
            this.hoveredEntity = null;
        }
    }

    setSnappingActivated(isActivated) {
        if (this.snappingModeActivated === isActivated) {
            return;
        }
        this.snappingModeActivated = isActivated;
        if (this.snappingModeActivated) {
            eventBus.emit('activating-tool', 'snapping');
            engineController.eventHandler.mouse.disableMouseEvents(); // Disable scene interactions (selection, drag...)
            this.canvas.addEventListener('pointermove', this.onPointerMoveEventHandler);
            this.canvas.addEventListener('pointerdown', this.onPointerDownEventHandler);
        } else {
            engineController.eventHandler.mouse.enableMouseEvents(); // Enable scene interactions back
            this.canvas.removeEventListener('pointermove', this.onPointerMoveEventHandler);
            this.canvas.removeEventListener('pointerdown', this.onPointerDownEventHandler);
            this.dispose();
        }
    }

    setNormalsMode(isNormalsMode) {
        this.normalsModeActivated = isNormalsMode;
        this.dispose();
    }

    dispose() {
        this.selectableGizmos.forEach((gizmo) => {
            gizmo.dispose();
        });
        this.selectableGizmos.length = 0;
        this.selectedGizmo?.dispose();
        this.hoveredGizmo?.dispose();
        this.selectedGizmo = null;
        this.hoveredGizmo = null;
    }

    /**
     * Create gizmos from given mesh
     * @param {*} mesh
     */
    getSnappingGizmos(mesh) {
        const initGizmo = (posX, posY, posZ) => {
            if (this.selectedGizmo && this.selectedGizmo.parent === mesh && this.selectedGizmo.position.equalsToFloats(posX, posY, posZ)) {
                return this.selectedGizmo;
            }
            const newGizmo = SnappingHelper.createPointGizmo(this.scene, SnappingController.snappingDotMaterial, 0.06);
            newGizmo.parent = mesh;
            newGizmo.position = new Vector3(posX, posY, posZ);
            newGizmo.computeWorldMatrix(true);
            return newGizmo;
        };

        const gizmos = [];

        let magMeshes;
        if (mesh) {
            magMeshes = mesh.getChildMeshes(true, (magMesh) => magMesh.name.includes('MAGNETISM'));
            if (mesh.name.includes('MAGNETISM')) {
                magMeshes.push(mesh);
            }
        } else {
            magMeshes = [mesh]; // Add ground snap points
        }
        const almostEqual = (a, b) => Math.abs(a - b) < 0.000001;
        const positions = [];
        magMeshes.forEach((magMesh) => {
            const pos = magMesh.getVerticesData(VertexBuffer.PositionKind);
            for (let i = 0; i < pos.length; i += 3) {
                if (
                    !positions.some(
                        (position) =>
                            almostEqual(position[0], pos[i]) &&
                            almostEqual(position[1], pos[i + 1]) &&
                            almostEqual(position[2], pos[i + 2]),
                    )
                ) {
                    positions.push([pos[i], pos[i + 1], pos[i + 2]]);
                    gizmos.push(initGizmo(pos[i], pos[i + 1], pos[i + 2]));
                }
            }
        });

        return gizmos;
    }

    /**
     * Create normal gizmo from hovered face
     * @param {*} pickResult
     */
    getNormalGizmo(pickResult) {
        const { faceId } = pickResult;
        const { pickedMesh } = pickResult;
        const indices = pickedMesh.getIndices();
        const positions = pickedMesh.getVerticesData(VertexBuffer.PositionKind);
        const i0 = indices[faceId * 3];
        const i1 = indices[faceId * 3 + 1];
        const i2 = indices[faceId * 3 + 2];
        const p0 = new Vector3(positions[i0 * 3], positions[i0 * 3 + 1], positions[i0 * 3 + 2]);
        const p1 = new Vector3(positions[i1 * 3], positions[i1 * 3 + 1], positions[i1 * 3 + 2]);
        const p2 = new Vector3(positions[i2 * 3], positions[i2 * 3 + 1], positions[i2 * 3 + 2]);
        const lp01 = Vector3.DistanceSquared(p0, p1);
        const lp02 = Vector3.DistanceSquared(p0, p2);
        const lp12 = Vector3.DistanceSquared(p1, p2);
        const maxDist = Math.max(lp01, lp02, lp12);
        const minDist = Math.min(lp01, lp02, lp12);
        let center;
        let direction;
        if (lp01 === maxDist) {
            center = p0.add(p1).scale(0.5);
            if (lp02 === minDist) {
                direction = p0.subtract(p2).normalize();
            } else {
                direction = p2.subtract(p1).normalize();
            }
        } else if (lp02 === maxDist) {
            center = p0.add(p2).scale(0.5);
            if (lp12 === minDist) {
                direction = p2.subtract(p1).normalize();
            } else {
                direction = p1.subtract(p0).normalize();
            }
        } else {
            center = p1.add(p2).scale(0.5);
            if (lp02 === minDist) {
                direction = p2.subtract(p0).normalize();
            } else {
                direction = p0.subtract(p1).normalize();
            }
        }

        if (this.selectedGizmo !== this.hoveredGizmo) {
            if (this.hoveredGizmo && this.hoveredGizmo.parent === pickedMesh.parent && this.hoveredGizmo.position.equals(center)) {
                return this.hoveredGizmo;
            }
            this.hoveredGizmo?.dispose();
        }
        if (this.selectedGizmo && this.selectedGizmo.parent === pickedMesh.parent && this.selectedGizmo.position.equals(center)) {
            return this.selectedGizmo;
        }

        const newGizmo = SnappingHelper.createArrowGizmo(this.scene, SnappingController.snappingArrowMaterial, 0.4, 0.12, 0.04);

        // Position
        newGizmo.position = center;

        // Orientation
        const axis1 = pickResult.getNormal();
        const axis2 = Vector3.Up();
        const axis3 = Vector3.Up();
        Vector3.CrossToRef(direction, axis1, axis2);
        Vector3.CrossToRef(axis2, axis1, axis3);
        const tmpVec = Vector3.RotationFromAxis(axis3.negate(), axis1, axis2);
        const quat = Quaternion.RotationYawPitchRoll(tmpVec.y, tmpVec.x, tmpVec.z);
        newGizmo.rotationQuaternion = quat;

        newGizmo.parent = pickedMesh.parent.entity.mesh;
        newGizmo.computeWorldMatrix(true);
        return newGizmo;
    }

    static get snappingArrowMaterial() {
        return materialManager.loadedMaterials['snapping-normal-material'];
    }

    static get snappingDotMaterial() {
        return materialManager.loadedMaterials['snapping-dot-material'];
    }

    static get snappingDotHoverMaterial() {
        return materialManager.loadedMaterials['hover-green-material'];
    }

    static get snappingDotSelectedMaterial() {
        return materialManager.loadedMaterials['snapping-dot-selected-material'];
    }
}
