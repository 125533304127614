import self from '../../index';
import html2canvas from 'html2canvas';
import { Logger, RenderTargetTexture, Texture, Constants, FxaaPostProcess, Tools } from '@babylonjs/core';

const {
    app: { config },
} = self;

export default {
    /**
     * Returns an screenshot of a DOM element
     * @param {HTMLElement} htmlElement
     * @param {Number} scale how much the resulting image will be scaled,
     * 1 means the image will have the screen size
     * @param {Boolean} transparentBackground when there is nothing on a pixel,
     * should it be transparent
     * @returns {Promise(image)} the promise returns a base64 encoded image
     */
    getScreenshotFromHtml(htmlElement, scale = 1, transparentBackground = false) {
        return Promise.resolve(
            html2canvas(htmlElement, {
                logging: config.get('@obsidian.debug'),
                scale,
                backgroundColor: transparentBackground ? null : '#ffffff',
            }),
        ).then((htmlCanvas) => htmlCanvas.toDataURL('image/png', 1));
    },

    /**
     * MONKEY PATCH
     * This function enables to catch sprites inside screenshots
     * TODO : Remove once BABYLON Release version 4.1.0-beta.25
     */
    patchScreenshotRenderTargetTexture(screenshotToolsInstance) {
        screenshotToolsInstance.CreateScreenshotUsingRenderTarget = (
            engine,
            camera,
            size,
            successCallback = () => {},
            mimeType = 'image/png',
            samples = 1,
            antialiasing = false,
            fileName = '',
            renderSprites = false,
        ) => {
            const { height, width } = size;

            const targetTextureSize = { width, height };

            if (!(height && width)) {
                Logger.Error("Invalid 'size' parameter !");
                return;
            }

            const scene = camera.getScene();
            let previousCamera = null;

            if (scene.activeCamera !== camera) {
                previousCamera = scene.activeCamera;
                scene.activeCamera = camera;
            }

            const renderCanvas = engine.getRenderingCanvas();
            if (!renderCanvas) {
                Logger.Error('No rendering canvas found !');
                return;
            }

            const originalSize = { width: renderCanvas.width, height: renderCanvas.height };
            engine.setSize(width, height);
            scene.render();

            // At this point size can be a number, or an object
            // (according to engine.prototype.createRenderTargetTexture method)
            const texture = new RenderTargetTexture(
                'screenShot',
                targetTextureSize,
                scene,
                false,
                false,
                Constants.TEXTURETYPE_UNSIGNED_INT,
                false,
                Texture.NEAREST_SAMPLINGMODE,
            );
            texture.renderList = null;
            texture.samples = samples;
            texture.renderSprites = renderSprites;
            texture.onAfterRenderObservable.add(() => {
                Tools.DumpFramebuffer(width, height, engine, successCallback, mimeType, fileName);
            });

            const renderToTexture = () => {
                scene.incrementRenderId();
                scene.resetCachedMaterial();
                texture.render(true);
                texture.dispose();

                if (previousCamera) {
                    scene.activeCamera = previousCamera;
                }
                engine.setSize(originalSize.width, originalSize.height);
                camera.getProjectionMatrix(true); // Force cache refresh;
            };

            if (antialiasing) {
                const fxaaPostProcess = new FxaaPostProcess('antialiasing', 1.0, scene.activeCamera);
                texture.addPostProcess(fxaaPostProcess);
                // Async Shader Compilation can lead to none ready effects in synchronous code
                if (!fxaaPostProcess.getEffect().isReady()) {
                    fxaaPostProcess.getEffect().onCompiled = () => {
                        renderToTexture();
                    };
                } else {
                    // The effect is ready we can render
                    renderToTexture();
                }
            } else {
                // No need to wait for extra resources to be ready
                renderToTexture();
            }
        };

        screenshotToolsInstance.CreateScreenshotUsingRenderTargetAsync = (
            engine,
            camera,
            size,
            mimeType = 'image/png',
            samples = 1,
            antialiasing = false,
            fileName = '',
            renderSprites = false,
        ) =>
            new Promise((resolve, reject) => {
                screenshotToolsInstance.CreateScreenshotUsingRenderTarget(
                    engine,
                    camera,
                    size,
                    (data) => {
                        if (typeof data !== 'undefined') {
                            resolve(data);
                        } else {
                            reject(new Error('Data is undefined'));
                        }
                    },
                    mimeType,
                    samples,
                    antialiasing,
                    fileName,
                    renderSprites,
                );
            });
    },
};
