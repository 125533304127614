import self from '../..';

const { stonejs } = self.app.modules;

const SelectionHelper = {
    GroupActionEnum: {
        GROUP: {
            id: 0,
            label: stonejs.gettext('Group'),
            icon: 'group',
        },
        UNGROUP: {
            id: 1,
            label: stonejs.gettext('Ungroup'),
            icon: 'ungroup',
        },
        ADD: {
            id: 2,
            label: stonejs.gettext('Add to group'),
            icon: 'forward',
        },
        LEAVE: {
            id: 3,
            label: stonejs.gettext('Leave group'),
            icon: 'backward',
        },
        FUSION: {
            id: 4,
            label: stonejs.gettext('Fusion'),
            icon: 'fusion',
        },
        NONE: {
            id: 5,
            label: stonejs.gettext('No action available'),
            icon: '',
        },
    },
};

export default SelectionHelper;
