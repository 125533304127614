import EntitiesData from '../../../../entities-data';
import self from '../../index';

const {
    app: {
        events,
        modules: { meshManager, dataStore },
    },
} = self;

/**
 * Snap configuration
 */
export const SnapConfig = {
    maxDistToSnap: 0.06,
    maxAngleToSnap: Math.PI / 4 + 0.1,
};

export default class SnapController {
    /**
     * @class {bematrix.SnapController}
     */
    constructor() {
        this.dataStore = dataStore;
        this.MeshManager = meshManager.meshController;
        this.snappableStructures = [];

        events.on('drag-start', (snappable) => {
            this.updateSnappableStructures(snappable);
        });

        events.on('@history.history-go', () => {
            this.updateSnappableStructures();
        });

        events.on('@selection-manager.entity-pasted', () => {
            this.updateSnappableStructures();
        });

        events.on('drag', (snappable) => {
            this.tryToSnap(snappable);
        });

        events.on('@entity-manager.mesh-fetched', (entity) => {
            this.postFetchMesh(entity);
        });
    }

    tryToSnap(snappable) {
        const isCover = (entity) => EntitiesData.entityTypeFromSubCategory(entity.subCategory) === EntitiesData.ENTITY_TYPE.COVER;
        const currentEntityIsCover = isCover(snappable);

        this.snappableStructures.some((s) => {
            if (currentEntityIsCover && isCover(s)) {
                return false;
            }
            return snappable.updateSnap(s);
        });
    }

    updateSnappableStructures(selection) {
        const allEnt = this.dataStore.listEntities();
        this.snappableStructures = allEnt.filter((ent) => ent.isSnappable && ent !== selection);
        this.snappableStructures.forEach((s) => {
            s.snappyRects.forEach((sn) => {
                sn.computeWorldMatrix();
            });
        });
    }

    postFetchMesh(entity) {
        // we generate the snappy rectangle from the snapInfos obtained from the mesh
        // or from the bounding box for straight frames
        if (!entity.isSnappable) {
            return;
        }
        const { snapInfos } = this.MeshManager.loadedMeshes[entity.ref];
        if (snapInfos) {
            entity.generateSnappyRectsFromSnapInfos(snapInfos);
        } else {
            entity.generateSnappyRectsFromBoundingBox();
        }
        events.emit('snappy-rect-generated', entity);
    }
}
