/**
 * Patch to version 1.36.0
 *
 * The purpose of this patch is to fix old doors structures and set correct category and subcategory
 * The old saved entities don't have the right category and subCategory
 */

export default {
    /**
     * Main patch function
     * Set the old door frames structures entity to new door frames structures
     *
     * Broken data structure example
     *  {
     *      __name__: "frame",
     *      category : "FRAMES",
     *      subCategory : "DOOR FRAMES",
     *      ref : "620 #### #### (30 deg)"
     *  },
     * {
     *      __name__: "frame",
     *      category : "FRAMES",
     *      subCategory : "DOOR FRAMES",
     *      ref : "618 #### #### MDF"
     *  },
     *
     * Post fix data structure example
     * {
     *      __name__: "door",
     *      category : "DOOR FRAMES",
     *      subCategory : "SINGLE DOORS",
     *      ref : "620 #### #### (30 deg)"
     *  },
     * {
     *      __name__: "door",
     *      category : "DOOR FRAMES",
     *      subCategory : "STORAGE DOOR",
     *      ref : "618 #### #### MDF"
     *  },
     * @param {ObsidianFile} project
     * @returns {ObsidianFile}
     */
    patch(project) {
        const projectSerializedData = project.project;

        Object.values(projectSerializedData).forEach((path) => {
            path.forEach((item) => {
                if (item.__name__ === 'frame' && item.subCategory.toUpperCase().includes('DOOR FRAMES')) {
                    if (item.ref.startsWith('620')) {
                        this.toSingleDoor(item);
                    } else if (item.ref.startsWith('618')) {
                        this.toStorageDoor(item);
                    }
                }
            });
        });

        return project;
    },

    /**
     * Convert old door frames which is now in SINGLE DOORS subCategory
     * @param {entity} item
     */
    toSingleDoor(item) {
        item.__name__ = 'door';
        item.category = 'DOOR FRAMES';
        item.subCategory = 'SINGLE DOORS';
    },

    /**
     *
     * Convert old door frames which is now in SINGLE DOORS category
     * @param {entity} item
     */
    toStorageDoor(item) {
        item.__name__ = 'door';
        item.category = 'DOOR FRAMES';
        item.subCategory = 'STORAGE DOORS';
    },
};
