<script lang="ts">
    import self from '../../../index';
    import { Vector2 } from '@babylonjs/core';

    const {
        app: {
            modules: {
                buildingPlanManager: {
                    buildingPlanController: bpController,
                    buildingPlanImageController: { IMAGE_MODES },
                },
            },
            events,
        },
    } = self;

    export default {
        props: {
            textObject: {
                type: Object,
                default: null,
            },
            sizeInfos: {
                type: Object,
                default: () => ({
                    x: 0,
                    y: 0,
                }),
            },
            arrowObject: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                canvas: null,
                canvasContext: null,
                canvasScaleRatio: 4,
                context: null,
                textDataMutable: this.textObject,
                arrowObjectMutable: this.arrowObject,
                isSelected: false,
            };
        },
        watch: {
            'textDataMutable.position.x': function textXWatcher() {
                this.updateTextPosition();
            },
            'textDataMutable.position.y': function textYWatcher() {
                this.updateTextPosition();
            },
            'textDataMutable.text': function textContentWatcher() {
                this.updateTextPosition();
            },
        },
        mounted() {
            this.canvas = this.$refs.textArrowCanvas;
            // Set canvas size in CSS
            this.canvas.style.width = this.canvas.parentElement.offsetWidth;
            this.canvas.style.height = this.canvas.parentElement.offsetHeight;

            this.canvas.width = this.canvas.parentElement.offsetWidth * this.canvasScaleRatio;
            this.canvas.height = this.canvas.parentElement.offsetHeight * this.canvasScaleRatio;

            this.context = this.canvas.getContext('2d');
            this.context.scale(this.canvasScaleRatio, this.canvasScaleRatio);

            this.container = document.getElementById('canvas-container');

            if (this.textObject.settingArrow) {
                // Restore annotation position
                const positionX = this.textObject.position.x;
                const positionY = this.textObject.position.y;
                this.drawLine(positionX, positionY);
                this.addListeners();
            } else {
                this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y, true);
            }
            this.addPermanentListener();
        },
        beforeUnmount() {
            this.isSelected = false;
            this.removePermanentListeners();
        },
        methods: {
            computeStartingPosition(endPositionX, endPositionY) {
                // Line
                const center = {
                    x: this.textDataMutable.position.x + this.sizeInfos.x,
                    y: this.textDataMutable.position.y + this.sizeInfos.y,
                };
                const textBoxLimit = {
                    maxX: center.x + this.sizeInfos.x,
                    minX: center.x - this.sizeInfos.x,
                    maxY: center.y + this.sizeInfos.y,
                    minY: center.y - this.sizeInfos.y,
                };

                const startingPos = {
                    x: 0,
                    y: 0,
                };
                if (endPositionY > textBoxLimit.maxY) {
                    if (endPositionX > textBoxLimit.maxX) {
                        startingPos.x = textBoxLimit.maxX;
                        startingPos.y = center.y;
                    } else if (endPositionX < textBoxLimit.minX) {
                        startingPos.x = textBoxLimit.minX;
                        startingPos.y = center.y;
                    } else {
                        startingPos.x = center.x;
                        startingPos.y = textBoxLimit.maxY;
                    }
                } else if (endPositionY < textBoxLimit.minY) {
                    if (endPositionX > textBoxLimit.maxX) {
                        startingPos.x = textBoxLimit.maxX;
                        startingPos.y = center.y;
                    } else if (endPositionX < textBoxLimit.minX) {
                        startingPos.x = textBoxLimit.minX;
                        startingPos.y = center.y;
                    } else {
                        startingPos.x = center.x;
                        startingPos.y = textBoxLimit.minY;
                    }
                } else {
                    startingPos.y = center.y;
                    if (endPositionX > textBoxLimit.maxX) {
                        startingPos.x = textBoxLimit.maxX;
                    } else if (endPositionX < textBoxLimit.minX) {
                        startingPos.x = textBoxLimit.minX;
                    }
                }
                return startingPos;
            },

            drawLine(endPositionX, endPositionY, savedArrow = false, thickened = false) {
                this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                if (thickened) {
                    this.context.lineWidth = 2.5;
                }
                this.context.beginPath();

                const startingPos = this.computeStartingPosition(endPositionX, endPositionY);

                if (!savedArrow) {
                    this.arrowObjectMutable.start.x = startingPos.x;
                    this.arrowObjectMutable.start.y = startingPos.y;
                } else {
                    startingPos.x = this.arrowObjectMutable.start.x;
                    startingPos.y = this.arrowObjectMutable.start.y;
                }

                if (startingPos.x === 0 || startingPos.y === 0) {
                    this.context.closePath();
                    return;
                }

                this.context.moveTo(startingPos.x, startingPos.y);
                this.context.lineTo(endPositionX, endPositionY);
                this.context.stroke();
                this.context.closePath();

                // Tip point
                this.context.beginPath();
                this.context.arc(endPositionX, endPositionY, 2.5, 0, 2 * Math.PI);
                this.context.fill();
                if (thickened) {
                    this.context.lineWidth = 1;
                }
                this.context.closePath();
            },

            updateTextPosition() {
                this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y);
            },

            onMoveLocal(event) {
                const positionX = event.target.offsetLeft + event.offsetX;
                const positionY = event.target.offsetTop + event.offsetY;
                this.drawLine(positionX, positionY);
            },

            onClickLocal(event) {
                if (this.textDataMutable.settingArrow) {
                    const positionX = event.target.offsetLeft + event.offsetX;
                    const positionY = event.target.offsetTop + event.offsetY;
                    this.arrowObjectMutable.end.x = positionX;
                    this.arrowObjectMutable.end.y = positionY;
                    this.textDataMutable.settingArrow = false;
                    this.removeListeners();
                }
            },

            checkIfIsOnArrow(event) {
                const positionX = event.target.offsetLeft + event.offsetX;
                const positionY = event.target.offsetTop + event.offsetY;
                const epsilon = 1500; // pixel coordinates are big

                const pointToCheck = new Vector2(positionX, positionY);
                const AB = this.arrowObjectMutable.end.subtract(this.arrowObjectMutable.start);
                const AC = pointToCheck.subtract(this.arrowObjectMutable.start);

                // Check if are aligned
                const crossA =
                    (this.arrowObjectMutable.end.x - this.arrowObjectMutable.start.x) * (pointToCheck.y - this.arrowObjectMutable.start.y);

                const crossB =
                    (pointToCheck.x - this.arrowObjectMutable.start.x) * (this.arrowObjectMutable.end.y - this.arrowObjectMutable.start.y);

                const cross = crossA - crossB;

                if (cross < -epsilon || cross > epsilon) {
                    return false;
                }

                const dotAC = Vector2.Dot(AB, AC);
                const dotAB = Vector2.Dot(AB, AB);
                return dotAB > dotAC && dotAC > 0;
            },

            cursorCheck(event) {
                if (this.textDataMutable.settingArrow) {
                    return;
                }
                if (this.checkIfIsOnArrow(event)) {
                    this.container.style.cursor = 'pointer';
                    this.arrowObjectMutable.hovered = true;
                } else {
                    this.arrowObjectMutable.hovered = false;
                    if (this.textDataMutable.arrows.every((arrow) => !arrow.hovered)) {
                        this.container.style.cursor = 'default';
                    }
                }
            },

            checkArrowClick(event) {
                if (this.textDataMutable.settingArrow) {
                    return;
                }
                if (this.checkIfIsOnArrow(event)) {
                    this.isSelected = true;
                    this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y, false, true);
                } else if (this.isSelected) {
                    this.isSelected = false;
                    this.drawLine(this.arrowObjectMutable.end.x, this.arrowObjectMutable.end.y);
                }
            },

            deleteArrow() {
                if (this.isSelected) {
                    const index = this.textObject.arrows.findIndex((arrow) => arrow.id === this.arrowObjectMutable.id);
                    this.textObject.arrows.splice(index, 1);
                    bpController.imageController.captureImage(IMAGE_MODES.HTML);
                }
            },

            addPermanentListener() {
                this.container.addEventListener('pointermove', this.cursorCheck);
                this.container.addEventListener('pointerdown', this.checkArrowClick);
                events.on('@obsidian-engine.building-plan-delete', this.deleteArrow);
            },

            addListeners() {
                this.container.addEventListener('pointermove', this.onMoveLocal);
                this.container.addEventListener('pointerdown', this.onClickLocal);
            },

            removeListeners() {
                this.container.removeEventListener('pointermove', this.onMoveLocal);
                this.container.removeEventListener('pointerdown', this.onClickLocal);
            },

            removePermanentListeners() {
                this.container.removeEventListener('pointermove', this.cursorCheck);
                this.container.removeEventListener('pointerdown', this.checkArrowClick);
                events.removeListener('@obsidian-engine.building-plan-delete', this.deleteArrow);
            },
        },
    };
</script>
<template>
    <canvas
        ref="textArrowCanvas"
        class="text-arrow-canvas"
    />
</template>
<style lang="scss">
    @import '../../../../../style/base/variables.scss';
    .text-arrow-canvas {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: $z-index-line-annotation;
    }
</style>
