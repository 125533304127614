import AbstractOptionController from './abstract-option-controller';
// eslint-disable-next-line import/default
import OptionableMixin from '../model/optionable-mixin';
import { OptionUtility } from '../utility/option-utility';
import self from '../index';
import { Vector3 } from '@babylonjs/core';

export default class GroundplateOptionController extends AbstractOptionController {
    constructor(optionController) {
        super(optionController);
        this.groundPlateReferences = {};
        // this.groundPlateReferences[OptionableMixin.GROUNDPLATE_OPTION.TYPE1] = "901 28 0706 SET TG";
        // this.groundPlateReferences[OptionableMixin.GROUNDPLATE_OPTION.TYPE2] = "901 28 0707 SET TG";

        this.initializeEvents(this.groundPlateReferences);
    }

    /**
     * Add a groundplate mesh to the passed entity
     * @param {*} groundplateType
     * @param {*} entity if null take the current entity
     */
    addGroundplate(groundplateType, entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return Promise.resolve(null);
        }

        return this.updateGroundplateMeshes(realEntity, groundplateType);
    }

    isGroundplateRef(ref) {
        return Boolean(this.getGroundplateTypeFromRef(ref));
    }

    /**
     * Returns the groundplate reference from the entity
     * @param {Object} entity
     */
    getGroundplateRef(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return null;
        }

        return this.groundPlateReferences[entity.groundplateOption];
    }

    getGroundplateTypeFromRef(ref) {
        let referenceGroundplateType = OptionableMixin.GROUNDPLATE_OPTION.NONE;

        // Loop through the references array
        Object.keys(this.groundPlateReferences).forEach((groundplateAlias) => {
            if (this.groundPlateReferences[groundplateAlias].includes(ref)) {
                referenceGroundplateType = parseInt(groundplateAlias, 10);
            }
        });

        return referenceGroundplateType;
    }

    updateGroundplateMeshes(frame, type) {
        this.removeGroundplateMeshes(frame);
        frame.groundplateOption = type;

        if (frame.groundplateOption) {
            const groundplateRef = this.getGroundplateRef(frame);
            const groundplates = [];
            groundplates.push(this.loadGroundplateFromRef(groundplateRef));
            frame.optionsMeshes.groundplates = groundplates;

            frame.computeOptionsParameters();
            frame.optionsMeshes.groundplates.forEach((groundplate) => {
                groundplate.name = 'groundplate';
                groundplate.parent = frame.mesh;

                const angleInfo = OptionUtility.angleToUpward(frame.mesh);
                groundplate.rotate(Vector3.Left(), -Math.PI / 2);
                groundplate.rotate(Vector3.Up(), -angleInfo.angle);
                groundplate.rotate(Vector3.Forward(), Math.PI / 2);
                groundplate.translate(Vector3.Forward(), frame.orientedHeight / 2 - 0.001);
                groundplate.computeWorldMatrix(true);
            });
        }
        return Promise.resolve(null);
    }

    removeGroundplateMeshes(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);
        if (entityToRemoveFrom.optionsMeshes && entityToRemoveFrom.optionsMeshes.groundplates.length) {
            entityToRemoveFrom.optionsMeshes.groundplates.forEach((groundplate) => {
                groundplate.dispose();
            });
            entityToRemoveFrom.optionsMeshes.groundplates.splice(0);
        }
    }

    /**
     * Remove the groundplate
     * @param {*} entity if null take the current entity
     */
    removeGroundplate(entity = null) {
        const entityToRemoveFrom = this.optionController.returnRealEntity(entity);

        if (entityToRemoveFrom.optionsMeshes && entityToRemoveFrom.getGroundplateOption() > OptionableMixin.GROUNDPLATE_OPTION.NONE) {
            entityToRemoveFrom.setGroundplateOption(OptionableMixin.GROUNDPLATE_OPTION.NONE);
            this.removeGroundplateMeshes(entity);
        }
    }

    loadGroundplateFromRef(ref) {
        const groundplate = this.meshManager.getMeshFromLoadedGeometry(ref, 'eco', null, {
            category: 'XTREME SOLUTIONS',
            subCategory: 'BETRUSS PARTS',
            ref,
        });

        self.app.modules.meshManager.meshUtility.AddMetadataProperties(groundplate, {
            isOption: true,
        });

        return groundplate;
    }

    /**
     * Callback function used in different events in case we want
     * to update the groundplate information of a frame
     * @param {*} entity if null take the current entity
     */
    updateCanHaveGroundplate(entity = null) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (realEntity) {
            if (realEntity.forceGroundplate) {
                realEntity.canHaveGroundplatesOption = true;
            } else {
                this.availableGroundplates(realEntity);
            }
            if (!realEntity.canHaveGroundplatesOption) {
                this.removeGroundplate(realEntity);
            }
        }
    }

    availableGroundplates(entity = null) {
        const finalEntity = this.optionController.returnRealEntity(entity);
        if (!finalEntity) {
            return null;
        }
        if (!this.canHaveGroundplates(finalEntity)) {
            finalEntity.canHaveGroundplatesOption = false;
            return [];
        }
        const availableReferences = [];
        finalEntity.computeOptionsParameters();

        Object.keys(this.groundPlateReferences).forEach((groundplatesType) => {
            const ref = this.groundPlateReferences[groundplatesType];
            availableReferences.push({ ref, type: parseInt(groundplatesType, 10) });
        });

        finalEntity.canHaveGroundplatesOption = availableReferences.length > 0;

        return availableReferences;
    }

    canHaveGroundplates(entity) {
        const realEntity = this.optionController.returnRealEntity(entity);
        if (!realEntity) {
            return false;
        }
        let isColliding = false;
        realEntity.mesh.computeWorldMatrix();
        realEntity.mesh.refreshBoundingInfo();
        if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < -0.001) {
            isColliding = false;
        } else if (realEntity.mesh.getBoundingInfo().boundingBox.minimumWorld.y < 0.01) {
            isColliding = true;
        }

        const dot = Math.abs(Vector3.Dot(entity.mesh.up, Vector3.Up())); // Check frame orientation

        return isColliding && dot > 0.99;
    }
}
