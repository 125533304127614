// Simple helper to expose variables to the window object in development mode in order to debug them in the browser console.
// List all the variables you want to expose in the window.DEBUG object.
// or in your IDE with research in files, search for "ConsoleHelper.expose" to find all the variables exposed.
window.DEV = {};
const ignoreWarnings = {};
const ConsoleHelper = {
    ignoreWarningWith: (name) => {
        ignoreWarnings[name] = true;
    },
    expose: (name, value) => {
        if (process.env.NODE_ENV === 'development') {
            if (window.DEV[name] && !ignoreWarnings[name]) {
                // eslint-disable-next-line no-console
                console.warn(`Variable ${name} already exists in the window object. It will be overwritten.`);
            }
            window.DEV[name] = value;
        }
    },
};

// eslint-disable-next-line eol-last
export default ConsoleHelper;
