// List of ref products that are not optionable
const refsOfNonOptionableProducts = {
    '606 0992 0992 R430': true,
    '695 0992 0992': true,
    '692 992 496 2480': true,
    '692 992 496 2418': true,
    '697 0992 45': true,
    '606 0496 0496 R368': true,
    '692 992 496 0992': true,
    '606 0496 0496 PIE E': true,
    '606 0992 0992 PIE E': true,
};

/**
 * Entity Data shared among several modules
 */
const EntitiesData = {
    /**
     * Different entity types
     */
    ENTITY_TYPE: Object.freeze({
        STRAIGHT: 'STRAIGHT',
        CURVED: 'CURVED',
        PERFECT: 'PERFECT',
        GLASS: 'GLASS',
        CORNER: 'CORNER',
        COVER: 'COVER',
        BACKLED: 'BACKLED',
        SIDELED: 'SIDELED',
        MOTIONSKIN: 'MOTIONSKIN',
        TRACKSTART: 'TRACKSTART',
        STRUCTURAL: 'STRUCTURAL',
    }),

    REFS_WITHOUT_OPTIONS: Object.freeze(refsOfNonOptionableProducts),

    /** Returns the frame type from the catalog's subcategory
     * @param {String} subCategory
     * @param {String} ref (optional)
     * @returns {ENTITY_TYPE | undefined}
     */
    entityTypeFromSubCategory(subCategory, ref = '') {
        if (refsOfNonOptionableProducts[ref]) {
            return undefined;
        }
        if (subCategory.includes('CORNER PROFILES')) {
            return EntitiesData.ENTITY_TYPE.CORNER;
        }
        if (subCategory.includes('PERFECT CORNERS')) {
            return EntitiesData.ENTITY_TYPE.PERFECT;
        }
        if (subCategory.includes('TRACK LIGHT START')) {
            return EntitiesData.ENTITY_TYPE.TRACKSTART;
        }
        if (ref !== '') {
            if (subCategory.includes('SIDELED')) {
                return EntitiesData.ENTITY_TYPE.SIDELED;
            }
            if (subCategory.includes('COVER PROFILES')) {
                if (!ref.startsWith('608') || ref.endsWith('D00')) {
                    return EntitiesData.ENTITY_TYPE.COVER;
                }
                return undefined;
            }
        }
        return EntitiesData.ENTITY_TYPE[Object.keys(EntitiesData.ENTITY_TYPE).find((key) => subCategory.includes(key))];
    },
};
export default EntitiesData;
