import self from '../..';

const EntityStructure = require('./entity-structure').default;
const Structure = require('@obsidianjs/data-store/src/entity');

const BeTrussB30Structure = EntityStructure.$extend({
    __name__: 'betruss-b30',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Structure.$register(BeTrussB30Structure);
export default BeTrussB30Structure;
