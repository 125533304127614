import config from 'defaultConfig';

const GeometryUtility = {
    adaptToStep(measure, ceil = false, halfStep = false) {
        const factor = halfStep ? 1 : 2;
        if (ceil) {
            return Math.ceil(measure / (config.step * factor)) * config.step * factor;
        }
        return Math.round(measure / (config.step * factor)) * config.step * factor;
    },

    /**
     * Hide or show a mesh and its children
     * @param {Mesh} meshes
     * @param {Boolean} isVisible
     * @param {Boolean} toggleOptions
     */
    toggleMeshVisibility(mesh, isVisible, toggleOptions = true) {
        if (!mesh) {
            return;
        }

        if (!mesh.metadata || !mesh.metadata.isOption || toggleOptions) {
            mesh.isVisible = isVisible;
        } else {
            return;
        }

        mesh.getChildMeshes(true).forEach((childMesh) => {
            this.toggleMeshVisibility(childMesh, isVisible, toggleOptions);
        });
    },

    /**
     * Toggle multiple meshes visibility to isVisible
     * @param {Mesh []} meshes
     * @param {Boolean} isVisible
     * @param {Boolean} toggleOptions
     */
    toggleMeshesVisibility(meshes, isVisible, toggleOptions = true) {
        meshes.forEach((mesh) => {
            this.toggleMeshVisibility(mesh, isVisible, toggleOptions);
        });
    },

    /**
     * Check if a given mesh and its children are visible
     * @param {*} mesh - the mesh to check
     */
    isMeshVisible(mesh) {
        const childMeshes = mesh.getChildMeshes();
        if (childMeshes.length) {
            return childMeshes.some((childMesh) => childMesh.isVisible) && mesh.isVisible;
        }
        return mesh.isVisible;
    },
};
export default GeometryUtility;
