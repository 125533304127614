<script lang="ts">
    import BuildingPlanTable from './table.vue';
    import Annotations from './annotations.vue';
    import TextBoxes from './text-boxes.vue';
    import UserImages from './user-images.vue';
    import self from '../../../index';

    const {
        app: {
            modules: {
                buildingPlanManager: { buildingPlanController: bpController, PAGE_TYPES },
            },
        },
    } = self;

    export default {
        name: 'PdfHtmlContent',
        components: {
            BuildingPlanTable,
            Annotations,
            TextBoxes,
            UserImages,
        },
        computed: {
            is2DPage() {
                return bpController.getPage().type === PAGE_TYPES.HTML;
            },
            hasPage() {
                return Boolean(bpController.getPage());
            },
            pageHasTable() {
                const page = bpController.getPage();
                return page.table && page.table.exists && Object.keys(page.table.productsQuantities).length;
            },
            pageHasAnnotations() {
                const page = bpController.getPage();
                return page && page.table && page.table.exists && Object.keys(page.annotations).length;
            },
            pageHasTextBoxes() {
                const page = bpController.getPage();
                return page && page.texts && Object.keys(page.texts).length;
            },
            pageHasUserImages() {
                const page = bpController.getPage();
                return page && page.userImages && Object.keys(page.userImages).length;
            },
        },
    };
</script>
<template>
    <div
        v-if="hasPage"
        id="pdf-components-container"
        :class="{ 'page-2d': is2DPage }"
    >
        <building-plan-table v-if="pageHasTable" />
        <text-boxes v-if="pageHasTextBoxes" />
        <annotations v-if="pageHasAnnotations" />
        <user-images v-if="pageHasUserImages" />
    </div>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix';
    #pdf-components-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;

        pointer-events: none;

        &.page-2d {
            background-color: white;
            pointer-events: auto;
        }

        .line-canvas {
            position: absolute;
            z-index: $z-index-line-annotation;
        }
    }
</style>
