/**
 * Setup stuff of the project that doesn't rely to a particular module
 */
export default {
    name: 'init-utility',
    requires: ['iframe-api', 'stonejs'],
    load() {
        const InitUtility = require('./src/init-utility');
        InitUtility.init();
        return InitUtility;
    },
    unload() {},
};
