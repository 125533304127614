import self from '../..';
import { TransformNode, AbstractMesh } from '@babylonjs/core';

const {
    events,
    modules: {
        gridManager,
        collisionManager: { Controller: collisionController, CollisionHelper },
        meshManager,
    },
} = self.app;

export default class GizmoHelper {
    static generateHighlightBehavior(highlightBehavior) {
        const meshes = highlightBehavior.mesh ? [highlightBehavior.mesh] : highlightBehavior.meshes;

        const collisionBehavior = () =>
            !meshes.some((mesh) => {
                const isColliding = collisionController.checkStaticCollisionsEntity(mesh.entity);
                return isColliding && isColliding !== mesh.isColliding;
            });
        highlightBehavior.addHighlightBehaviorRules(collisionBehavior);
        const gridBehavior = () => {
            const bb = meshManager.meshUtility.ComputeMeshListBB(meshes);
            const checkUnderGrid = meshes.some((mesh) => CollisionHelper.isUnderGrid(mesh.entity, { isEntity: true }));
            return gridManager.isBBInsideGrid(bb) && !checkUnderGrid;
        };
        highlightBehavior.addHighlightBehaviorRules(gridBehavior);
    }

    static isGoingUnderGrid(attachedObject, worldDelta) {
        let minYWorld;
        if (attachedObject instanceof AbstractMesh) {
            if (CollisionHelper.havePreciseBoundingBox(attachedObject)) {
                const bestBBs = CollisionHelper.getObjectBestBBs(attachedObject);
                minYWorld = bestBBs
                    .map((bb) => bb.reduce((vec1, vec2) => (vec1.y < vec2.y ? vec1 : vec2)).y)
                    .reduce((minY1, minY2) => (minY1 < minY2 ? minY1 : minY2));
            } else {
                minYWorld = attachedObject.getBoundingInfo().boundingBox.minimumWorld.y;
            }
        } else if (attachedObject instanceof TransformNode) {
            const meshesToCheck = attachedObject.getChildMeshes(true).filter((mesh) => mesh.entity);
            minYWorld = meshManager.meshUtility.ComputeMeshListBB(meshesToCheck).minimumWorld.y;
        }
        if (minYWorld) {
            return minYWorld + worldDelta.y < -0.001;
        }
        return false;
    }

    static checkNodeGridRepulsion(nodeMesh) {
        const meshesToCheck = nodeMesh.getChildMeshes(true).filter((mesh) => mesh.entity);
        const checkMeshBelowGrid = meshesToCheck.some((mesh) => CollisionHelper.isUnderGrid(mesh.entity, { isEntity: true }));
        if (checkMeshBelowGrid) {
            const repulsionY = CollisionHelper.computeGridRepulsionMeshList(meshesToCheck);
            nodeMesh.position.y += -repulsionY;
        }
    }

    static attachMeshToMovableGizmo(gizmo, mesh) {
        if (gizmo.highlightBehavior) {
            gizmo.highlightBehavior.cleanBehavior();
            gizmo.highlightBehavior = null;
        }
        if (mesh) {
            gizmo._meshAttached = mesh;
            gizmo.attachedEntity = mesh.entity;
            if (mesh instanceof AbstractMesh) {
                gizmo.highlightBehavior = gizmo.highlightManager.createNewHighlightBehavior(mesh);
            } else if (mesh instanceof TransformNode) {
                gizmo.highlightBehavior = gizmo.highlightManager.createNewHighlightBehavior(mesh.getChildMeshes(true));
            }
            GizmoHelper.generateHighlightBehavior(gizmo.highlightBehavior);
            [gizmo.xGizmo, gizmo.yGizmo, gizmo.zGizmo].forEach((subGizmo) => {
                subGizmo.dragBehavior.attachedEntity = mesh.entity;
                subGizmo.attachedMesh = mesh;
            });
        } else {
            gizmo.attachedEntity = null;
            gizmo._meshAttached = null;
        }
    }

    static addGizmoDragEvents(dragBehavior) {
        dragBehavior.onDragStartObservable.add(() => events.emit('drag-start-gizmo'));
        dragBehavior.onDragObservable.add(() => {
            events.emit('dragging-gizmo');
        });
        dragBehavior.onDragEndObservable.add(() => events.emit('drag-end-gizmo'));
    }

    static updateGizmoAttachedMeshChildren(gizmo) {
        const meshesToCheck = gizmo.attachedMesh.getChildMeshes(true).filter((mesh) => mesh.entity);
        meshesToCheck.forEach((child) => {
            child.computeWorldMatrix(true);
            child.entity.updateDynamicParameters();
        });
    }
}
