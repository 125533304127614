export default {
    name: 'gizmo-manager',
    requires: [
        'collision-manager',
        'history',
        'highlight-manager',
        'grid-manager',
        'gui-manager',
        'obsidian-engine',
        'mesh-manager',
        'entity-manager',
    ],
    load() {
        const { GizmoManager, GIZMO_ENUM: GizmoStates } = require('./src/controllers/gizmo-manager');

        return {
            controller: new GizmoManager(),
            gizmoStates: GizmoStates,
        };
    },
    unload() {},
};
