export default {
    name: 'assets-manager',
    requires: [],
    load() {
        const Controller = require('./src/controllers/assets-controller').default; // eslint-disable-line
        return {
            controller: new Controller(),
        };
    },
    unload() {},
};
