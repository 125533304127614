export default {
    name: 'group-manager',
    requires: [
        'data-store',
        'data-exporter',
        'geometry-utility',
        'obsidian-engine',
        'entity-manager',
        'selection-manager',
        'stonejs',
        'history',
        'option-manager',
    ],
    load() {
        const GroupControllerClass = require('./src/controllers/group-controller').default; // eslint-disable-line
        const Controller = new GroupControllerClass();
        const GroupSelectionController = require('./src/controllers/group-selection-controller').default;
        const GroupSelection = new GroupSelectionController(Controller);
        const DisplayHelper = require('./src/helpers/display-group-helper').default;
        return {
            Controller,
            DisplayHelper,
            GroupSelection,
        };
    },
    unload() {},
};
