export default {
    name: 'gui-manager',
    requires: ['obsidian-engine', 'mesh-manager'],
    load() {
        const Controller = require('./src/controller/gui-controller.js').default; // eslint-disable-line
        return {
            GuiController: new Controller(),
            DragSizeViewer: require('./src/gui/drag-size-viewer').default,
        };
    },
    unload() {},
};
