<script lang="ts">
    import camera from './camera.vue';
    import history from './history.vue';
    import actions from './actions.vue';
    import sceneInteractions from './scene-interactions.vue';
    import grid from './grid.vue';
    import self from '../../index';
    import EventBus from '../eventBus';
    import RightsHelper from '../../../../helpers/rights-helper';

    const {
        app: {
            modules: {
                buildingPlanManager: {
                    buildingPlanController: bpController,
                    buildingPlanImageController: { IMAGE_MODES },
                    buildingPlanHelper: bpHelper,
                    PAGE_TYPES,
                },
                selectionManager: selectionController,
                measurementManager,
                stonejs,
                iframeApi,
            },
            events: { on, emit },
        },
    } = self;

    export default {
        name: 'TopMenu',
        components: {
            camera,
            history,
            actions,
            sceneInteractions,
            grid,
        },
        props: {
            cameraData: {
                type: Object,
                default: () => ({
                    orthographic: false,
                }),
            },
            gridData: {
                type: Object,
                default: () => ({}),
            },
            config: {
                type: Object,
                default: () => ({}),
            },
            optionsData: {
                type: Object,
                default: () => ({
                    optionsFamilies: {},
                    showInfills: true,
                    infillsVisibilityLocked: false,
                }),
            },
            materialData: {
                type: Object,
                default: () => ({
                    areFramesTransparent: false,
                }),
            },
            measurementData: {
                type: Object,
                default: () => ({
                    measureModeActivated: false,
                }),
            },
            buildingPlanData: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                isExpanded: false,
                isPublic: RightsHelper.isModePublic(),
                localMeasureMode: this.measurementData.measureModeActivated,
                bpImageController: bpController.imageController,
                connectorSelectionModeEnabled: false,
                PAGE_TYPES,
            };
        },
        computed: {
            canDisplayGrid() {
                return RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
            },
            canDisplayScreenshotButton() {
                return (
                    RightsHelper.isRoleDesigner() &&
                    RightsHelper.isPlanBasic() &&
                    !RightsHelper.isModePublic() &&
                    !RightsHelper.isModeBuildingPlan()
                );
            },
            canDisplayCamera() {
                return this.isScenePageActiveCamera();
            },
            canDisplaySceneInteractions() {
                return !this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type === PAGE_TYPES.SCENE;
            },
            canDisplayMeasurementButton() {
                return (
                    (RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan()) ||
                    (RightsHelper.isModeBuildingPlan() && this.isScenePageActiveCamera())
                );
            },
            canDisplayHistory() {
                return RightsHelper.isRoleDesigner() && !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
            },
            canDisplayActions() {
                return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
            },
            canDisplaySaveButton() {
                return RightsHelper.isRoleDesigner() && RightsHelper.isMode3dAuthenticated();
            },
            isConnectorSelectionOn() {
                return RightsHelper.isModeBuildingPlan() && this.canBeEdited && this.pageConnectorSelectionModeEnabled;
            },
            isConnectorSelectionOff() {
                return RightsHelper.isModeBuildingPlan() && this.canBeEdited && !this.pageConnectorSelectionModeEnabled;
            },

            // Pdf mode only
            // Does the page have a scene screenshot
            canDisplayPdfModeButtons() {
                return RightsHelper.isModeBuildingPlan() && RightsHelper.isPlanEssential();
            },

            canBeValidated() {
                const page = bpController.getPage();
                return (
                    bpController.isPageEditable() &&
                    (page.type === PAGE_TYPES.HTML || (page.type === PAGE_TYPES.SCENE && page.images.SCENE))
                );
            },
            canBeModified() {
                const page = bpController.getPage();
                return (
                    page &&
                    !bpController.isPageEditable() &&
                    (page.type === PAGE_TYPES.HTML || (page.type === PAGE_TYPES.SCENE && page.images.SCENE))
                );
            },
            canBeEdited() {
                const page = bpController.getPage();
                return bpController.isPageEditable() && page.type === PAGE_TYPES.SCENE && page.images.SCENE;
            },
            pageConnectorSelectionModeEnabled() {
                const page = bpController.getPage();
                return bpController.isPageEditable() && page.scenePageMetadata.connectorSelectionModeEnabled;
            },
        },
        mounted() {
            EventBus.$on('toggle-groups', (open) => {
                this.isExpanded = !open;
            });

            on('checkVersion', (onValidateEvent, isRequired = false) => {
                if (this.config.versionName === '') {
                    if (onValidateEvent === 'save') {
                        this.openVersionPopin(onValidateEvent, isRequired);
                    } else {
                        this.openMustSavePopin(onValidateEvent, isRequired);
                    }
                } else {
                    if (onValidateEvent === 'save') {
                        this.$emit('onSaveProject');
                    }
                    iframeApi.sendEvent(onValidateEvent);
                    EventBus.$emit(onValidateEvent);
                }
            });
        },
        methods: {
            isScenePageActiveCamera() {
                return !this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type === this.PAGE_TYPES.SCENE;
            },

            environmentSelection() {
                emit('checkVersion', 'open-environment-selector', true);
            },

            /**
             * Shows a popin where the user give its project a version name
             */
            openVersionPopin(onValidateEvent = 'save', isRequired = false) {
                emit('open-popin', {
                    title: this._('New version'),
                    description: `${this._('Please insert the version name')}
                    : <br />
                        <input type="text" id="version-name" required pattern= "^([a-zA-Z0-9.]|\\s)+$">
                    <br />
                        <span class="warning-info">
                            ${this._(
                                'Warning : Please give a valid version name' +
                                    ', if you write any special characters like (!,_-^%)' +
                                    ' or give an empty version name,the submission will be blocked.',
                            )}
                        </span>`,
                    isRequired,
                    onValidate: () => {
                        emit('lockUnload');
                        this.config.versionName = document.getElementById('version-name').value;
                        if (onValidateEvent === 'save') {
                            this.$emit('onSaveProject');
                        }
                        iframeApi.sendEvent(onValidateEvent);
                    },
                });
            },

            openMustSavePopin() {
                emit('open-popin', {
                    title: this._("Can't take screenshot"),
                    description: `${this._('You must have saved your project to take a screenshot')}`,
                    isRequired: true,
                    onValidate: () => {},
                });
            },
            save() {
                emit('checkVersion', 'save', true);
            },
            toggleMeasurement() {
                measurementManager.Controller.toggleMeasureMode();
            },
            _(...args) {
                return stonejs.gettext(...args);
            },

            // Pdf mode only
            validatePage() {
                if (bpController.currentPage.type === PAGE_TYPES.HTML) {
                    bpController.imageController.captureImage(IMAGE_MODES.HTML);
                }

                bpController.writePageToPdf();
                bpController.endPageEditing();
            },
            modifyPage() {
                selectionController.unselectAll();
                bpController.clearPage();
                bpController.recreatePage();
                bpController.startPageEditing();
            },
            /**
             * Toggle connector selection mode
             * We also need to toggle frame transparency and infill visibility
             */
            toggleConnectorSelectionMode() {
                const page = bpController.getPage();
                const connectorSelectionMode = !page.scenePageMetadata.connectorSelectionModeEnabled;

                bpHelper.setConnectorSelectionMode(page, connectorSelectionMode);
            },

            /**
             * Saves the building plan structure as an obsidian file
             */
            savePdf() {
                if (!bpController.isPdfComplete()) {
                    this.openPdfIncompletePopin(false, () => {
                        bpController.save();
                    });
                } else {
                    bpController.save();
                }
            },
            /**
             * Download the building plan pdf passing by BO
             */
            downloadPdf() {
                if (!bpController.isPdfComplete()) {
                    this.openPdfIncompletePopin(false, () => {
                        bpController.generatesTemporaryPdf();
                    });
                } else {
                    bpController.generatesTemporaryPdf();
                }
            },

            openPdfIncompletePopin(isRequired, onValidate) {
                emit('open-popin', {
                    title: this._('PDF incomplete'),
                    description: `${this._('You are about to download an imcomplete PDF')}
                    <br />
                    <br />
                    <span class="warning-info">
                        ${this._(
                            'Warning : Pages are still being edited.' +
                                'The resulting PDF can be different ' +
                                'from the preview or pages maybe blank!',
                        )}
                    </span>`,
                    isRequired,
                    onValidate,
                });
            },
        },
    };
</script>

<template>
    <div>
        <b-navbar
            class="navbar-top"
            :class="{
                noRightMenu: isExpanded,
                noLeftMenu: isPublic,
            }"
        >
            <!-- Pdf mode only -->
            <b-navbar-nav v-if="canDisplayPdfModeButtons">
                <b-nav-item
                    v-b-tooltip.hover
                    :title="_('Download PDF')"
                    :link-classes="'px-4'"
                    class="h-100 item-interactions"
                    @click="downloadPdf"
                >
                    <img
                        class="icon"
                        src="assets/icons/download.svg"
                    />
                </b-nav-item>
                <!-- Save is not supposed to exist -->
                <!-- It only exist for debugging purposes -->
                <!-- As we still don't have BP saving we comment the button -->
                <!-- <b-nav-item
                    :link-classes="'px-4'"
                    class="h-100 item-interactions top-menu-div border-right"
                    @click="savePdf"
                >
                    <img
                        class="icon"
                        src="assets/icons/save-black.svg"
                    >
                </b-nav-item> -->
            </b-navbar-nav>
            <b-navbar-brand :title="config.projectName">
                {{ config.projectName }}
            </b-navbar-brand>
            <b-nav-text :title="config.versionName">
                {{ config.versionName }}
            </b-nav-text>

            <b-navbar-nav class="ml-auto">
                <!-- Nav grid menu -->
                <grid
                    v-if="canDisplayGrid"
                    :config="config"
                    :grid-data="gridData"
                />
                <!-- !Nav grid menu -->
                <b-nav-item
                    v-if="canDisplayScreenshotButton"
                    v-b-tooltip.hover
                    class="h-100 item-interactions top-menu-div"
                    :title="_('Take screenshot')"
                    :link-classes="'px-4'"
                    @click="environmentSelection()"
                >
                    <b-img
                        class="icon"
                        src="/assets/icons/screenshot.svg"
                    />
                </b-nav-item>
                <scene-interactions
                    v-if="canDisplaySceneInteractions"
                    class="item-interactions"
                    :options-data="optionsData"
                    :material-data="materialData"
                />
                <camera
                    v-if="canDisplayCamera"
                    class="item-interactions"
                    :camera-data="cameraData"
                />
                <span v-if="canDisplayMeasurementButton">
                    <b-nav-item
                        v-if="!measurementData.measureModeActivated"
                        v-b-tooltip.hover
                        :title="_('Open measuring tool')"
                        :link-classes="'px-4'"
                        class="h-100 item-interactions top-menu-div"
                        @click="toggleMeasurement()"
                    >
                        <img src="assets/icons/measuring-tool.svg" />
                    </b-nav-item>
                    <b-nav-item
                        v-else-if="measurementData.measureModeActivated"
                        v-b-tooltip.hover
                        :title="_('Close measuring tool')"
                        :link-classes="'px-4'"
                        class="h-100 item-interactions top-menu-div"
                        @click="toggleMeasurement()"
                    >
                        <img src="assets/icons/measuring-tool-close.svg" />
                    </b-nav-item>
                </span>
                <history v-if="canDisplayHistory" />
                <actions
                    v-if="canDisplayActions"
                    :config="config"
                    class="item-interactions"
                    @onSaveProject="$emit('onSaveProject')"
                />

                <!-- PDF ELEMENTS PART -->
                <b-nav-item
                    v-if="isConnectorSelectionOn"
                    class="connector-interaction-button top-menu-div"
                    @click="toggleConnectorSelectionMode"
                >
                    <img src="/assets/icons/normal-selection-mode.svg" />
                    {{ _('Normal selection mode') }}
                </b-nav-item>
                <b-nav-item
                    v-else-if="isConnectorSelectionOff"
                    class="connector-interaction-button top-menu-div"
                    @click="toggleConnectorSelectionMode"
                >
                    <img src="/assets/icons/connector-selection-mode.svg" />
                    {{ _('Connector selection mode') }}
                </b-nav-item>
                <div class="vertical-button-list">
                    <div
                        v-if="canBeModified"
                        class="edition-page-button"
                        @click="modifyPage"
                    >
                        <div class="edition-button-icon-container">
                            <img
                                class="icon"
                                src="assets/icons/edit-page.svg"
                            />
                        </div>
                        {{ _('Redo page') }}
                    </div>
                    <div
                        v-else-if="canBeValidated"
                        class="edition-page-button"
                        @click="validatePage"
                    >
                        <div class="edition-button-icon-container">
                            <img
                                class="icon"
                                src="assets/icons/check.svg"
                            />
                        </div>
                        {{ _('Validate page') }}
                    </div>
                </div>
                <!-- END OF PDF ELEMENTS PART -->
            </b-navbar-nav>
        </b-navbar>
        <div
            v-if="canDisplaySaveButton"
            v-b-tooltip.hover
            :title="_('Save')"
            :class="{
                noRightMenu: isExpanded,
            }"
            class="save-btn"
            @click="save"
        >
            <img src="assets/icons/save.svg" />
        </div>
    </div>
</template>
