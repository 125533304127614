import self from '../..';

const EntityStructure = require('./entity-structure').default;
const Structure = require('@obsidianjs/data-store/src/entity');

const CornerStructure = EntityStructure.$extend({
    __name__: 'corner',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Structure.$register(CornerStructure);
export default CornerStructure;
