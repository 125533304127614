export default {
    name: 'request-manager',
    requires: ['iframe-api'],
    load() {
        const Controller = require('./src/controllers/request-controller').default; // eslint-disable-line
        return {
            controller: new Controller(),
        };
    },
    unload() {},
};
