import { Matrix, Vector3 } from '@babylonjs/core';

/**
 * Transform mouse coordiante to 3D coordinate, intersection with given plane
 * @param engine the current engine
 * @param scene the current scene
 * @param planeNormal target plane normal ((0, 1, 0) by default)
 * @param planeOffset target plane offset (near/far)
 * @returns resulting 3D position
 */
export const mouseToWorld = (engine, scene, planeNormal = new Vector3(0, 1, 0), planeOffset = 0) => {
    const camera = scene.activeCamera;
    const far = Vector3.Unproject(
        new Vector3(scene.pointerX, scene.pointerY, 0.5),
        engine.getRenderWidth(),
        engine.getRenderHeight(),
        Matrix.Identity(),
        scene.getViewMatrix(),
        scene.getProjectionMatrix(),
    );

    const direction = far.subtract(camera.position).normalize();
    let dot = Vector3.Dot(direction, planeNormal);
    if (Math.abs(dot) < 0.001) {
        dot = 0.001;
    }
    const distance = (planeOffset - Vector3.Dot(camera.position, planeNormal)) / dot; // Prevents infinite distance

    return camera.position.add(direction.scale(distance));
};
