import self from '../../index';

const {
    app: {
        modules: {
            cameraManager: { controller: cameraController },
        },
    },
} = self;

export const PAGE_TYPES = {
    SCENE: 0,
    HTML: 1,
};

// TODO: Create real serializable classes and architecture issue 510 & 511
export class Page {
    /**
     * @param{PAGE_TYPES} pageType
     */
    constructor(pageType) {
        this.type = pageType;

        this.editing = true;

        // This part of the pages is handled by the image controller
        if (pageType === PAGE_TYPES.SCENE) {
            this.images = {
                SCENE: null,
                HTML: null,
            };

            this.scenePageMetadata = {
                cameraMode: cameraController.getCameraMode(),
                connectorSelectionModeEnabled: false,
                // Visible products at the moment of the scene capture
                activeProducts: null,
                // Keep the position of the pointer when it clicked on an object
                selectedProductsPointerPosition: {},
                productsVisibility: [],
                measurements: [],
            };
            this.table = {
                exists: false,
                productsQuantities: {},
            };
            this.annotations = {};
            this.camera = null; // TODO= Removing when add serializable class for pages
        } else {
            this.images = {
                HTML: null,
            };

            this.userImages = {};
        }
        this.texts = {};
    }
}
