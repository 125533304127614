class EventBus {
    constructor() {
        this.callbacks = [];
    }

    emit(eventName, ...args) {
        this.callbacks.filter((callback) => callback.eventName === eventName).forEach(({ callback }) => callback(...args));
    }

    on(eventName, callback) {
        const symbol = Symbol(eventName);
        this.callbacks = [...this.callbacks, { symbol, eventName, callback }];

        return symbol;
    }

    off(symbol) {
        this.callbacks = this.callbacks.filter((callback) => callback.symbol !== symbol);
    }
}

const eventBus = new EventBus();
export default eventBus;
