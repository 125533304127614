<script lang="ts">
    import self from '../../../index';
    import EventBus from '../../eventBus';
    import RightsHelper from '../../../../../helpers/rights-helper';

    const {
        app: {
            modules: {
                optionManager: { optionController },
                measurementManager: { Controller: measurementController },
                buildingPlanManager: { buildingPlanController: bpController, PAGE_TYPES },
            },
        },
    } = self;

    export default {
        name: 'ProductOption',
        props: {
            productSelected: {
                type: Boolean,
                default: () => true,
            },
            optionKey: {
                type: String,
                default: () => '',
            },
            optionFamily: {
                type: Number,
                default: () => 0,
            },
            product: {
                type: Object,
                default: () => ({}),
            },
            optionsNumber: {
                type: Object,
                default: () => ({}),
            },
            config: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                optionSelected: false,
                optionsFamilies: optionController.optionsFamilies,
                optionsData: optionController.vueData,
                measurementController,
            };
        },
        computed: {
            canRemoveOption() {
                return !RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan();
            },
            canDisplayIcon() {
                return !RightsHelper.isModePublic();
            },
            hideInfill() {
                return this.family === this.optionsFamilies.INFILL && !this.optionsData.showInfills;
            },
            family() {
                return optionController.getOptionFamilyFromKey(this.optionKey);
            },
            isVisible() {
                return this.optionFamily && this.currentOptionNumber;
            },
            currentOptionNumber() {
                switch (this.optionKey) {
                    case 'baseplateOption':
                        return 1;
                    case 'groundplateOption':
                        return 1;
                    case 'lightOption':
                        return this.optionsNumber.lightNumber;
                    case 'shelfOption':
                        return this.optionsNumber.shelfNumber;
                    case 'screenOption':
                        return this.optionsNumber.screenNumber;
                    case 'infillOption':
                        return 1;
                    default:
                        return 0;
                }
            },
            optionText() {
                let ref;
                let number;
                let family = null;
                switch (this.optionKey) {
                    case 'baseplateOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.BASEPLATE, this.product);
                        return `${this._('Baseplate')} : ${ref}`;
                    case 'groundplateOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.GROUNDPLATE, this.product);
                        return `${this._('Groundplate')} : ${ref}`;
                    case 'lightOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.LIGHT, this.product);
                        family =
                            this.optionFamily === self.app.modules.optionManager.OptionableMixin.LIGHT_SINGULARITY_OPTION.SIMPLE
                                ? 'SIMPLE'
                                : 'DOUBLE';
                        number = this.optionsNumber.lightNumber;
                        return `${this._('Lights')} : ${ref} - ${family} * ${number}`;
                    case 'shelfOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.SHELF, this.product);
                        number = this.optionsNumber.shelfNumber;
                        return `${this._('Shelves')} : ${ref} * ${number}`;
                    case 'screenOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.SCREEN, this.product);
                        number = this.optionsNumber.screenNumber;
                        return `${this._('Screen brackets')} : ${ref} * ${number}`;
                    case 'infillOption':
                        ref = optionController.getOptionReference(this.optionsFamilies.INFILL, this.product);
                        family =
                            this.optionFamily === self.app.modules.optionManager.OptionableMixin.INFILL_OPTION.ONE_FACE
                                ? 'ONE FACE'
                                : 'BOTH FACES';
                        return `${this._('Infill')} : ${ref} : ${family}`;
                    default:
                        return '';
                }
            },
        },
        watch: {
            productSelected(value) {
                this.optionSelected = value && this.optionSelected;
            },
        },
        mounted() {
            EventBus.$on('option-menu-open', (optionFamily) => {
                if (this.productSelected && this.family === optionFamily) {
                    this.optionSelected = this.productSelected;
                } else {
                    this.optionSelected = false;
                }
            });
            EventBus.$on('option-menu-close', () => {
                this.optionSelected = false;
            });
        },
        methods: {
            /**
             * Open the option menu to edit the select option
             */
            editOption() {
                if (this.measurementController.measureModeActivated) {
                    return;
                }

                if (!bpController.isCameraFixed && bpController.currentPage.type !== PAGE_TYPES.HTML) {
                    const family = optionController.getOptionFamilyFromKey(this.optionKey);

                    if (!this.productSelected) {
                        self.app.modules.groupManager.GroupSelection.selectProduct(this.product);
                        // Currententity is not set when we call the event
                        setTimeout(() => {
                            self.app.events.emit('selectOption', family);
                        }, 100);
                    } else {
                        self.app.events.emit('selectOption', family);
                    }

                    // Force the optionSelection before selectProduct does
                    this.optionSelected = true;
                }
            },
            removeOption() {
                if (this.measurementController.measureModeActivated) {
                    return;
                }
                const family = optionController.getOptionFamilyFromKey(this.optionKey);
                optionController.removeOption(family, this.product, true);
            },
            _(...args) {
                return self.app.modules.stonejs.gettext(...args);
            },
        },
    };
</script>
<template>
    <li
        v-if="hideInfill && isVisible"
        class="option optionHidden"
    >
        <template v-if="canDisplayIcon">
            <img src="/assets/icons/options.svg" />
        </template>
        <span class="option-text">
            {{ optionText }}
        </span>
    </li>
    <li
        v-else-if="isVisible"
        class="option"
        :class="{
            optionSelected: optionSelected,
        }"
        @click.stop="editOption()"
    >
        <img :src="optionSelected ? '/assets/icons/options-select.svg' : '/assets/icons/options.svg'" />
        <span class="option-text">
            {{ optionText }}
        </span>
        <template v-if="canRemoveOption">
            <img
                :src="productSelected ? '/assets/icons/close.svg' : '/assets/icons/close-black.svg'"
                class="interaction-icon ml-auto"
                @click.stop="removeOption()"
            />
        </template>
    </li>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix';
    .option {
        display: flex;
        align-items: center;
        font-family: $font-regular;
        font-size: 0.75rem;
        padding: 2% 0;
        padding-left: $group-padding-left;
        margin-left: $group-product-margin-left * 2;
        margin-right: $group-product-margin-left;
        color: #6a696c;
        border-radius: 3px;

        &:not(.optionSelected):hover {
            background-color: white;

            .interaction-icon {
                display: inline;
            }
        }

        .option-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &.optionSelected {
            background-color: $green;
            color: #fff;
        }

        &.optionHidden {
            background-color: #cccccc;
            color: #fff;

            &:hover {
                background-color: #cccccc;

                .interaction-icon {
                    display: inline;
                }
            }
        }

        img {
            padding: 0 0.35rem;
            max-height: 13px;
        }
    }
</style>
