import self from '..';
import config from 'defaultConfig';
import { Engine, Mesh, VertexBuffer, VertexData, Quaternion, Matrix, Vector3, BoundingBox, Tools } from '@babylonjs/core';

import { v4 as uuid } from 'uuid';

export default {
    /**
     * @param {Mesh} mesh
     */
    SplitFacesWithHoles(mesh) {
        const meshes = [];
        const indexes = mesh.getIndices();
        const positions = mesh.getVerticesData(VertexBuffer.PositionKind);
        const normals = mesh.getVerticesData(VertexBuffer.NormalKind);
        mesh.name = 'Root';

        // First, we split the vertices of faces aligned on the x plan or the z plan
        let xPlans = {};
        let zPlans = {};
        for (let i = 0; i < indexes.length; i += 3) {
            const i1 = indexes[i] * 3;
            const i2 = indexes[i + 1] * 3;
            const i3 = indexes[i + 2] * 3;
            const v1 = new Vector3(positions[i1], positions[i1 + 1], positions[i1 + 2]);
            const v2 = new Vector3(positions[i2], positions[i2 + 1], positions[i2 + 2]);
            const v3 = new Vector3(positions[i3], positions[i3 + 1], positions[i3 + 2]);
            // Make it round
            v1.x = Math.round(v1.x * 10000) / 10000;
            v2.x = Math.round(v2.x * 10000) / 10000;
            v3.x = Math.round(v3.x * 10000) / 10000;
            v1.y = Math.round(v1.y * 10000) / 10000;
            v2.y = Math.round(v2.y * 10000) / 10000;
            v3.y = Math.round(v3.y * 10000) / 10000;
            let planFace = null;
            if (v1.x === v2.x && v2.x === v3.x) {
                if (!xPlans[v1.x]) {
                    xPlans[v1.x] = {
                        indices: [],
                        positions: [],
                        val: v1.x,
                    };
                }
                planFace = xPlans[v1.x];
            } else if (v1.y === v2.y && v2.y === v3.y) {
                if (!zPlans[v1.y]) {
                    zPlans[v1.y] = {
                        indices: [],
                        positions: [],
                        val: v1.y,
                    };
                }
                planFace = zPlans[v1.y];
            }
            if (planFace) {
                planFace.positions.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v3.x, v3.y, v3.z);
                planFace.indices.push(i, i + 1, i + 2);
            }
        }
        xPlans = Object.values(xPlans);
        zPlans = Object.values(zPlans);

        // We make sure we have only 4 faces with hole per plan orientation
        // => deletion of faces near the maximums
        [xPlans, zPlans].forEach((plans) => {
            const comparisonFunc = (planA, planB) => {
                if (planA.val < planB.val) {
                    return -1;
                }
                if (planA.val > planB.val) {
                    return 1;
                }
                return 0;
            };
            if (plans.length > 4) {
                plans.sort(comparisonFunc);
                do {
                    plans.splice(1, 1);
                    plans.splice(plans.length - 2, 1);
                } while (plans.length > 4);
            }
            plans.forEach((plan) => {
                const planMesh = new Mesh(uuid(), mesh.getScene());
                planMesh.isUnIndexed = true; // who needs indexes for rectangles ?
                const vertexData = new VertexData();
                vertexData.positions = plan.positions;
                const planNormals = [];

                for (let i = 0; i < plan.indices.length; i += 1) {
                    const ind = indexes[i];
                    planNormals.push(normals[ind], normals[ind + 1], normals[ind + 2]);
                }
                // Yep, we need indices because obj import/export seems to work only with indices
                const indices = [];
                for (let i = 0; i < vertexData.positions.length / 3; i += 1) {
                    indices.push(i);
                }
                vertexData.indices = indices;
                // Normals computation
                VertexData.ComputeNormals(vertexData.positions, indices, planNormals);
                vertexData.normals = planNormals;
                vertexData.applyToMesh(planMesh);
                meshes.push(planMesh);
            });
        });

        // Remove corresponding indices from the main mesh
        const newIndices = [];
        for (let i = 0; i < indexes.length; i += 1) {
            let addToNew = true;
            [xPlans, zPlans].every((plans) => {
                plans.every((plan) => {
                    plan.indices.every((ind) => {
                        if (ind === i) {
                            addToNew = false;
                        }
                        return addToNew;
                    });
                    return addToNew;
                });
                return addToNew;
            });
            if (addToNew) {
                newIndices.push(indexes[i]);
            }
        }
        meshes.push(mesh);
        mesh.setIndices(newIndices);
        return meshes;
    },

    /* eslint-disable */
    /**
     * Copied and adapted from the original babylonjs obj serializer
     * @param {*} mesh
     */
    ToOBJ(mesh) {
        const output = [];
        let v = 1;
        for (let j = 0; j < mesh.length; j += 1) {
            output.push(`o ${mesh[j].name}`);
            // Uses the position of the item in the scene,
            // to the file(this back to normal in the end)
            // TODO: submeshes (groups)
            // TODO: smoothing groups (s 1, s off);
            const g = mesh[j].geometry;
            if (!g) {
                Tools.Warn('No geometry is present on the mesh');
                continue;
            }
            const trunkVerts = g.getVerticesData('position');
            const trunkNormals = g.getVerticesData('normal');
            const trunkUV = g.getVerticesData('uv');
            const trunkFaces = g.getIndices();
            let curV = 0;
            if (!trunkVerts || !trunkFaces) {
                Tools.Warn('There are no position vertices or indices on the mesh!');
                continue;
            }
            for (let i = 0; i < trunkVerts.length; i += 3) {
                output.push(`v ${trunkVerts[i]} ${trunkVerts[i + 1]} ${trunkVerts[i + 2]}`);
                curV++;
            }
            if (trunkNormals != null) {
                for (let i = 0; i < trunkNormals.length; i += 3) {
                    output.push(`vn ${trunkNormals[i]} ${trunkNormals[i + 1]} ${trunkNormals[i + 2]}`);
                }
            }
            if (trunkUV != null) {
                for (let i = 0; i < trunkUV.length; i += 2) {
                    output.push(`vt ${trunkUV[i]} ${trunkUV[i + 1]}`);
                }
            }
            for (let i = 0; i < trunkFaces.length; i += 3) {
                const indices = [String(trunkFaces[i + 2] + v), String(trunkFaces[i + 1] + v), String(trunkFaces[i] + v)];
                const blanks = ['', '', ''];
                const facePositions = indices;
                const faceUVs = trunkUV != null ? indices : blanks;
                const faceNormals = trunkNormals != null ? indices : blanks;
                output.push(
                    `f ${facePositions[0]}/${faceUVs[0]}/${faceNormals[0]} ${facePositions[1]}/${faceUVs[1]}/${
                        faceNormals[1]
                    } ${facePositions[2]}/${faceUVs[2]}/${faceNormals[2]}`,
                );
            }
            v += curV;
        }
        const text = output.join('\n');
        return text;
    },

    /**
     * Compute the snaps infos for all the given meshes
     * @param {Array<Mesh>} meshes
     */
    ComputeAllSnapsInfo(meshes) {
        const snapInfos = [];
        meshes.forEach((m) => {
            const { snapInfo } = this.ComputeSnapsInfo(m);
            snapInfos.push(snapInfo);
        });
        return snapInfos;
    },

    /**
     * Compute the snap info for a given mesh
     * @param {mesh} mesh
     * @param {Boolean} noWidthHeightSwitch (optional)
     */
    ComputeSnapsInfo(mesh) {
        const vertices = mesh.getVerticesData(VertexBuffer.PositionKind);

        const gC = this.GravityCenter(vertices);

        // We fetch the normal, and the vectors defining the face's plan
        const faceInfo = this.ComputeFacePlan(vertices);
        const faceSize = this.ComputeFaceSize(vertices, faceInfo);
        //console.log("gravity center :", gC);
        // console.log("face size center :", faceSize.center);

        if (faceInfo.normal.y === -1 && faceInfo.xPlan.x === -1) {
            faceInfo.xPlan.x = 1;
        }
        //quaternion defining the face rotation
        const quat = Quaternion.RotationQuaternionFromAxis(faceInfo.xPlan, faceInfo.yPlan, faceInfo.normal).normalize();
        if (faceSize.width > faceSize.height) {
            const t = faceSize.width;
            faceSize.width = faceSize.height;
            faceSize.height = t;
            quat.multiplyInPlace(Quaternion.RotationAxis(Vector3.Forward(), Math.PI / 2));
        }
        const snapInfo = {
            position: gC,
            quaternion: quat,
            width: faceSize.width,
            height: faceSize.height,
            normal: faceInfo.normal,
        };
        return { snapInfo, faceInfo };
    },

    /**
     * Map the hole correctly so holes on several face are aligned
     * Also merge the holed mesh into one
     * @param {*} meshes
     * @param {boolean} noHoleTopBot
     * @param {boolean} excludeCorners
     * @param {boolean} isL
     */
    MapHoles(meshes, noHoleTopBot = false, excludeCorners = false, isL = false) {
        // TODO : if the app takes to much time to load,
        // we could export those uv hole datas to new models
        // const timeStart = Date.now();
        const unindexed = meshes[0].isUnIndexed;
        let parent = meshes[0].parent;
        const meshData = [];
        const toMergeWithParent = [];
        const snapFacesInfos = [];
        const parentGravCenter = parent.getBoundingInfo().boundingBox.centerWorld;
        //Compute mesh infos
        meshes.forEach((m) => {
            const { snapInfo, faceInfo } = this.ComputeSnapsInfo(m);
            m.faceInfo = faceInfo;
            m.faceInfo.snap = snapInfo;
            if (excludeCorners) {
                m.gravityCenter = m.faceInfo.snap.position;
            }
        });
        meshes.forEach((m) => {
            const faceInfo = m.faceInfo;
            const normal = faceInfo.normal;
            let merged = false;
            let mergeWithParent = false;
            //Exclusion of top and bot faces if asked
            if (noHoleTopBot && faceInfo.normalUp === true) {
                toMergeWithParent.push(m);
                mergeWithParent = true;
            }
            //Exclude faces of corners for perfect corners
            if (excludeCorners) {
                if (
                    !meshes.every((m2) => {
                        if (m2 !== m) {
                            if (!faceInfo.normalUp && !m2.faceInfo.normalUp) {
                                const dot = Vector3.Dot(m.faceInfo.normal, m2.faceInfo.normal);
                                if (Math.abs(dot) < 0.0001) {
                                    let dist = Vector3.DistanceSquared(m2.gravityCenter, m.gravityCenter);
                                    if (dist < 0.002) {
                                        toMergeWithParent.push(m);
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    })
                ) {
                    mergeWithParent = true;
                }
            }

            if (mergeWithParent) {
                return;
            }
            // not merged with parent => actual face with holes
            // => We can use it's informations for snapping
            // We do that only if it is oriented outward
            if (Vector3.Dot(faceInfo.normal, faceInfo.snap.position.subtract(parentGravCenter)) > 0) {
                if (excludeCorners && faceInfo.normalUp) {
                    if (isL) {
                        snapFacesInfos.push(...this.SnapInfosForLFace(faceInfo));
                    } else {
                        const bbSize = m.getBoundingInfo().boundingBox.extendSize;
                        if (faceInfo.snap.width < faceInfo.snap.height) {
                            faceInfo.snap.width = bbSize.x * 2;
                            faceInfo.snap.height = bbSize.z * 2;
                        } else {
                            faceInfo.snap.width = bbSize.z * 2;
                            faceInfo.snap.height = bbSize.x * 2;
                        }
                        snapFacesInfos.push(...this.SnapInfosForUFace(faceInfo));
                    }
                } else {
                    snapFacesInfos.push(faceInfo.snap);
                }
            }

            // Merge aligned faces to create a coherent UvMapping
            for (let i = 0; i < meshData.length; i += 1) {
                const mD = meshData[i];
                if (Math.abs(Vector3.Dot(mD.normal, normal)) > 0.95) {
                    merged = true;
                    mD.mesh = Mesh.MergeMeshes([m, mD.mesh], true, true);
                    mD.mesh.parent = parent;
                    mD.mesh.computeWorldMatrix(true);
                    break;
                }
            }
            if (!merged) {
                meshData.push({
                    mesh: m,
                    xPlan: faceInfo.xPlan,
                    yPlan: faceInfo.yPlan,
                    normal,
                });
            }
        });
        // Apply plane uv projection on each face
        const meshesToMerge = [];
        meshData.forEach((mD) => {
            const m = mD.mesh;
            const vertices = m.getVerticesData(VertexBuffer.PositionKind);
            const uv = this.PlaneUVProjection(vertices, mD.xPlan, mD.yPlan, mD.normal, 0.062 * 2);
            m.setVerticesData(VertexBuffer.UVKind, uv, false);
            meshesToMerge.push(mD.mesh);
        });
        // Merge them to lower the number of meshes in the scene
        const finalMesh = Mesh.MergeMeshes(meshesToMerge, true, true);
        finalMesh.isUnIndexed = unindexed;
        finalMesh.name = `holed-${uuid()}`;
        //If meshes need to be merged with parent => do it
        if (toMergeWithParent.length > 0) {
            toMergeWithParent.push(parent);
            parent = Mesh.MergeMeshes(toMergeWithParent, true, true);
        }
        finalMesh.parent = parent;
        return {
            mainMesh: parent,
            snapFacesInfos: snapFacesInfos,
        };
    },

    /**
     * Extract snap info from face informations of an U shaped face object
     * @param {Object}  snapInfos
     * @param {Boolean} isL L or U
     * @return {Object[]}
     */
    SnapInfosForUFace(faceInfo, isL) {
        const snaps = [];
        snaps.push({
            height: faceInfo.snap.width - 4 * config.step,
            width: 2 * config.step,
            normal: faceInfo.snap.normal,
            quaternion: Quaternion.RotationYawPitchRoll(0, (-Math.sign(faceInfo.snap.normal.y) * Math.PI) / 2, Math.PI / 2),
            position: new Vector3(0, faceInfo.snap.position.y, -config.step),
        });

        const sideQuat = Quaternion.RotationYawPitchRoll(0, (-Math.sign(faceInfo.snap.normal.y) * Math.PI) / 2, 0);
        snaps.push({
            height: faceInfo.snap.height - 2 * config.step,
            width: 2 * config.step,
            normal: faceInfo.snap.normal,
            quaternion: sideQuat,
            position: new Vector3(
                faceInfo.snap.width * 0.5 - config.step,
                faceInfo.snap.position.y,
                -faceInfo.snap.height * 0.5 - config.step,
            ),
        });

        snaps.push({
            height: faceInfo.snap.height - 2 * config.step,
            width: 2 * config.step,
            normal: faceInfo.snap.normal,
            quaternion: sideQuat,
            position: new Vector3(
                -faceInfo.snap.width * 0.5 + config.step,
                faceInfo.snap.position.y,
                -faceInfo.snap.height * 0.5 - config.step,
            ),
        });

        return snaps;
    },

    /**
     * Extract snap info from face informations of L shaped face object
     * @param {Object}  snapInfos
     * @return {Object[]}
     */
    SnapInfosForLFace(faceInfo) {
        const snaps = [];
        snaps.push({
            height: faceInfo.snap.width - 2 * config.step,
            width: 2 * config.step,
            normal: faceInfo.snap.normal,
            quaternion: Quaternion.RotationYawPitchRoll(0, (-Math.sign(faceInfo.snap.normal.y) * Math.PI) / 2, Math.PI / 2),
            position: new Vector3(faceInfo.snap.width / 2 - config.step, faceInfo.snap.position.y, faceInfo.snap.width - config.step),
        });
        const sideQuat = Quaternion.RotationYawPitchRoll(0, (-Math.sign(faceInfo.snap.normal.y) * Math.PI) / 2, 0);
        snaps.push({
            height: faceInfo.snap.height - 2 * config.step,
            width: 2 * config.step,
            normal: faceInfo.snap.normal,
            quaternion: sideQuat,
            position: new Vector3(faceInfo.snap.width - config.step, faceInfo.snap.position.y, faceInfo.snap.width / 2 - config.step),
        });
        return snaps;
    },

    /**
     *
     * @param {Float32Array} positionArray
     * @returns {Vector3}
     */
    GravityCenter(positionArray) {
        let x = 0;
        let y = 0;
        let z = 0;
        for (let i = 0; i < positionArray.length; i += 3) {
            x += positionArray[i];
            y += positionArray[i + 1];
            z += positionArray[i + 2];
        }
        let nbPos = positionArray.length / 3;
        x /= nbPos;
        y /= nbPos;
        z /= nbPos;
        return new Vector3(x, y, z);
    },

    /**
     * Compute the normal, and the vectors defining the face's plan
     * @param {Float32Array vertices} faceMesh
     */
    ComputeFacePlan(vertices) {
        const epsilon = 0.1;
        const vn1 = new Vector3(vertices[0], vertices[1], vertices[2]);
        const vn2 = vn1.clone();
        vn1.subtractInPlace(new Vector3(vertices[3], vertices[4], vertices[5])).normalize();
        vn2.subtractInPlace(new Vector3(vertices[6], vertices[7], vertices[8])).normalize();
        const normal = Vector3.Cross(vn2, vn1).normalize();

        let xPlan;
        let yPlan;
        let normalUp = false;
        // Faces with not an upward normal

        if (Math.abs(normal.y) < epsilon) {
            yPlan = Vector3.Up();
            xPlan = Vector3.Cross(yPlan, normal).normalize();
        } else {
            // Faces with an upward normal : aligned on the X-Z plan

            // TODO SNAPPING UNDERSTAND THIS BORDEL
            // // I DON'T UNDERSTANNND !!!

            // if (!(normal.y > epsilon
            //     || (Math.sign(normal.x) === Math.sign(normal.z)))) {

            //     console.log("entry vertices :", vertices);
            //         console.log("check vertices buggy");
            //         console.log("normal :", normal);
            //     }
            if (normal.y > epsilon) {
                yPlan = Vector3.Forward();
                xPlan = Vector3.Left();
            } else if (normal.z === normal.x) {
                yPlan = Vector3.Forward();
                xPlan = Vector3.Right();
            } else {
                yPlan = Vector3.Backward();
                xPlan = Vector3.Left();
            }

            normalUp = true;
        }

        return { normal, xPlan, yPlan, normalUp };
    },

    /**
     *
     * @param {Float32Array} vertices
     * @param {*} planInfos
     */
    ComputeFaceSize(vertices, planInfos) {
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        let pointMinX = new Vector3();
        let pointMinY = new Vector3();
        let pointMaxX = new Vector3();
        let pointMaxY = new Vector3();
        for (let i = 0; i < vertices.length; i += 3) {
            const pos = new Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
            const projX = Vector3.Dot(pos, planInfos.xPlan);
            const projY = Vector3.Dot(pos, planInfos.yPlan);
            if (projX < minX) {
                minX = projX;
                pointMinX = pos;
            }
            if (projX > maxX) {
                maxX = projX;
                pointMaxX = pos;
            }
            if (projY < minY) {
                minY = projY;
                pointMinY = pos;
            }
            if (projY > maxY) {
                maxY = projY;
                pointMaxY = pos;
            }
        }
        const maxPoint = new Vector3(
            Math.max(pointMaxX.x, pointMaxY.x),
            Math.max(pointMaxX.y, pointMaxY.y),
            Math.max(pointMaxX.z, pointMaxY.z),
        );
        const minPoint = new Vector3(
            Math.min(pointMinX.x, pointMinY.x),
            Math.min(pointMinX.y, pointMinY.y),
            Math.min(pointMinX.z, pointMinY.z),
        );

        const boxSize = maxPoint.subtract(minPoint);
        const center = boxSize.scale(0.5);

        const width = maxX - minX;
        const height = maxY - minY;
        // const center = new Vector3(width /2, height /2, 0);
        // console.log("my center :", center);
        return { width, height, center };
    },

    // Takes a set of vertices that belong to the same plane (directed by X and Y) ,
    // and maps UV coordinates on it
    // U and V are colinear to X and Y respectively (so X and Y must be free)
    // textureSize may or may not be specified, so the UV matches 1 texture pixel = 1 world unit
    // if not specified, uvs are bounded between 0 and 1
    PlaneUVProjection(vertices, X, Y, normal, textureSize, uOffsetP = 1, vOffsetP = 0) {
        // We must find the matrix that will project in the local space
        let vector;
        let uOffset = uOffsetP;
        let vOffset = vOffsetP;
        const Us = [];
        const Vs = [];

        if (normal.length() < Engine.CollisionsEpsilon) {
            self.app.log.warn("Can't map UVs : basis vectors are linked");
            return null;
        }

        if (vertices.length === 0) {
            return null;
        }

        let localMatrix = Matrix.FromValues(X.x, Y.x, normal.x, 0, X.y, Y.y, normal.y, 0, X.z, Y.z, normal.z, 0, 0, 0, 0, 1);
        localMatrix = Matrix.Transpose(localMatrix);
        localMatrix.invert();

        // Project the vertices in the new local UV base
        // We must drop the Z coordinate to project on the UV plane
        for (let i = 0, il = vertices.length; i < il; i += 3) {
            vector = new Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
            Vector3.TransformCoordinatesToRef(vector, localMatrix, vector);
            Us.push(vector.x);
            Vs.push(vector.y);
        }

        // Let's scale so U and V are between 0 and 1
        let minU = Us[0];
        let maxU = Us[0];

        let minV = Vs[0];
        let maxV = Vs[0];

        for (let i = 0, il = Us.length; i < il; i += 1) {
            if (Us[i] < minU) minU = Us[i];
            if (Us[i] > maxU) maxU = Us[i];
            if (Vs[i] < minV) minV = Vs[i];
            if (Vs[i] > maxV) maxV = Vs[i];
        }

        // Texture repetition
        let uRatio;

        let vRatio;
        if (textureSize && textureSize !== -1) {
            uRatio = ((maxU - minU) / textureSize) * 2;
            vRatio = ((maxV - minV) / textureSize) * 2;
        } else if (textureSize !== -1) {
            uRatio = 2;
            vRatio = 2;
        } else {
            uRatio = 1;
            vRatio = 1;
        }

        if (maxU === minU) {
            for (let i = 0, il = Us.length; i < il; i += 1) {
                Us[i] = 0;
            }
        } else {
            for (let i = 0, il = Us.length; i < il; i += 1) {
                Us[i] = ((Us[i] - minU) / (maxU - minU)) * uRatio;
            }
        }

        if (maxV === minV) {
            for (let i = 0, il = Vs.length; i < il; i += 1) {
                Vs[i] = 0;
            }
        } else {
            for (let i = 0, il = Vs.length; i < il; i += 1) {
                Vs[i] = ((Vs[i] - minV) / (maxV - minV)) * vRatio;
            }
        }
        uOffset *= uRatio;
        vOffset *= vRatio;

        // Now let's concatenate U and V so it matches a BABYLON UV buffer
        const UV = [];
        for (let i = 0, il = Us.length; i < il; i += 1) {
            UV.push(Us[i] + uOffset, Vs[i] + vOffset);
        }

        return UV;
    },

    /**
     * Generate an OBB out of the each child meshes
     * with a name containing "Collider"
     * @param {Mesh[]} meshes
     */
    GenerateSubBoundingBox(mesh) {
        const vertices = [];
        const verticesFloats = mesh.getVerticesData(VertexBuffer.PositionKind, false, true);
        for (let i = 0; i < verticesFloats.length; i += 3) {
            // Generate a Vector3 vertice
            const vec = new Vector3(verticesFloats[i], verticesFloats[i + 1], verticesFloats[i + 2]);
            // Add it once
            if (!vertices.find((v) => vec.x === v.x && vec.y === v.y && vec.z === v.z)) {
                vertices.push(vec.clone());
            }
        }
        return vertices;
    },

    /**
     * Clone a mesh by taking account of properties that should not be cleaned
     * Necessary cause Babylon v3.3 clone those properties but it should not
     * Will be fixed in Babylon V4
     * @param {*} mesh
     */
    CleanClone(mesh) {
        const propertiesToRemove = [
            'onAfterWorldMatrixUpdateObservable',
            'onCollideObservable',
            'onCollisionPositionChangeObservable',
            'onRebuildObservable',
            'onDisposeObservable',
        ];
        const clone = mesh.clone(uuid());
        propertiesToRemove.forEach((property) => {
            if (clone[property] && clone[property]._observers.length) {
                clone[property]._observers = [];
                clone.getChildMeshes().forEach((child) => {
                    child[property]._observers = [];
                });
            }
        });
        return clone;
    },

    /**
     * Copy a given list of subColliders
     * @param {*} subCollidersBB
     */
    CloneSubColliders(subCollidersBB) {
        const collidersCopy = [];
        subCollidersBB.forEach((subCollider) => {
            const subCopy = [];
            subCollider.forEach((vec) => {
                subCopy.push(vec.clone());
            });
            collidersCopy.push(subCopy);
        });
        return collidersCopy;
    },

    /**
     * return the bounding sphere of the mesh and its children
     *
     * @param {Mesh} mesh
     */
    getBoundingSphere(mesh) {
        mesh.computeWorldMatrix(true);
        mesh.refreshBoundingInfo();
        const bs = mesh.getBoundingInfo().boundingSphere;
        const children = mesh.getChildMeshes(true);
        children.forEach((child) => {
            child.computeWorldMatrix(true);
            child.refreshBoundingInfo();
            const childBS = this.getBoundingSphere(child);
            bs.centerWorld = Vector3.Center(bs.centerWorld, childBS.centerWorld);
            bs.radius = Math.max(bs.radiusWorld, childBS.radiusWorld); // TODO : recalculate the radius, because this work only if the center is the same
        });
        return bs;
    },

    /**
     * Return the mesh bounding merged with children ones
     */
    GetBoundingBox(mesh) {
        mesh.computeWorldMatrix(true);
        mesh.refreshBoundingInfo();
        const bb = mesh.getBoundingInfo().boundingBox;

        // Recursively goes through each child to include their BBs in the global one
        const updateBBWithChildrenBBs = (meshes) => {
            meshes.forEach((child) => {
                if (child.metadata && child.metadata.isOption) {
                    return;
                }
                const children = child.getChildMeshes(true);
                updateBBWithChildrenBBs(children);

                // Update child
                child.computeWorldMatrix(true);
                child.refreshBoundingInfo();

                this.UpdateBBMinMax(child.getBoundingInfo().boundingBox, bb);
            });
        };

        updateBBWithChildrenBBs(mesh.getChildMeshes(true));

        // Update world / local centers
        bb.centerWorld = Vector3.Center(bb.minimumWorld, bb.maximumWorld);
        bb.center = Vector3.Center(bb.minimum, bb.maximum);

        // Compute the bounding box points in world space
        bb.vectorsWorld[0].copyFromFloats(bb.minimumWorld.x, bb.minimumWorld.y, bb.minimumWorld.z);
        bb.vectorsWorld[1].copyFromFloats(bb.maximumWorld.x, bb.maximumWorld.y, bb.maximumWorld.z);
        bb.vectorsWorld[2].copyFromFloats(bb.maximumWorld.x, bb.minimumWorld.y, bb.minimumWorld.z);
        bb.vectorsWorld[3].copyFromFloats(bb.minimumWorld.x, bb.maximumWorld.y, bb.minimumWorld.z);
        bb.vectorsWorld[4].copyFromFloats(bb.minimumWorld.x, bb.minimumWorld.y, bb.maximumWorld.z);
        bb.vectorsWorld[5].copyFromFloats(bb.maximumWorld.x, bb.maximumWorld.y, bb.minimumWorld.z);
        bb.vectorsWorld[6].copyFromFloats(bb.minimumWorld.x, bb.maximumWorld.y, bb.maximumWorld.z);
        bb.vectorsWorld[7].copyFromFloats(bb.maximumWorld.x, bb.minimumWorld.y, bb.maximumWorld.z);

        // Compute the bounding box points in local space
        bb.vectors[0].copyFromFloats(bb.minimum.x, bb.minimum.y, bb.minimum.z);
        bb.vectors[1].copyFromFloats(bb.maximum.x, bb.maximum.y, bb.maximum.z);
        bb.vectors[2].copyFromFloats(bb.maximum.x, bb.minimum.y, bb.minimum.z);
        bb.vectors[3].copyFromFloats(bb.minimum.x, bb.maximum.y, bb.minimum.z);
        bb.vectors[4].copyFromFloats(bb.minimum.x, bb.minimum.y, bb.maximum.z);
        bb.vectors[5].copyFromFloats(bb.maximum.x, bb.maximum.y, bb.minimum.z);
        bb.vectors[6].copyFromFloats(bb.minimum.x, bb.maximum.y, bb.maximum.z);
        bb.vectors[7].copyFromFloats(bb.maximum.x, bb.minimum.y, bb.maximum.z);
        return bb;
    },

    /**
     * Update the referenced BB bounding box min and max in world and local space
     * to make it include the newBB
     */
    UpdateBBMinMax(newBB, refBB) {
        // World maximum / minimum
        if (newBB.maximumWorld.x > refBB.maximumWorld.x) {
            refBB.maximumWorld.x = newBB.maximumWorld.x;
        }
        if (newBB.maximumWorld.y > refBB.maximumWorld.y) {
            refBB.maximumWorld.y = newBB.maximumWorld.y;
        }
        if (newBB.maximumWorld.z > refBB.maximumWorld.z) {
            refBB.maximumWorld.z = newBB.maximumWorld.z;
        }
        if (newBB.minimumWorld.x < refBB.minimumWorld.x) {
            refBB.minimumWorld.x = newBB.minimumWorld.x;
        }
        if (newBB.minimumWorld.y < refBB.minimumWorld.y) {
            refBB.minimumWorld.y = newBB.minimumWorld.y;
        }
        if (newBB.minimumWorld.z < refBB.minimumWorld.z) {
            refBB.minimumWorld.z = newBB.minimumWorld.z;
        }

        // Local maximum / minimum
        if (newBB.maximum.x > refBB.maximum.x) {
            refBB.maximum.x = newBB.maximum.x;
        }
        if (newBB.maximum.y > refBB.maximum.y) {
            refBB.maximum.y = newBB.maximum.y;
        }
        if (newBB.maximum.z > refBB.maximum.z) {
            refBB.maximum.z = newBB.maximum.z;
        }
        if (newBB.minimum.x < refBB.minimum.x) {
            refBB.minimum.x = newBB.minimum.x;
        }
        if (newBB.minimum.y < refBB.minimum.y) {
            refBB.minimum.y = newBB.minimum.y;
        }
        if (newBB.minimum.z < refBB.minimum.z) {
            refBB.minimum.z = newBB.minimum.z;
        }

        // World extends
        if (newBB.extendSizeWorld.x > refBB.extendSizeWorld.x) {
            refBB.extendSizeWorld.x = newBB.extendSizeWorld.x;
        }
        if (newBB.extendSizeWorld.y > refBB.extendSizeWorld.y) {
            refBB.extendSizeWorld.y = newBB.extendSizeWorld.y;
        }
        if (newBB.extendSizeWorld.z > refBB.extendSizeWorld.z) {
            refBB.extendSizeWorld.z = newBB.extendSizeWorld.z;
        }

        // Local extends
        if (newBB.extendSize.x > refBB.extendSize.x) {
            refBB.extendSize.x = newBB.extendSize.x;
        }
        if (newBB.extendSize.y > refBB.extendSize.y) {
            refBB.extendSize.y = newBB.extendSize.y;
        }
        if (newBB.extendSize.z > refBB.extendSize.z) {
            refBB.extendSize.z = newBB.extendSize.z;
        }
    },

    ComputeNewBb(newBB, refBB) {
        let minPoint = Vector3.Minimize(newBB.minimumWorld, refBB.minimumWorld);
        let maxPoint = Vector3.Maximize(newBB.maximumWorld, refBB.maximumWorld);
        return new BoundingBox(minPoint, maxPoint);
    },

    ComputeMeshListBB(meshList) {
        // Compute the global bounding box
        meshList.forEach((mesh) => {
            mesh.computeWorldMatrix(true);
        });
        const minPoint = meshList[0].getBoundingInfo().boundingBox.minimumWorld.clone();
        const maxPoint = meshList[0].getBoundingInfo().boundingBox.maximumWorld.clone();

        let bb = new BoundingBox(minPoint, maxPoint);
        for (let i = 1; i < meshList.length; i += 1) {
            const currentBb = meshList[i].getBoundingInfo().boundingBox;
            bb = this.ComputeNewBb(bb, currentBb);
        }
        return bb;
    },

    /**
     * Return ultimate parent of the given mesh
     * @param {Mesh} mesh
     */
    GetRootMesh(mesh) {
        let rootMesh = mesh;
        while (rootMesh.parent) {
            rootMesh = rootMesh.parent;
        }
        return rootMesh;
    },

    /**
     * A safe function to add properties to the metadata object of a mesh
     * This function do an object assign so all proerties all ready existing will be kept, but their values could get overriden
     * @param {Mesh} mesh mesh to add the property to
     * @param {Object} objectToMerge the objet to merge the metadata with
     */
    AddMetadataProperties(mesh, objectToMerge) {
        mesh.metadata = Object.assign(mesh.metadata || {}, objectToMerge);
    },
};
