<script lang="ts">
    import PdfHtmlContent from './pdf-mode/pdf-html-content.vue';
    import self from '../../index';
    import RightsHelper from '../../../../helpers/rights-helper';
    import config from 'defaultConfig';

    const {
        app: {
            modules: {
                obsidianEngine: { controller: engineController },
                cameraManager: { controller: cameraController },
                buildingPlanManager: { buildingPlanController: bpController },
            },
            events,
        },
    } = self;

    export default {
        name: 'ObsidianCanvas',
        components: {
            PdfHtmlContent,
        },
        props: {
            isCanvasReady: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                // In mm, landscape orientation
                canvasWidth: 640,
                canvasHeight: 480,
                a4Height: 210,
                a4Width: 297,
                config,
            };
        },
        watch: {
            config: {
                handler() {
                    this.reajustCanvas();
                },
                deep: true,
            },
        },
        mounted() {
            // This avoid bad resizing of canvas when the ui load and the window is not focused
            window.addEventListener('focus', () => {
                this.reajustCanvas();
            });
            window.addEventListener('resize', () => {
                this.reajustCanvas();
            });

            this.reajustCanvas();

            const engineCallback = () => {
                engineController.engine.resize();
                cameraController.adaptOrthoSizeToCanvas();
            };

            if (engineController.engine) {
                engineCallback();
            } else {
                events.once('@obsidian-engine.engine-ready', engineCallback);
            }
        },
        methods: {
            reajustCanvas() {
                setTimeout(() => {
                    const canvasContainer = document.getElementById('canvas-container');
                    const canvasWidth = Math.ceil(canvasContainer.clientWidth);
                    const canvasHeight = Math.ceil(canvasContainer.clientHeight);

                    this.$refs.canvas3D.width = canvasWidth;
                    this.$refs.canvas3D.height = canvasHeight;

                    engineController.engine.resize();
                    cameraController.adaptOrthoSizeToCanvas();
                    events.emit('canvas-resized', { width: canvasWidth, height: canvasHeight });
                }, 0);
            },

            validateEventPropagation(e) {
                if (RightsHelper.isModeBuildingPlan() && bpController.isCameraFixed) {
                    e.stopImmediatePropagation();
                }
            },
        },
    };
</script>

<template>
    <div id="canvas-container">
        <canvas
            id="main-canvas"
            ref="canvas3D"
            width="100%"
            height="100%"
            @dblclick="validateEventPropagation($event)"
        />
        <pdf-html-content v-if="isCanvasReady" />
    </div>
</template>
