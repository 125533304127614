import config from '../default-config';

export default {
    // Mode helper functions
    isModeDemo() {
        return config.mode === 'demo';
    },

    isModePublic() {
        return config.mode === 'public';
    },

    isMode3dAuthenticated() {
        return config.mode === '3d-authenticated';
    },

    isModeBuildingPlan() {
        return config.mode === 'building-plan';
    },

    // Roles helper
    isRoleAdminBematrix() {
        return config.roles.includes('admin_bematrix');
    },

    isRoleDesigner() {
        return config.roles.includes('designer');
    },

    isRoleFinance() {
        return config.roles.includes('finance');
    },

    // Plan helper
    isPlanBasic() {
        return config.plans.includes('basic');
    },

    isPlanEssential() {
        return config.plans.includes('essential');
    },

    hasForgeRole() {
        return config.roles.includes('forge');
    },

    // Rights setters
    toggleBuildingPlanMode() {
        if (this.isModeBuildingPlan()) {
            config.mode = '3d-authenticated';
        } else {
            config.mode = 'building-plan';
        }
    },
};
