import { Tools, Geometry, VertexData, BoundingInfo, Material, Matrix, Mesh, Scene } from '@babylonjs/core';
// import { OBJExport } from '@babylonjs/serializers';

import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';

const ObjHelper = {
    tempMeshes: [],

    scale: 1000,

    exportMaterial: null,

    copyMeshForOBJ(mesh, exportScene) {
        const copy = new Mesh(mesh.name, exportScene);
        if (!this.exportMaterial) {
            this.exportMaterial = new Material('exportMaterial');
        }
        copy.material = this.exportMaterial;
        mesh.geometry.applyToMesh(copy);
        copy.makeGeometryUnique();
        copy.parent = mesh.parent;
        if (mesh.rotationQuaternion) {
            copy.rotationQuaternion = mesh.rotationQuaternion.clone();
        } else {
            copy.rotation = mesh.rotation.clone();
        }
        copy.scaling = mesh.scaling.scale(this.scale);
        copy.setAbsolutePosition(mesh.absolutePosition.clone().scale(this.scale));
        copy.computeWorldMatrix(true);
        copy.sideOrientation = Material.ClockWiseSideOrientation;
        copy.bakeCurrentTransformIntoVertices();
        copy.parent = null;
        return copy;
    },

    /**
     * mesh.sideOrientation exist on from babylonjs 7.11.0. I used it to fix the issue with the normals and for the future babylonjs upgrade
     * @see https://doc.babylonjs.com/breaking-changes#7110
     */
    exportToObj({ meshes, engine, directDownload = false, exportDescendants = true }) {
        const sceneForExport = new Scene(engine);
        meshes.forEach((mesh) => {
            const copy = this.copyMeshForOBJ(mesh, sceneForExport);
            this.tempMeshes.push(copy);
            if (exportDescendants) {
                const children = mesh.getChildMeshes(false);
                children.forEach((childMesh) => {
                    this.tempMeshes.push(this.copyMeshForOBJ(childMesh, sceneForExport));
                });
            }
        });

        const obj = ObjHelper.meshToObj(this.tempMeshes);

        if (directDownload) {
            const blob = new Blob([obj], { type: 'text/plain' });
            saveAs(blob, 'scene.obj');
        }

        this.tempMeshes.forEach((mesh) => {
            mesh.dispose();
        });

        sceneForExport.dispose();

        this.tempMeshes = [];
        return obj;
    },
    /**
     * Code come from BabylonJS OBJExport class version 7.34.4
     * Some parts are modified to fit the needs of the project
     * @param {[Mesh]} meshes
     * @param {Boolean} globalposition
     * @returns
     */
    meshToObj(meshes, globalposition = true) {
        const output = [];
        let v = 1;
        // keep track of uv index in case mixed meshes are passed in
        let textureV = 1;

        for (let j = 0; j < meshes.length; j++) {
            const mesh = meshes[j];
            const objectName = mesh.name || `mesh${j}}`;
            output.push(`o ${objectName}`);

            // Uses the position of the item in the scene, to the file (this back to normal in the end)
            let inverseTransform = null;
            if (globalposition) {
                const transform = mesh.computeWorldMatrix(true);
                inverseTransform = new Matrix();
                transform.invertToRef(inverseTransform);

                mesh.bakeTransformIntoVertices(transform);
            }

            const g = mesh.geometry;

            if (!g) {
                Tools.Warn('No geometry is present on the mesh');
                // eslint-disable-next-line no-continue
                continue;
            }

            const trunkVerts = g.getVerticesData('position');
            const trunkNormals = g.getVerticesData('normal');
            const trunkUV = g.getVerticesData('uv');
            const trunkFaces = g.getIndices();
            let currentV = 0;
            let currentTextureV = 0;

            if (!trunkVerts || !trunkFaces) {
                Tools.Warn('There are no position vertices or indices on the mesh!');
                // eslint-disable-next-line no-continue
                continue;
            }

            const { useRightHandedSystem } = meshes[0].getScene();
            const handednessSign = useRightHandedSystem ? 1 : -1;

            for (let i = 0; i < trunkVerts.length; i += 3) {
                output.push(
                    `v ${trunkVerts[i] * handednessSign * this.scale} ${trunkVerts[i + 1] * this.scale} ${trunkVerts[i + 2] * this.scale}`,
                );
                currentV++;
            }

            if (trunkNormals != null) {
                for (let i = 0; i < trunkNormals.length; i += 3) {
                    output.push(`vn ${trunkNormals[i] * handednessSign} ${trunkNormals[i + 1]} ${trunkNormals[i + 2]}`);
                }
            }
            if (trunkUV != null) {
                for (let i = 0; i < trunkUV.length; i += 2) {
                    output.push(`vt ${trunkUV[i]} ${trunkUV[i + 1]}`);
                    currentTextureV++;
                }
            }

            const blanks = ['', '', ''];
            const { sideOrientation } = mesh;
            const [offset1, offset2] = sideOrientation === Material.ClockWiseSideOrientation ? [2, 1] : [1, 2];

            for (let i = 0; i < trunkFaces.length; i += 3) {
                const indices = [String(trunkFaces[i] + v), String(trunkFaces[i + offset1] + v), String(trunkFaces[i + offset2] + v)];
                const textureIndices = [
                    String(trunkFaces[i] + textureV),
                    String(trunkFaces[i + offset1] + textureV),
                    String(trunkFaces[i + offset2] + textureV),
                ];

                const facePositions = indices;
                const faceUVs = trunkUV != null ? textureIndices : blanks;
                const faceNormals = trunkNormals != null ? indices : blanks;

                output.push(
                    `f ${facePositions[0]}/${faceUVs[0]}/${faceNormals[0]} ${facePositions[1]}/${faceUVs[1]}/${faceNormals[1]} ${
                        facePositions[2]
                    }/${faceUVs[2]}/${faceNormals[2]}`,
                );
            }
            // back de previous matrix, to not change the original mesh in the scene
            if (globalposition && inverseTransform) {
                mesh.bakeTransformIntoVertices(inverseTransform);
            }
            v += currentV;
            textureV += currentTextureV;
        }
        const text = output.join('\n');
        return text;
    },

    /**
     * Clone the current geometry into a new geometry
     * It's the same as the clone function of Geometry
     * but it includes this fix : https://github.com/BabylonJS/Babylon.js/pull/5643
     *
     * @param id defines the unique ID of the new geometry
     * @returns a new geometry object
     */
    copyGeometry(baseGeom) {
        const vertexData = new VertexData();
        vertexData.indices = [];
        const indices = baseGeom.getIndices();
        if (indices) {
            for (let index = 0; index < indices.length; index++) {
                vertexData.indices.push(indices[index]);
            }
        }
        let updatable = false;
        let stopChecking = false;
        Object.keys(baseGeom._vertexBuffers).forEach((kind) => {
            // using slice() to make a copy of the array and not just reference it
            const data = baseGeom.getVerticesData(kind);
            if (data) {
                if (data instanceof Float32Array) {
                    vertexData.set(new Float32Array(data), kind);
                } else {
                    vertexData.set(data.slice(0), kind);
                }
                if (!stopChecking) {
                    const vb = baseGeom.getVertexBuffer(kind);

                    if (vb) {
                        updatable = vb.isUpdatable();
                        stopChecking = !updatable;
                    }
                }
            }
        });
        const geometry = new Geometry(uuid(), baseGeom._scene, vertexData, updatable);
        geometry.delayLoadState = baseGeom.delayLoadState;
        geometry.delayLoadingFile = baseGeom.delayLoadingFile;
        geometry._delayLoadingFunction = baseGeom._delayLoadingFunction;
        let kind;
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (kind in baseGeom._delayInfo) {
            geometry._delayInfo = geometry._delayInfo || [];
            geometry._delayInfo.push(kind);
        }
        // Bounding info
        geometry._boundingInfo = new BoundingInfo(baseGeom._extend.minimum, baseGeom._extend.maximum);
        return geometry;
    },
};

export default ObjHelper;
