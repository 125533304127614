export default {
    name: 'measurement-manager',
    requires: ['obsidian-engine', 'gui-manager', 'material-manager', 'highlight-manager', 'data-store', 'history'],
    load() {
        const MeasurementController = require('./src/controllers/measurement-controller').default;
        return {
            Controller: new MeasurementController(),
            MeasurableMixin: require('./src/models/measurable-mixin'),
        };
    },
    unload() {},
};
