export default {
    name: 'obsidian-engine',
    requires: ['history'],
    load() {
        const Controller = require('./src/controllers/engine-controller').default;
        const Helper = require('./src/helpers/engine-helper');

        return {
            controller: new Controller(),
            helper: Helper,
        };
    },
    unload() {},
};
