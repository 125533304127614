import self from '../..';
import { Camera, ArcRotateCamera, Vector3 } from '@babylonjs/core';

const TWEEN = require('@tweenjs/tween.js');

const CameraRadiusFromGround = 7.5;

const {
    modules: {
        obsidianEngine: { controller: engineController, helper: engineHelper },
    },
    events,
    log,
} = self.app;

/**
 * A enum which contains different views used for the camera.
 */
export const CameraViews = {
    DEFAULT_VIEW: {
        alpha: 1,
        beta: 1,
        radius: 20,
        name: 'DEFAULT_VIEW',
    },
    TOP_VIEW: {
        alpha: 0,
        beta: 0,
        radius: 20,
        name: 'TOP_VIEW',
    },
    SIDE_VIEW: {
        alpha: Math.PI / 2,
        beta: 1.4671335375312806,
        radius: 10,
        name: 'SIDE_VIEW',
    },
    FRONT_VIEW: {
        alpha: 0,
        beta: 1.4671335375312806,
        radius: 10,
        name: 'FRONT_VIEW',
    },
    EDIT_VIEW: {
        alpha: 0.004288646133055362,
        beta: 1.0804457306903368,
        radius: 19.960545122601108,
        name: 'EDIT_VIEW',
    },
    OBJECT_VIEW: {
        alpha: 1,
        beta: 1,
        radius: 2,
    },
};

/**
 * The controller which manage the camera and give its information
 */

export default class CameraManager {
    constructor() {
        this.cameras = [];
        this.currentCameraId = 0;
        this.currentTween = null;
        this.orthographic = false;
        this.orthoZoomStart = 5;
        this.orthoZoomPower = 0.01;
        this.orthoZoomLowerLimit = 2;
        this.activeCameraSet = false;
        this.initialPosition = Vector3.Zero();

        this.vueData = {
            orthographic: this.orthographic,
        };

        if (engineController.ready) {
            this.onEngineReady(engineController.scene);
        } else {
            events.on('@obsidian-engine.engine-ready', (scene) => {
                this.onEngineReady(scene);
            });
        }

        events.on('@obsidian-engine.animate', () => {
            this.orbit();
        });

        events.on('@obsidian-engine.double-click', () => {
            this.zoomCamera();
        });
    }

    /**
     * Called when the event "engine-ready" is emitted.
     */
    onEngineReady(scene) {
        this.scene = scene;
        this.canvas = engineController.canvas;
        this.mouseEventHandler = engineController.eventHandler.mouse;
        this.initCamera();
    }

    /**
     * Initialize the camera on the scene by giving it a default view
     * Default camera is an arc rotate camera
     * There's also a free camera initialized
     */
    initCamera() {
        // Parameters: alpha, beta, radius, target position, scene
        const camera = new ArcRotateCamera('ArcRotateCamera', 1, 1, 20, new Vector3(0, 0, 0), this.scene);

        this.scene.addCamera(camera);
        this.scene.setActiveCameraByID(camera.id);

        // Attach the camera to the canvas.
        this.orbiting = false;
        camera.attachControl(this.canvas, true);
        camera.setTarget(new Vector3(0, 0, 0));
        this.cameras.push(camera);
        this.currentCameraId = this.cameras.indexOf(camera);
        this.changeView(CameraViews.DEFAULT_VIEW);
        this.setupCameraZoomValues();

        // Callback to be called on scroll when the camera is in ortho mode : zoom/unzoom
        this.orthoZoomCallback = (event) => {
            this.orthoZoomStart = Math.max(this.orthoZoomStart + this.orthoZoomPower * event.deltaY, this.orthoZoomLowerLimit);
            this.adaptOrthoSizeToCanvas();
        };
        // Adapt the ortho camera to the screen ratio
        this.adaptOrthoSizeToCanvas();
        this.activeCameraSet = true;
        this.initialPosition = camera.position.clone();
        events.emit('camera-ready');
    }

    /**
     * Increment the current camera index (do nothing if only one camera available)
     */
    changeCamera() {
        const index = (this.currentCameraId + 1) % this.cameras.length;
        const camera = this.cameras[index];
        this.scene.setActiveCameraByID(camera.id);
        this.currentCameraId = index;
    }

    /**
     * Careful this function only works with an arcRotateCamera !
     * This function change the view with an animation
     * It's different than set wiew which only change the values without doing
     * a tweening
     * @param {alpha, beta} view
     */
    changeView(view, callback = null) {
        if (this.currentTween) {
            this.currentTween.stop();
        }

        this.currentCamera.alpha %= 2 * Math.PI;
        this.currentCamera.beta %= 2 * Math.PI;

        const animationEndState = {
            alpha: view.alpha % (Math.PI * 2),
            beta: view.beta % (Math.PI * 2),
            radius: view.radius,
        };

        if (view.target) {
            this.currentTween = new TWEEN.Tween(this.currentCamera.target).to(view.target, 700).easing(TWEEN.Easing.Quadratic.Out).start();
        }

        this.currentTween = new TWEEN.Tween(this.currentCamera)
            .to(animationEndState, 700)
            .onComplete(() => {
                if (typeof callback === 'function') {
                    callback();
                }
            })
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    /**
     * Called when we are initializing a mesh by drag and dropping but the camera zoomed too much
     * @param {function} callback
     */
    reajustCamera(callback) {
        if (this.currentTween) {
            this.currentTween.stop();
        }
        this.cameras[this.currentCameraId].beta %= 2 * Math.PI;
        this.currentTween = new TWEEN.Tween(this.cameras[this.currentCameraId])
            .to(
                {
                    beta: this.cameras[this.currentCameraId].beta - Math.PI / 6,
                },
                400,
            )
            .onComplete(() => {
                callback();
            })
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();
    }

    /**
     * Set the current arc rotate camera view to given 'view' parameter
     * /!\ ONLY FOR ARCROTATECAMERA
     * @param {Object} view - The view given to the current arcRotateCamera
     * @param {number} view.alpha - The alpha value of the arcRotateCamera in radian
     * @param {number} view.beta - The beta value of the arcRotateCamera in radian
     * @param {number} view.radius - The distance between the camera and the current target
     */
    setView(view) {
        this.cameras[this.currentCameraId].alpha = view.alpha;
        this.cameras[this.currentCameraId].beta = view.beta;
        this.cameras[this.currentCameraId].radius = view.radius;
    }

    /**
     * Change between perspective camera and orthographic camera.
     */
    changeMode() {
        if (this.orthographic) {
            if (!this.currentCamera.inputs.attached.mousewheel) {
                this.currentCamera.inputs.addMouseWheel();
            }

            this.disableOrthoZoom();
            this.setupCameraZoomValues();
            this.cameras[this.currentCameraId].mode = Camera.PERSPECTIVE_CAMERA;
            this.orthographic = false;
            this.vueData.orthographic = false;
            return;
        }
        this.currentCamera.inputs.removeByType('ArcRotateCameraMouseWheelInput');
        this.enableOrthoZoom();
        this.cameras[this.currentCameraId].mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.orthographic = true;
        this.vueData.orthographic = true;
    }

    /**
     * Enable the zoom for orthographic camera
     */
    enableOrthoZoom() {
        this.canvas.addEventListener('wheel', this.orthoZoomCallback);
    }

    /**
     * Disable the zoom for orthographic camera
     */
    disableOrthoZoom() {
        this.canvas.removeEventListener('wheel', this.orthoZoomCallback);
    }

    /**
     * Adapt the size of the orthographic camera to the canvas
     */
    adaptOrthoSizeToCanvas() {
        const ratio = this.canvas.width / this.canvas.height;
        this.cameras[this.currentCameraId].orthoTop = this.orthoZoomStart;
        this.cameras[this.currentCameraId].orthoBottom = -this.orthoZoomStart;
        this.cameras[this.currentCameraId].orthoLeft = -this.orthoZoomStart * ratio;
        this.cameras[this.currentCameraId].orthoRight = this.orthoZoomStart * ratio;
    }

    /**
     * Add a new camera to the camera array of the controller
     * @param {Camera} camera - The camera to add in the camera array
     */
    addCamera(camera) {
        this.cameras.push(camera);
        this.scene.addCamera(camera);
    }

    /**
     * Remove a camera from the camera array of the controller
     * @param {Camera} camera - The camera to remove
     */
    removeCamera(camera) {
        const index = this.cameras.indexOf(camera);
        if (index < 0) {
            log.warn('Camera not found in manager');
            return;
        }
        this.cameras.splice(index, 1);
    }

    /**
     * Change the target of the current camera
     * /!\ ONLY AVAILABLE FOR ARCROTATECAMERA
     * @param {Vector3} target - The new target to set
     */
    lookAt(target) {
        this.cameras[this.currentCameraId].setTarget(target);
    }

    get currentCamera() {
        return this.cameras[this.currentCameraId];
    }

    getCameraInfos() {
        const camera = this.getCurrentCamera();
        return camera;
    }

    /**
     * Change the value of this.orbiting to true or false
     * and change the view of the camera.
     */
    updateOrbiting() {
        if (!this.orbiting) {
            this.changeView(CameraViews.EDIT_VIEW);
        }
        this.orbiting = !this.orbiting;
    }

    /**
     * Called in the main loop,
     * makes the camera turn around the "focus point" while this.orbiting is true
     */
    orbit() {
        if (!this.orbiting) {
            return;
        }
        this.cameras[this.currentCameraId].alpha = (this.cameras[this.currentCameraId].alpha % (2 * Math.PI)) + 0.005;
    }

    activateCamera(bool) {
        if (!bool) {
            this.scene.activeCamera.detachControl(this.canvas);
        } else {
            this.scene.activeCamera.attachControl(this.canvas, false);
        }
    }

    getCameraName() {
        if (this.cameras[this.currentCameraId].name === 'camera') {
            return 'Rotate';
        }
        return 'Free';
    }

    // DEBUG FUNCTIONS
    /**
     * Used to set the view of the current camera to a hardcoded value
     */
    setDebugView() {
        // Change this if you want another view for debug
        this.setView({
            alpha: 2.456342689680064,
            beta: 1.0583719473189026,
            radius: 11.039047840927513,
        });
    }

    /**
     * Log the current view of the current camera
     */
    debugCurrentCamera() {
        const infos = {
            alpha: this.cameras[this.currentCameraId].alpha,
            beta: this.cameras[this.currentCameraId].beta,
            radius: this.cameras[this.currentCameraId].radius,
        };
        log.info('current camera infos', infos);
    }

    /**
     * @param {BABYLON.Mesh[]} meshList
     * @returns {boolean}
     */
    isMeshListInFrustum(meshList) {
        return meshList.every((mesh) => {
            if (mesh.computeWorldMatrix) {
                mesh.computeWorldMatrix(true);
                return this.currentCamera.isCompletelyInFrustum(mesh);
            }
            return true;
        });
    }

    zoomCamera() {
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

        if (pickResult.hit && pickResult.pickedMesh) {
            let toRadius = this.currentCamera.lowerRadiusLimit;

            if (engineHelper.HasPickedGround(pickResult)) {
                toRadius = CameraRadiusFromGround;
            }

            this.tweenRadius = new TWEEN.Tween(this.currentCamera)
                .to(
                    {
                        radius: toRadius,
                    },
                    750,
                )
                .easing(TWEEN.Easing.Quadratic.Out)
                .start();

            this.tweenTargetPosition = new TWEEN.Tween(this.currentCamera.target)
                .to(
                    {
                        x: pickResult.pickedPoint.x,
                        y: pickResult.pickedPoint.y,
                        z: pickResult.pickedPoint.z,
                    },
                    1000,
                )
                .easing(TWEEN.Easing.Quadratic.Out)
                .start();
        }
    }

    setupCameraZoomValues() {
        this.currentCamera.lowerRadiusLimit = 2;
        this.currentCamera.minZ = 0.5;
        this.currentCamera.wheelPrecision = 20;
        this.currentCamera.upperBetaLimit = 1.5;
    }

    /**
     * Return the current camera mode
     * If there is no current camera it returns PERSPECTIVE_CAMERA
     * @returns {number}
     */
    getCameraMode() {
        if (this.currentCamera) {
            return this.currentCamera.mode;
        }
        return Camera.PERSPECTIVE_CAMERA;
    }
}
