export default {
    name: 'mesh-manager',
    requires: ['material-manager'],
    load() {
        const MeshManager = require('./mesh-manager').default;
        const MeshUtility = require('./utility/mesh-utility');
        return {
            meshController: new MeshManager(),
            meshUtility: MeshUtility,
        };
    },
    unload() {},
};
