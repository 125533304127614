// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/fonts/Poppins-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../../assets/fonts/Poppins-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../../assets/fonts/Poppins-SemiBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../../../assets/fonts/Poppins-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Poppins Regular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:\"Poppins Medium\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:\"Poppins Semibold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:\"Poppins Bold\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}:export{topMenuHeight:56px;bottomMenuHeight:56px}.modal-mask{position:fixed;z-index:9998;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);display:table}.modal-wrapper{display:table-cell;vertical-align:middle}", "",{"version":3,"sources":["webpack://./src/style/base/_variables.scss","webpack://./src/modules/ui/src/components/overlay/overlay.vue"],"names":[],"mappings":"AAyCA,WACI,6BAAA,CACA,2CAAA,CAEJ,WACI,4BAAA,CACA,2CAAA,CAEJ,WACI,8BAAA,CACA,2CAAA,CAEJ,WACI,0BAAA,CACA,2CAAA,CAGJ,QACI,kBAzCc,CA0Cd,qBAzCiB,CChBrB,YACI,cAAA,CACA,YDiCc,CChCd,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,+BAAA,CACA,aAAA,CAEJ,eACI,kBAAA,CACA,qBAAA","sourcesContent":[null,"@font-face{font-family:\"Poppins Regular\";src:url(\"../../assets/fonts/Poppins-Regular.otf\")}@font-face{font-family:\"Poppins Medium\";src:url(\"../../assets/fonts/Poppins-Medium.otf\")}@font-face{font-family:\"Poppins Semibold\";src:url(\"../../assets/fonts/Poppins-SemiBold.otf\")}@font-face{font-family:\"Poppins Bold\";src:url(\"../../assets/fonts/Poppins-Bold.otf\")}:export{topMenuHeight:56px;bottomMenuHeight:56px}.modal-mask{position:fixed;z-index:9998;top:0;left:0;width:100%;height:100%;background-color:rgba(0,0,0,.5);display:table}.modal-wrapper{display:table-cell;vertical-align:middle}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
