<script lang="ts">
    import product from './group-product.vue';
    import self from '../../..';
    import EventBus from '../../eventBus';
    import RightsHelper from '../../../../../helpers/rights-helper';
    import { directive as onClickaway } from 'vue-clickaway';

    const {
        app: {
            modules: {
                history,
                groupManager: { Controller: groupController, GroupSelection: groupSelection, DisplayHelper: displayHelper },
                buildingPlanManager: { buildingPlanController: bpController, PAGE_TYPES },
                measurementManager: { Controller: measurementController },
            },
            events,
        },
    } = self;

    export default {
        name: 'Group',
        directives: {
            onClickaway,
        },
        components: { product },
        props: {
            group: {
                type: Object,
                default: () => ({}),
            },
            showOptions: {
                type: Boolean,
                default: () => true,
            },
        },
        data() {
            return {
                productList: this.group.productList,
                // The info if we are editing the group label/title
                isEditing: !this.group.default && !this.group.nameLocked,
                // Initialise the display information
                isDisplayed: displayHelper.isGroupVisible(this.group),
                showFrames: true, // The info about if the group is collapsed or not
                // Info used when we rename the group and want to check if something has changed
                tempName: this.group.name,
                buildingPlanData: bpController.vueData,
                measurementController,
                PAGE_TYPES,
            };
        },
        computed: {
            // Check if a group is selected, easy to compute
            isSelected() {
                return groupSelection.isSelectedGroup(this.group);
            },
            updatedProductList() {
                return this.productList.filter((currentProduct) => currentProduct.__name__ !== 'connector');
            },
            // The css class of the components, easy to compute
            displayClass() {
                return {
                    selected: this.isSelected,
                    hidden:
                        !this.isDisplayed ||
                        (RightsHelper.isModeBuildingPlan() && this.buildingPlanData.isCameraFixed) ||
                        this.measurementController.measureModeActivated ||
                        this.buildingPlanData.currentPage.type === PAGE_TYPES.HTML,
                };
            },
        },
        watch: {
            isEditing(val) {
                if (val) {
                    // When we start editing the group name we store it
                    // in the tempName data for checking changes
                    this.tempName = this.group.name;
                }
            },
        },
        mounted() {
            this.initListening();
            // Happens when we click on the "collapse all group" button
            EventBus.$on('collapseGroups', () => {
                this.hideFrames();
            });
            events.on('@history.history-go', () => {
                this.$forceUpdate(); // Name doesn't update unless we interact with
                // the group view
            });
        },
        beforeUnmount() {
            EventBus.$off('collapseGroups', () => {
                this.hideFrames();
            });
        },
        methods: {
            startNameEdition() {
                if (!RightsHelper.isModePublic() && !RightsHelper.isModeBuildingPlan() && !this.group.default) {
                    this.isEditing = true;
                }
            },

            // Select or unselect a group on the UI
            selectGroup() {
                if (!this.buildingPlanData.isCameraFixed && this.buildingPlanData.currentPage.type !== PAGE_TYPES.HTML) {
                    if (!this.isDisplayed) {
                        return;
                    }
                    if (this.isSelected) {
                        groupSelection.unselectGroup(this.group);
                    } else {
                        groupSelection.selectGroup(this.group);
                    }
                }
            },

            // Display all the product of a group
            displayGroup() {
                displayHelper.setGroupVisibility(this.group, true);
                this.isDisplayed = true;
            },

            // Initiate all the events
            initListening() {
                events.on('@group-manager.entity-added-to-group', (newGroup, oldGroup) => {
                    if (newGroup.id === this.group.id || oldGroup.id === this.group.id) {
                        this.updateVisibility();
                    }
                });
                [
                    '@data-store.entity-removed',
                    '@group-manager.product-added-group',
                    '@group-manager.product-removed-group',
                    '@group-manager.cleaned-group',
                    '@history.history-go',
                    '@entity-manager.history-change-visibility',
                    '@building-plan-manager.restore-page-products-visibility',
                ].forEach((eventName) => {
                    events.on(eventName, () => {
                        this.updateVisibility();
                    });
                });
            },
            // Hide all the product of a group
            hideGroup() {
                if (this.isSelected) {
                    this.selectGroup();
                }
                this.group.productList.forEach((currentProduct) => {
                    if (groupSelection.isSelectedProduct(currentProduct)) {
                        groupSelection.unselectProduct(currentProduct);
                    }
                });
                displayHelper.setGroupVisibility(this.group, false);
                this.isDisplayed = false;
            },

            updateVisibility() {
                this.isDisplayed = displayHelper.isGroupVisible(this.group);
            },

            // When we click on the arrow icon to collapse/uncolapse the group
            toggleFrames() {
                this.showFrames = !this.showFrames;
            },

            // Collapse the group
            hideFrames() {
                this.showFrames = false;
            },

            // When we click outside after editing or when we press 'enter' key
            saveGroupName() {
                if (!this.isEditing) {
                    return;
                }

                this.isEditing = false;
                this.group.nameLocked = true;
                if (this.tempName !== this.group.name) {
                    if (this.group.name.trim() === '') {
                        this.group.name = this.tempName;
                    } else {
                        groupController.checkNamesDuplicates(this.group);
                        // Snapshot only when the name of the group has changed
                        history.snapshot();
                    }
                }
            },
        },
    };
</script>
<template>
    <li
        v-if="updatedProductList.length > 0"
        :class="displayClass"
        class="group"
        @click.self="selectGroup()"
    >
        <label
            class="group-infos"
            @click.self="selectGroup()"
        >
            <img
                src="/assets/icons/fleche-folder.svg"
                :class="{ closed: !showFrames }"
                @click="toggleFrames()"
            />
            <img
                src="/assets/icons/folder.svg"
                @click.self="selectGroup()"
            />
            <span
                v-if="!isEditing"
                @click="startNameEdition()"
                >{{ group.name }}</span
            >
            <input
                v-if="!group.default && isEditing"
                v-model="group.name"
                v-on-clickaway="saveGroupName"
                :placeholder="group.name"
                style="width: 120px"
                @keyup.enter="saveGroupName"
            />
            <template v-if="!buildingPlanData.isCameraFixed && buildingPlanData.currentPage.type !== PAGE_TYPES.HTML">
                <img
                    v-if="isDisplayed && !isEditing"
                    class="visibility-icon ml-auto"
                    :src="isSelected ? '/assets/icons/eye.svg' : '/assets/icons/eye-black.svg'"
                    @click.stop="hideGroup()"
                />
                <img
                    v-if="!isDisplayed && !isEditing"
                    class="visibility-icon ml-auto"
                    :src="isSelected ? '/assets/icons/eye-slash.svg' : '/assets/icons/eye-slash-black.svg'"
                    @click.stop="displayGroup()"
                />
            </template>
        </label>
        <ul v-if="showFrames">
            <product
                v-for="product in updatedProductList"
                :key="product.id"
                :product="product"
                :show-options="showOptions"
                :group-selected="isSelected"
                :group-is-displayed="isDisplayed"
                @productDisplayed="updateVisibility"
                @productHidden="updateVisibility"
            />
        </ul>
    </li>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix';

    .group {
        margin: 0 0.8rem;
        padding-bottom: 5%;
        margin-top: 3%;
        border-radius: 4px;

        &.hidden {
            opacity: 0.3;
        }

        &.selected {
            color: $dark;
            background-color: #fff;
        }

        &:hover:not(.hidden) {
            cursor: pointer;
        }

        ul {
            list-style: none;
        }

        .group-infos {
            display: flex;
            align-items: center;
            width: 100%;
            padding-top: 2%;
            padding-bottom: 4%;
            padding-left: $group-padding-left;
            width: calc(100% - 20px);
            font-family: $font-semibold;
            font-size: 1rem;

            img {
                max-height: 40px;
                padding: 0 0.35rem;
                transition: all 0.25s ease;

                &.closed {
                    transform: rotate(-90deg);
                }
            }

            span {
                cursor: text;
            }

            .visibility-icon {
                max-height: 13px;
            }
        }
    }

    .group:not(.hidden) .group-infos:hover {
        cursor: pointer;

        .visibility-icon {
            display: inline;
        }
    }
</style>
