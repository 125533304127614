const EntityStructure = require('./entity-structure').default;
const Structure = require('@obsidianjs/data-store/src/entity');

const ConnectorStructure = EntityStructure.$extend({
    __name__: 'connector',

    __init__(params) {
        this.$super(params);
        this.$data.isConnector = true;
        this.$data.materialId = 'connectors-ano';
    },

    getIsConnector() {
        return this.$data.isConnector;
    },
});
Structure.$register(ConnectorStructure);
export default ConnectorStructure;
