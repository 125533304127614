import self from '../..';
import config from 'defaultConfig';

const ConfigHelper = {
    /**
     * Updates the infillsImg property on config
     * @param {Object|JSON} imageObject
     */
    updateConfigImages(imageObject) {
        let imageJson = imageObject;
        if (typeof imageObject !== 'string') {
            imageJson = JSON.stringify(imageObject);
        }
        config.infillsImg = imageJson;
        self.app.events.emit('update-config-images');
    },
};

export default ConfigHelper;
