import self from '../..';

const EntityStructure = require('./entity-structure').default;
const Structure = require('@obsidianjs/data-store/src/entity');

const BeTrussSquareStructure = EntityStructure.$extend({
    __name__: 'betruss-square',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
        this.initOptions();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype, self.app.modules.optionManager.OptionableMixin.prototype],
});
Structure.$register(BeTrussSquareStructure);
export default BeTrussSquareStructure;
