<script lang="ts">
    import eventBus from '../../../../../commons/EventBus';
    import self from '../../../index';

    export default {
        name: 'CatalogProduct',
        props: {
            reference: {
                type: String,
                default: '',
            },
            name: {
                type: String,
                default: '',
            },
            thumbnailPath: {
                type: String,
                default: '',
            },
            customClickHandling: {
                type: Boolean,
                default: false,
            },
            isRawThumbnailPath: {
                type: Boolean,
                default: false,
            },
            isAvailable: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['click', 'meshInitializing', 'meshInitializingError'],
        data() {
            return {
                thumbnailUrl: this.buildThumbnailUrl(),
                isSelected: false,
            };
        },
        mounted() {
            self.app.events.on('@selection-manager.end-initialization', () => {
                this.isSelected = false;
            });
        },
        methods: {
            /**
             * Add the selected product to the scene and start the drag and drop
             */
            addToScene(event) {
                this.isSelected = true;

                if (self.app.modules.measurementManager.Controller.measureModeActivated) {
                    self.app.modules.measurementManager.Controller.toggleMeasureMode();
                }

                if (self.app.modules.catalogManager.catalogLoaded) {
                    if (self.app.modules.catalogManager.products[this.reference].isAvailable) {
                        self.app.events.emit('showLoader');
                        self.app.modules.projectManager.controller
                            .addToScene(this.reference)
                            .then((struct) => {
                                self.app.events.emit('hideLoader');
                                if (!struct) {
                                    // Mesh couldn't be instantiate
                                    this.thumbnailUrl = '/assets/images/unavailable.png';
                                } else {
                                    this.$emit('meshInitializing');
                                    self.app.modules.selectionManager.initializingEntityOnScene(struct, {
                                        x: event.clientX,
                                        y: event.clientY,
                                    });
                                    eventBus.emit('activating-tool');
                                }
                            })
                            .catch((exception) => {
                                self.app.log.error('EXCEPTION WHILE LOADING MESH: ', exception);
                                self.app.events.emit('hideLoader');
                                this.$emit('meshInitializingError');
                            });
                    } else {
                        self.app.log.warn(`The reference : ${this.reference}, is currently unavailable !`);
                    }
                } else {
                    self.app.log.info('Library not loaded yet ..');
                }
            },

            /**
             * If the url loading fails the thumbnail is set to a default one
             */
            setFallbackThumbnailUrl() {
                this.thumbnailUrl = '/assets/images/default_thumbnail.png';
            },

            /**
             * Compute the thumbnail url
             */
            buildThumbnailUrl() {
                if (this.isRawThumbnailPath) {
                    return this.thumbnailPath;
                }
                if (this.isAvailable) {
                    return `${this.thumbnailPath}${this.reference}.jpg`;
                }
                return '/assets/images/unavailable.png';
            },
        },
    };
</script>
<template>
    <div
        v-if="customClickHandling === false"
        class="catalog-product"
        @click="addToScene"
    >
        <!-- use alt ? -->
        <img
            :src="thumbnailUrl"
            width="128"
            @error="setFallbackThumbnailUrl()"
        />
        <label>{{ name }}</label>
    </div>
    <div
        v-else
        class="catalog-product"
        @click="$emit('click')"
    >
        <img
            :src="thumbnailUrl"
            width="128"
            @error="setFallbackThumbnailUrl()"
        />
        <label>{{ name }}</label>
    </div>
</template>

<style lang="scss">
    @import '../../../../../style/bematrix';

    .catalog-product {
        cursor: pointer;
        padding: 4% 0;
        margin: 20% 10%;
        border: solid 1px transparent;

        label {
            color: #66676a;
            cursor: pointer;
            display: inline-block;
            font-family: $font-medium;
            font-size: 0.55em;
            margin-bottom: 8px;
            word-wrap: break-word;
        }

        img {
            border-radius: 3px;
            max-height: 5%;
            margin: 8px 0;
        }

        &:hover {
            border: solid 1px $green;
            border-radius: 3px;
        }
    }
</style>
