<script lang="ts">
    import self from '../../../index';

    const {
        app: {
            modules: {
                catalogManager,
                buildingPlanManager: { buildingPlanController: bpController },
            },
        },
    } = self;

    export default {
        name: 'BuildingPlanTable',
        data() {
            return {
                catalog: catalogManager.products,
            };
        },
        computed: {
            getPageProductsQuantities() {
                return bpController.getPage().table.productsQuantities;
            },
        },
    };
</script>
<template>
    <div class="building-plan-table-container">
        <table class="building-plan-table">
            <tr class="building-plan-tr">
                <th class="building-plan-th">ID</th>
                <th class="building-plan-th">Quantity</th>
                <th class="building-plan-th">Name</th>
                <th class="building-plan-th">Description</th>
            </tr>
            <tr
                v-for="(quantity, ref, index) in getPageProductsQuantities"
                :key="ref"
                class="building-plan-tr"
            >
                <td class="building-plan-td">
                    {{ index + 1 }}
                </td>
                <td class="building-plan-td">
                    {{ quantity }}
                </td>
                <td class="building-plan-td">
                    {{ ref }}
                </td>
                <td class="building-plan-td">
                    {{ catalog[ref].description }}
                </td>
            </tr>
        </table>
    </div>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix.scss';
</style>
