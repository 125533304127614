const Structure = require('@obsidianjs/data-store/src/entity');

const GridStructre = Structure.$extend({
    __name__: 'configuration',

    /**
     * @class bematrix.EntityStructure
     */
    __init__(params) {
        this.$data.gridHeight = null;
        this.$data.gridWidth = null;
        this.$super(params);
    },

    getGridHeight() {
        return this.$data.gridHeight;
    },

    setGridHeight(height) {
        this.$data.gridHeight = height;
    },

    getGridWidth() {
        return this.$data.gridWidth;
    },

    setGridWidth(width) {
        this.$data.gridWidth = width;
    },
});

Structure.$register(GridStructre);
export default GridStructre;
