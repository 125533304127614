<script lang="ts">
    import self from '../../../index';
    import draggable from 'vuedraggable';

    const {
        app: {
            modules: {
                buildingPlanManager: { buildingPlanController: bpController, PAGE_TYPES },
                measurementManager: { Controller: measurementController },
                stonejs,
            },
            events: { emit },
        },
    } = self;

    export default {
        components: {
            draggable,
        },
        props: {
            buildingPlanData: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                PAGE_TYPES,
                measurementController,
                isAddPageOpen: false,
            };
        },
        methods: {
            selectPage(pageNumber) {
                if (measurementController.measureModeActivated) {
                    measurementController.toggleMeasureMode();
                }
                bpController.setActivePage(pageNumber);
            },

            addScenePage() {
                bpController.addPage(PAGE_TYPES.SCENE);

                this.isAddPageOpen = false;
            },
            addHtmlPage() {
                bpController.addPage(PAGE_TYPES.HTML);

                this.isAddPageOpen = false;
            },
            removePage() {
                bpController.removePage();
            },
            swapPages() {
                bpController.swapPage();

                this.$forceUpdate();
            },

            exitPdfMode() {
                const onValidate = () => {
                    bpController.togglePdfMode();
                };
                const popinDescription1 = this._('You are about to quit pdf mode');
                const popinDescription2 = this._(
                    'Warning : By quitting the pdf mode you will lose all the pages informations you have setted!',
                );
                emit('open-popin', {
                    title: this._('Exit pdf mode'),
                    description: `${popinDescription1}
                    <br />
                    <br />
                    <span class="warning-info">
                        ${popinDescription2}
                    </span>`,
                    isRequired: false,
                    onValidate,
                });
            },

            useHoverAddIcon() {
                this.$refs['add-page-icon'].src = 'assets/icons/plus-hover.svg';
            },
            useNormalAddIcon() {
                this.$refs['add-page-icon'].src = 'assets/icons/plus.svg';
            },
            useClickScenePageIcon(e) {
                e.target.src = 'assets/icons/3d-page-click.svg';
            },
            useHoverScenePageIcon(e) {
                e.target.src = 'assets/icons/3d-page-hover.svg';
            },
            useNormalScenePageIcon(e) {
                e.target.src = 'assets/icons/3d-page.svg';
            },
            useClickHtmlPageIcon(e) {
                e.target.src = 'assets/icons/2d-page-click.svg';
            },
            useHoverHtmlPageIcon(e) {
                e.target.src = 'assets/icons/2d-page-hover.svg';
            },
            useNormalHtmlPageIcon(e) {
                e.target.src = 'assets/icons/2d-page.svg';
            },

            isPageSelected(testedPageNumber) {
                return this.buildingPlanData.selectedPageNumber === testedPageNumber;
            },

            isEditingPage(testedPageNumber) {
                return bpController.isPageEditable(testedPageNumber) && bpController.imageController.pageHasImages(testedPageNumber);
            },
            onDragEnd({ newIndex, oldIndex }) {
                bpController.updateMovedPage(newIndex, oldIndex);
                return true;
            },

            _(...args) {
                return stonejs.gettext(...args);
            },
        },
    };
</script>
<template>
    <div class="left-container">
        <div class="preview-container">
            <div class="exit-bulding-plan-container">
                <div
                    class="exit-bulding-plan-button"
                    @click="exitPdfMode"
                >
                    <div class="edition-button-icon-container">
                        <img
                            class="icon"
                            src="assets/icons/3d-page.svg"
                        />
                    </div>
                    {{ _('Return to 3d project') }}
                </div>
            </div>
            <draggable
                v-model="buildingPlanData.pdfPages"
                class="preview-images-list"
                draggable=".preview-draggable"
                @end="onDragEnd"
            >
                <template v-for="(page, pageIndex) in buildingPlanData.pdfPages">
                    <div
                        v-if="page.images.SCENE && page.images.HTML"
                        :key="pageIndex"
                        class="preview-layer-container preview-draggable"
                        :class="{
                            'editing-page': isEditingPage(pageIndex + 1),
                        }"
                        @click="selectPage(pageIndex + 1)"
                    >
                        <img
                            class="preview-layer"
                            :class="{
                                'page-selected': isPageSelected(pageIndex + 1),
                            }"
                            :src="page.images.HTML.base64"
                        />
                        <img
                            class="preview-layer"
                            :class="{
                                'page-selected': isPageSelected(pageIndex + 1),
                            }"
                            :src="page.images.SCENE.base64"
                        />
                    </div>
                    <!-- Consider the different page types -->
                    <img
                        v-else-if="page.type === PAGE_TYPES.SCENE && page.images.SCENE"
                        :key="`img-type-scene-${pageIndex}`"
                        class="preview-image preview-draggable"
                        :class="{
                            'page-selected': isPageSelected(pageIndex + 1),
                            'editing-page': isEditingPage(pageIndex + 1),
                        }"
                        :src="page.images.SCENE.base64"
                        @click="selectPage(pageIndex + 1)"
                    />
                    <img
                        v-else-if="page.type === PAGE_TYPES.HTML && page.images.HTML"
                        :key="`img-type-html-${pageIndex}`"
                        class="preview-image preview-draggable"
                        :class="{
                            'page-selected': isPageSelected(pageIndex + 1),
                            'editing-page': isEditingPage(pageIndex + 1),
                        }"
                        :src="page.images.HTML.base64"
                        @click="selectPage(pageIndex + 1)"
                    />

                    <div
                        v-else
                        :key="`img-type-other-${pageIndex}`"
                        class="preview-image preview-draggable"
                        :class="{
                            'page-selected': isPageSelected(pageIndex + 1),
                            'editing-page': isEditingPage(pageIndex + 1),
                        }"
                        @click="selectPage(pageIndex + 1)"
                    >
                        <div class="preview-image-placeholder">
                            <img
                                v-if="page.type === PAGE_TYPES.SCENE"
                                src="assets/icons/no-capture-pdf.svg"
                            />
                            <img
                                v-else
                                src="assets/icons/no-capture-html.svg"
                            />
                            <br />
                            {{ _('no capture for the moment') }}
                        </div>
                    </div>
                </template>
                <div class="button-separator">
                    <div
                        v-if="!measurementController.measureModeActivated"
                        v-b-tooltip.hover
                        :title="_('Add new page')"
                        class="add-page-button"
                        @click="isAddPageOpen = !isAddPageOpen"
                        @mouseover="useHoverAddIcon"
                        @mouseleave="useNormalAddIcon"
                    >
                        <img
                            ref="add-page-icon"
                            class="icon-button"
                            src="assets/icons/plus.svg"
                        />
                    </div>
                    <div
                        v-if="isAddPageOpen"
                        class="add-pdf-page"
                    >
                        <img
                            v-b-tooltip.hover
                            :title="_('Project page')"
                            src="assets/icons/3d-page.svg"
                            @mousedown="useClickScenePageIcon"
                            @mouseup="useHoverScenePageIcon"
                            @mouseover="useHoverScenePageIcon"
                            @mouseleave="useNormalScenePageIcon"
                            @click="addScenePage"
                        />
                        <img
                            v-b-tooltip.hover
                            :title="_('Empty page')"
                            src="assets/icons/2d-page.svg"
                            @mousedown="useClickHtmlPageIcon"
                            @mouseup="useHoverHtmlPageIcon"
                            @mouseover="useHoverHtmlPageIcon"
                            @mouseleave="useNormalHtmlPageIcon"
                            @click="addHtmlPage"
                        />
                    </div>
                </div>
            </draggable>
            <div class="preview-interactions-container">
                <div
                    v-if="buildingPlanData.selectedPageNumber && !measurementController.measureModeActivated"
                    class="preview-interaction"
                    @click="removePage"
                >
                    <span class="icon">
                        <img src="assets/icons/close.svg" />
                    </span>
                    <span class="preview-interactions-text">
                        {{ _('Remove page') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix.scss';

    .left-container {
        position: fixed;
        display: flex;
        flex-direction: column;
        width: $left-menu-width;
        height: 100%;

        background-color: $pale-grey;
        border: solid 1px $border-grey;

        .exit-bulding-plan-container {
            width: 80%;
            height: $top-menu-height;
            border-bottom: $border-grey solid 1px;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 auto;
            .exit-bulding-plan-button {
                cursor: pointer;
                margin-top: 0.25em;
                margin: auto 0;
                height: 70%;
                background-color: $dark;
                width: 80%;
                border-radius: 5px;
                color: white;
                justify-content: center;
                display: flex;
                align-items: center;
                font-size: 0.85em;
                img {
                    max-width: 15px;
                    margin-right: 1em;
                }
                &:hover {
                    background-color: $dark-hover;
                }
                &:active {
                    background-color: $dark-click;
                }
            }
        }

        .button-separator {
            width: 80%;
            border-top: $border-grey solid 1px;
            margin-top: 1em;
            padding: 1em 0;
        }

        .add-button {
            max-width: 30px;
            min-width: 30px;
            max-height: 30px;
            min-height: 30px;

            img {
                margin: auto;

                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
                min-height: 20px;
            }
        }

        .add-pdf-page {
            display: flex;
            flex-direction: row;
            width: max-content;
            margin: 0.5em auto;

            background-color: #ffffff;
            border: 1px solid $bluey-grey;
            border-radius: 5px;
            box-shadow: 0 3px 3px -2px rgba(0, 63, 79, 0.29);

            img {
                margin: 0.75em 1.25em;

                max-width: 20px;
                min-width: 20px;
                max-height: 20px;
                min-height: 20px;

                cursor: pointer;
            }

            div {
                height: 1.5em;
                margin: auto;

                border-left: 1px solid $bluey-grey;
            }
        }

        .icon-button {
            max-width: 30px;
            max-height: 30px;
            margin: auto;
        }

        .add-page-button {
            width: 30px;
            height: 30px;
            border: solid 1.7px #3f434b;
            border-radius: 50%;
            display: inline-flex;
            &:hover {
                border-color: $green;
                cursor: pointer;
            }
        }

        .preview-container {
            height: 100%;

            background-color: $pale-grey;
            border-top: solid 1px $border-grey;

            .preview-title {
                margin: 0.5em 0;
                text-align: center;
                font-size: 1.2em;
            }

            .preview-images-list {
                height: 85%;

                display: flex;
                flex-direction: column;
                align-items: center;

                text-align: center;
                overflow-y: auto;

                div.preview-image {
                    display: table;
                    font-family: $font-regular;
                    font-size: 1em;
                    border-color: $bluey-grey;
                    img {
                        margin-bottom: 0.5em;
                    }
                }

                .preview-image {
                    width: 70%;
                    height: 20%;
                    margin: 1em 0;
                    padding: 0.25em;
                    border: solid transparent 1px;
                    border-radius: 10px;

                    &-placeholder {
                        display: table-cell;
                        vertical-align: middle;
                    }

                    &:hover {
                        border-color: $green;
                        cursor: pointer;
                    }

                    &.page-selected {
                        border-color: $dark;
                    }

                    &.editing-page {
                        filter: contrast(0.5);
                    }
                }

                .preview-layer-container {
                    position: relative;

                    :first-child {
                        position: absolute;

                        &:hover {
                            border-color: $green;
                            cursor: pointer;
                        }

                        &.page-selected {
                            border-color: $dark;
                        }
                    }

                    &.editing-page {
                        filter: contrast(0.5);
                    }
                    .preview-layer {
                        width: 70%;

                        margin: 1em 0;
                        padding: 0.25em;
                        border: solid transparent 1px;
                        border-radius: 10px;
                    }
                }
            }
        }

        .preview-interactions-container {
            display: flex;
            flex-direction: column;

            .preview-interaction {
                width: 75%;
                background-color: white;
                cursor: pointer;
                border-radius: 30px;
                margin: 1em 0;
                margin-left: 1rem;
                box-shadow: 0 3px 3px -2px rgba(0, 63, 79, 0.29);
                padding: 0;
                text-align: left;
                display: flex;
                align-items: center;

                .preview-interactions-text {
                    text-align: center;
                    font-family: $font-regular;
                    font-size: 0.75em;
                    margin-left: 1em;
                    float: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .icon {
                    border-radius: 50%;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    margin: 2px 0 2px 2px;
                    background-color: $green;

                    img {
                        position: relative;
                        top: 9px;
                        max-height: 13px;
                        max-width: 13px;
                    }
                }

                &:hover {
                    background-color: $pale-grey-hover;
                }

                &:active {
                    background-color: $pale-grey-click;
                }
            }
        }
    }
</style>
