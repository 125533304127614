<script lang="ts">
    import textArrow from './text-arrow.vue';
    import self from '../../../index';

    import EventBus from '../../eventBus';

    const {
        app: {
            modules: {
                buildingPlanManager: {
                    buildingPlanController: bpController,
                    buildingPlanImageController: { IMAGE_MODES },
                    PAGE_TYPES,
                },
            },
            events: { on, removeListener },
        },
    } = self;

    export default {
        components: { textArrow },
        props: {
            textObject: {
                default: () => ({}),
                type: Object,
            },
        },
        data() {
            return {
                bpController,
                localTextObject: this.textObject,
                dragging: false,
                canvasContainer: null,
                startOffsetX: 0,
                startOffsetY: 0,
                draggableElement: null,
                textElement: null,
                sizeInfos: {
                    x: 0,
                    y: 0,
                },
            };
        },
        computed: {
            isFocused() {
                return this.localTextObject.isFocused;
            },

            isSettingArrow() {
                return this.localTextObject.settingArrow;
            },

            isScenePage() {
                return bpController.getPage().type === PAGE_TYPES.SCENE;
            },
        },
        mounted() {
            this.draggableElement = this.$refs['draggable-element'];
            this.textElement = this.$refs['text-element'];
            this.textElement.innerText = this.textObject.text;
            this.canvasContainer = document.getElementById('canvas-container');

            // Restore text position
            this.draggableElement.style.left = this.localTextObject.position.x;
            this.draggableElement.style.top = this.localTextObject.position.y;
            // When a textbox get focused, other ones get blurred
            this.computeSizeInfos();
            EventBus.$on('textbox-focus', this.onOtherTextboxFocus);
        },
        beforeUnmount() {
            this.blur();

            EventBus.$off('textbox-focus', this.onOtherTextboxFocus);
        },
        methods: {
            // The following methods handle manual focus of the text box
            onOtherTextboxFocus(targetElement) {
                if (targetElement !== this.$el) {
                    this.onClickOutside();
                }
            },
            onClickOutside() {
                this.blur();
                bpController.imageController.captureImage(IMAGE_MODES.HTML);
            },
            focus() {
                if (!this.isFocused) {
                    this.localTextObject.isFocused = true;
                    this.textElement.contentEditable = true;

                    EventBus.$emit('textbox-focus', this.$el);

                    this.canvasContainer.addEventListener('click', this.onClickOutside);
                    on('@obsidian-engine.unselect', this.onClickOutside);
                }
            },
            blur() {
                if (this.isFocused) {
                    this.localTextObject.isFocused = false;
                    this.textElement.contentEditable = false;

                    this.updateText();

                    this.canvasContainer.removeEventListener('click', this.onClickOutside);
                    removeListener('@obsidian-engine.unselect', this.onClickOutside);
                }

                if (this.dragging) {
                    this.dragEnd();
                }
            },
            updateText() {
                this.localTextObject.text = this.textElement.innerText;
                this.computeSizeInfos();
            },
            addArrow() {
                bpController.addArrowToText();
                removeListener('@obsidian-engine.unselect', this.onClickOutside);
            },
            computeSizeInfos() {
                this.sizeInfos.x = this.textElement.clientWidth / 2;
                this.sizeInfos.y = this.textElement.clientHeight / 2;
            },
            // Manual dragging management
            dragStart(event) {
                this.dragging = true;

                // Initial offset on the drag handle
                this.startOffsetX = event.offsetX;
                this.startOffsetY = event.offsetY;

                this.draggableElement.style.pointerEvents = 'none';

                this.textElement.contentEditable = false;
                this.canvasContainer.addEventListener('mousemove', this.drag);
            },
            drag(event) {
                // Subtract the canvas offset in the window + the drag handle initial offset from
                // the mouse position in the window
                const positionX = event.offsetX - this.startOffsetX;
                const positionY = event.offsetY - this.startOffsetY;

                this.draggableElement.style.left = positionX;
                this.draggableElement.style.top = positionY;
                this.localTextObject.position.x = positionX;
                this.localTextObject.position.y = positionY;
            },
            dragEnd() {
                this.draggableElement.style.pointerEvents = 'auto';
                this.textElement.contentEditable = true;
                this.canvasContainer.removeEventListener('mousemove', this.drag);
                this.dragging = false;

                this.startOffsetX = 0;
                this.startOffsetY = 0;
            },
        },
    };
</script>
<template>
    <div class="text-box-container">
        <text-arrow
            v-for="arrow in localTextObject.arrows"
            :key="arrow.id"
            :arrow-object="arrow"
            :text-object="localTextObject"
            :size-infos="sizeInfos"
        />
        <div
            ref="draggable-element"
            class="text-box"
            :class="[isFocused ? 'focused' : '', isSettingArrow ? 'settingArrow' : '']"
            @click.stop="bpController.isPageEditable() && focus()"
        >
            <div
                v-show="isFocused && !isSettingArrow"
                class="text-input move-handle"
                :class="[dragging ? 'dragging' : '']"
                @mousedown="dragStart"
                @mouseup="dragEnd"
            >
                <img
                    draggable="false"
                    src="assets/icons/move-handle.svg"
                    :class="textObject.sizeClass"
                />
            </div>
            <div
                ref="text-element"
                class="text"
                :class="textObject.sizeClass"
            />
            <div
                v-show="isScenePage && isFocused && !isSettingArrow"
                class="text-input arrow-button"
                @click="addArrow"
            >
                <img
                    src="assets/icons/dot-arrow.svg"
                    :class="textObject.sizeClass"
                />
            </div>
        </div>
    </div>
</template>
<style lang="scss">
    @import '../../../../../style/bematrix';

    .text-box-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .text-box {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 0;
        left: 0;
        z-index: $z-index-page-text-box;
        pointer-events: auto;
        &.focused {
            border: solid 1px black;
            border-radius: 2px;
        }

        .text-input {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            padding: 0 0.5rem;
            min-width: 10px;

            img {
                &.small-text {
                    height: 0.75rem;
                }
                &.medium-text {
                    height: 1.25rem;
                }
                &.large-text {
                    height: 2rem;
                }
            }

            &.arrow-button {
                background-color: $bluey-grey;
                cursor: pointer;
                &:hover {
                    background-color: $pale-grey;
                }
            }

            &.move-handle {
                background-color: $dark-click;
                cursor: grab;

                &.dragging {
                    cursor: grabbing;
                }
            }
        }

        .text {
            cursor: text;
            padding: 0 0.5rem;

            &.small-text {
                font-size: 1rem;
            }
            &.medium-text {
                font-size: 2.5rem;
            }
            &.large-text {
                font-size: 5rem;
            }
        }
    }
</style>
