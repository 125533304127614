<script lang="ts">
    import IndexedAnnotation from './indexed-annotation.vue';
    import self from '../../../index';

    const {
        app: {
            modules: {
                buildingPlanManager: { buildingPlanController: bpController },
            },
        },
    } = self;

    export default {
        name: 'BuildingPlanTable',
        components: {
            IndexedAnnotation,
        },
        computed: {
            getPageIndexedAnnotations() {
                const page = bpController.getPage();
                return page.annotations;
            },
        },
    };
</script>
<template>
    <div class="building-plan-annotations-container">
        <indexed-annotation
            v-for="annotation in getPageIndexedAnnotations"
            :key="annotation.uuid"
            :annotation-data="annotation"
        />
    </div>
</template>
<style lang="scss">
    .building-plan-annotations-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }
</style>
