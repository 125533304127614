<script lang="ts">
    import EventBus from '../eventBus';
    import self from '../../index';
    import { debounce } from '../../../../helpers/js-helper';
    import iro from '@jaames/iro';

    const { events } = self.app;

    export default {
        name: 'ColorPickerIro',
        data() {
            return {
                isVisible: false,
                currentColorObject: {
                    value: '#000000',
                },
                picker: null,
            };
        },

        mounted() {
            this.picker = new iro.ColorPicker('#picker', {
                width: 128,
                color: '#f00',
                borderWidth: 1,
                borderColor: '#fff',
                layout: [
                    {
                        component: iro.ui.Wheel,
                        options: {},
                    },
                    {
                        component: iro.ui.Slider,
                        options: {
                            sliderSize: 12,
                        },
                    },
                ],
                layoutDirection: 'horizontal',
            });

            this.picker.on('color:change', debounce(this.changeColor, 1000));

            EventBus.$on('open-color-picker', this.show.bind(this));
            events.on('@selection-manager.unselect-all', this.hide.bind(this));
        },

        unmounted() {
            EventBus.$off('open-color-picker', this.show);
        },

        methods: {
            show(colorObject) {
                this.currentColorObject = colorObject;
                this.isVisible = true;
                this.picker.color.hexString = colorObject.value;
            },
            hide() {
                this.isVisible = false;
            },
            changeColor() {
                const isValidHexStr = /^#[0-9A-F]{6}$/i.test(this.picker.color.hexString);
                if (!isValidHexStr) {
                    return;
                }

                this.currentColorObject.value = this.picker.color.hexString;
            },
        },
    };
</script>

<template>
    <div
        v-show="isVisible"
        id="picker-container"
        v-click-outside="hide"
        sm="10"
    >
        <div id="picker" />
        <input
            id="input-small"
            v-model="currentColorObject.value"
            type="text"
            size="7"
        />
    </div>
</template>

<style lang="scss">
    @import '../../../../style/bematrix';
    $menu-size: 200px;

    #picker-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: $menu-size;
        height: $menu-size;
        z-index: 999998;
        bottom: 108px;
        left: $left-menu-width;
        margin-left: 8px;
        padding: 8px;
        background-color: rgb(255, 255, 255, 0.8);
        border-radius: 8px;
    }

    #picker {
        position: relative;
        padding: 16px;
    }
</style>
