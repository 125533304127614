import self from '../..';
import { Color3, MeshBuilder, StandardMaterial } from '@babylonjs/core';

let debugSpheres = [];

const CollisionBoundingSphereDebug = {
    reset() {
        debugSpheres.forEach((sphere) => {
            sphere.parent = null;
            sphere.dispose();
        });
        debugSpheres = [];
    },

    addDebugSphere(mesh) {
        const sphere = MeshBuilder.CreateSphere('sphere', { diameter: mesh.boundingSphere.radiusWorld * 2 }, self.app.scene);
        sphere.material = new StandardMaterial('sphereMat', self.app.scene);
        sphere.material.diffuseColor = new Color3(0.3, 0.3, 1);
        sphere.material.specularColor = new Color3(0, 0, 0);
        sphere.material.alpha = 0.3;
        sphere.position = mesh.boundingSphere.centerWorld;
        debugSpheres.push(sphere);
        mesh.debugSphere = sphere;
    },

    updateToCollideColor(meshes) {
        meshes.forEach((mesh) => {
            mesh.debugSphere.material.diffuseColor = new Color3(1, 0, 0);
        });
    },
};

export { CollisionBoundingSphereDebug };
